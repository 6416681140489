import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MessageBox } from '../../components/common/modalBox/MessageBox';
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { useEffect } from 'react';
import { useState } from 'react';

const PreviewModal = (props) => {
    console.log(props.data);
    let { data } = props.data;
    //const [showPreviewModal, setShowPreviewModal] = useState(false);
    

    return (
        <>
            <Modal {...props}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.data.EventHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="RouteText"><strong>Route Text</strong>:- {props.data.RouteText}</div>
                    <div className="EventEmail"><strong>Event Email</strong>:- {props.data.EventEmail}</div>
                    {props.data.ImageUrl != '' && props.data.ImageUrl != null ? <div className="ImageUrl"><strong></strong><img src={'https://intranet.global.dish.com/' + props.data.ImageUrl} /></div> : ''}
                    <div className="LocationGroup"><strong>Event Location</strong>:- {props.data.LocationGroup.Text != undefined ? props.data.LocationGroup.Text:''}</div>
                        <div className="EventDescription" dangerouslySetInnerHTML={{ __html: '<strong>Event Description</strong>:- ' + props.data.EventDescription}} />
                        <div className="EventDetails" dangerouslySetInnerHTML={{ __html: '<strong>Event Details</strong>:- ' + props.data.EventDetails }} />
                    <div className="sessionDetails"></div>
                        <div className="Start Date Time">{props.data.StartDate}</div>
                        <div className="End Date Time">{props.data.EndDate}</div>
                    <div></div>
                </Modal.Body>
       
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onHide}>Save Changes</Button>
                </Modal.Footer>


            </Modal>
        </>
        )
}
export default PreviewModal;