import React, { PureComponent } from 'react';
import { HttpRequest } from '../common/CommonOperations';
import { MessageBox } from '../../components/common/modalBox/MessageBox';

export class EmployeeProfileReview extends PureComponent {
    static displayName = EmployeeProfileReview.name;

    constructor(props) {
        super(props);
        this.state = {
            otherSelect: false,
            showMessageBox: false, message: '', messageType: '1',
            employeeProfileDetails: null,
            SubmitResponse: '',
            successImg: false,
            HideContent: true,
            HideFristMessage: false,
            MessageFristTime: '',
            imgDis:false,
            ApprovalUserDetails: null,
            countVal: 0,
        };
        this.UserDetails = this.props.UserDetails;
    }
    componentDidMount() {
        document.title = 'Employee Profile Review';
        this.Getdata();

    }
    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });
    RenderWithSpace = (item) => {
        let items = item.split(',');
        let string = '';
        items.forEach((inItem) => string += inItem + ', ');
        return string;
    }
    RadioChange = (e) => {
        if (e.target.value == 'Reject') {
            this.setState({ otherSelect: !this.state.otherSelect })
        }
        else {
            this.setState({ otherSelect: false });
            document.getElementById('Comments').value = ''
        }
    }
    Getdata = async () => {
        //employeeProfileReview / 734873 / 148845 /        
        let menuTittleName = this.props.history.location.pathname;
        var employeeNumber = null;
        var managerEmployeeNumber = null;
        let splitId = menuTittleName.split('/');
        if (splitId.length > 2) {
            employeeNumber = splitId[2].toString();
        }
        if (employeeNumber != null && splitId.length > 3) {
            managerEmployeeNumber = splitId[3].toString();
        }
        let param = {
            EmployeeNumber: employeeNumber,
            SupervisorEmpID: managerEmployeeNumber
        }

        var response = await HttpRequest('BusinessCardEmployeeProfile/GetEmployeeProfileReviewDetails', 'POST', param);
        const data = await response.json();
        this.setState({ employeeProfileDetails: data.EmployeeProfileDetails });

        if (data.EmployeeProfileDetails == null) {
            if (data.IsSuccessful == false) {
                this.setState({ imgDis: false })
            } else {
                this.setState({ imgDis: true })
            }

            this.setState({
                HideFristMessage: true,
                HideContent: true,
                MessageFristTime: data.FriendlyMessage,

            })
        } else {
            this.setState({
                HideFristMessage: false,
                HideContent: false,
                MessageFristTime: data.FriendlyMessage,
            })

            this.GetEmployeeDetailsForAproval(data.EmployeeProfileDetails.EmailAddress)
        }
    }
    GetEmployeeDetailsForAproval = async (ntLogin) => {

        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + ntLogin.split('@')[0].toLowerCase(), 'GET');
        const dataGoogle = await responseGoogle.json();
        if (dataGoogle.success == true) {
            //  UserDetails.GoogleData = ;
            var approvalUser = JSON.parse(dataGoogle.data);

            await this.setState({ ApprovalUserDetails: approvalUser });
           // alert(this.UserDetails.GoogleData.customSchemas.Primary_Phone.work_phone_ext);
        }
    }
    RenderFormData = () => {
        let item = this.state.employeeProfileDetails;
        if (item == null || item == undefined) { return <></> }

        return (
            <>
                <div className="row">
                    <div className="col l6 m12 s12">
                        <div className="input-field">
                            <input id="firstName" disabled type="text" defaultValue={item.FirstName + ' ' + item.LastName} className="validate" />
                            <label htmlFor="firstName" className="active"> Employee Name</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col l6 m12 s12">
                        <div className="input-field">
                            <input id="firstName" disabled type="text" defaultValue={item.EmployeeNumber} className="validate" />
                            <label htmlFor="firstName" className="active">Employee ID</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.PreferredPronouns == null || item.PreferredPronouns == undefined || item.PreferredPronouns == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.PreferredPronounsUpdated == true ? 'updatedField' : ''}`}>
                            <input id="PreferredPronouns" disabled type="text" defaultValue={item.PreferredPronouns} className="validate" />
                            <label htmlFor="PreferredPronouns" className="active">Preferred Pronouns</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.NamePronunciation == null || item.NamePronunciation == undefined || item.NamePronunciation == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.NamePronunciationUpdated == true ? 'updatedField' : ''}`}>
                            <input id="PreferredPronounsOp" disabled type="text" defaultValue={item.NamePronunciation} className="validate" />
                            <label htmlFor="PreferredPronounsOp" className="active">Name pronunciation (optional)</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.TeamName == null || item.TeamName == undefined || item.TeamName == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.TeamNameUpdated == true ? 'updatedField' : ''}`}>
                            <input id="firstName" disabled type="text" defaultValue={item.TeamName} className="validate" />
                            <label htmlFor="firstName" className="active">Team Name</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.Description == null || item.Description == undefined || item.Description == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.DescriptionUpdated == true ? 'updatedField' : ''}`}>

                            <span id="descri" disabled name="jobDescription" className="materialize-textarea">
                                {item.Description}
                            </span>
                            <label htmlFor="firstName" className="active">Description</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.ERGMembership == null || item.ERGMembership == undefined || item.ERGMembership == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.ERGMembershipUpdated == true ? 'updatedField' : ''}`}>
                            <span id="ERGNumber" disabled name="ERGNumber" className="materialize-textarea">
                                {item.ERGMembership}
                            </span>
                            <label htmlFor="ERGNumber" className="active">ERG Membership</label>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ display: item.MobilePhoneNumber == null || item.MobilePhoneNumber == undefined || item.MobilePhoneNumber == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.MobilePhoneNumberUpdated == true ? 'updatedField' : ''}`}>
                            <input id="mobilePhoneNumber" disabled type="text" defaultValue={item.MobilePhoneNumber} className="validate" />
                            <label htmlFor="mobilePhoneNumber" className="active">Mobile Phone Number</label>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ display: item.SeatingLocation == null || item.SeatingLocation == undefined || item.SeatingLocation == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.SeatingLocationUpdated == true ? 'updatedField' : ''}`}>
                            <input id="seatingLoc" disabled type="text" defaultValue={item.SeatingLocation} className="validate" />
                            <label htmlFor="seatingLoc" className="active">Seating Location</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.Answer1 == null || item.Answer1 == undefined || item.Answer1 == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.Answer1Updated == true ? 'updatedField' : ''}`}>
                            <input id="selectedQuestion1" disabled type="text" defaultValue={item.Answer1} className="validate" />
                            <label htmlFor="selectedQuestion1" className="active">{item.Question1Text}</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.Answer2 == null || item.Answer2 == undefined || item.Answer2 == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.Answer2Updated == true ? 'updatedField' : ''}`}>
                            <input id="selectedQuestion2" disabled type="text" defaultValue={item.Answer2} className="validate" />
                            <label htmlFor="selectedQuestion2" className="active">{item.Question2Text}</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.Answer3 == null || item.Answer3 == undefined || item.Answer3 == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.Answer3Updated == true ? 'updatedField' : ''}`}>
                            <input id="selectedQuestion3" disabled type="text" defaultValue={item.Answer3} className="validate" />
                            <label htmlFor="selectedQuestion3" className="active">{item.Question3Text}</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.Answer4 == null || item.Answer4 == undefined || item.Answer4 == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.Answer4Updated == true ? 'updatedField' : ''}`}>
                            <input id="selectedQuestion4" disabled type="text" defaultValue={item.Answer4} className="validate" />
                            <label htmlFor="selectedQuestion4" className="active">{item.Question4Text}</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.Answer5 == null || item.Answer5 == undefined || item.Answer5 == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.Answer5Updated == true ? 'updatedField' : ''}`}>
                            <input id="selectedQuestion5" disabled type="text" defaultValue={item.Answer5} className="validate" />
                            <label htmlFor="selectedQuestion5" className="active">{item.Question5Text}</label>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ display: item.EmployeeImage == null || item.EmployeeImage == undefined || item.EmployeeImage == '' ? 'none' : 'block' }}>
                    <div className="col l6 m12 s12">
                        <div className={`input-field ${item.EmployeeImageUpdated == true ? 'updatedField' : ''}`}>
                            <label htmlFor="selectedQuestion1" className="active">Profile Photo</label><br />
                            <img src={'data:image/JPEG;base64,' + item.EmployeeImage} alt='' width="170px" height="225px" />

                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col l6 m12 s12 radioGroup">
                        <span className="labelText">Approve/Reject:</span>
                        <p>
                            <label>
                                <input className="with-gap" name="status" value="Approve" onChange={this.RadioChange} type="radio" />
                                <span>Approve</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input className="with-gap" name="status" value="Reject" onChange={this.RadioChange} type="radio" />
                                <span>Reject</span>
                            </label>
                        </p>
                    </div>
                </div>


                <div className="row" style={{ display: this.state.otherSelect == true ? 'block' : 'none' }}>
                    <div className="col l6 m12 s12">
                        <div className="input-field">
                            <input id="Comments" type="text" defaultValue={item.ManagerComments} className="validate" />
                            <label htmlFor="Comments" className="active">Comments</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 l12 m12">
                        <button className="btn btn-primary waves-effect waves-light" name="action" onClick={this.SaveEmployeeData}>Submit </button>
                    </div>

                </div>
            </>
        );
    }
   
    SaveEmployeeData = async () => {
        // debugger;

        let getDataEmp = this.state.employeeProfileDetails;
        let radioValue = document.querySelectorAll('.radioGroup input[type="radio"]');
        var getSelectedValue = document.querySelector('input[name="status"]:checked');
        var rValue = '';
        radioValue.forEach((item) => {
            if (item.checked) {
                rValue = item.value
            }
        })
        if (getSelectedValue == null) {
            this.ShowMessageBox('Please select Approve/Reject Status', 3);
            return false;
        }
        if (rValue == 'Reject' && document.getElementById('Comments').value.trim() == '') {
            this.ShowMessageBox('While rejecting Comment can not be blank', 3);
            return false;
        }
        let param = {
            EmployeeNumber: getDataEmp.EmployeeNumber,
            EmployeeEmailID: getDataEmp.EmailAddress,
            ApprovalStatus: rValue,
            Comments: rValue = 'Reject' ? document.getElementById('Comments').value : '',
            SupervisorFullName: getDataEmp.SupervisorName.split(',')[1] + ' ' + getDataEmp.SupervisorName.split(',')[0],
            SupervisorEmailID: getDataEmp.SupervisorEmailAddress
        }
        var response = await HttpRequest('BusinessCardEmployeeProfile/UpdateEmployeeProfileApprovalStatus', 'POST', param);
        const responseData = await response.json();
       // this.UpdateUserDetailFromGoogleApi();
        let isApproval = '';
        radioValue.forEach((item) => {
            if (item.checked) { isApproval = item.value }
        })
        let googleUpdate= await  this.UpdateUserDetailFromGoogleApi();
        if (responseData.IsSuccessful) {
            this.setState({ successImg: true, SubmitResponse: "Thank you for your approval. Changes will take effect tomorrow on The HOP.", HideContent: true, countVal:1 });
            if (isApproval == 'Approve') {
                let googleUpdateImage =await this.UpdateUserImageFromGoogleApi();
            }
            else {
                this.ShowMessageBox(responseData.FriendlyMessage, 1)
            }
            document.getElementById('Comments').value = ''

        } else {
            this.setState({ successImg: false, SubmitResponse: responseData.FriendlyMessage, HideContent: true, countVal: 1});
        }
    }
    UpdateUserDetailFromGoogleApi = async () => {
        let item = this.state.employeeProfileDetails;
        var objData = {
            customSchemas: {
                Questions: {
                    Answer1: item.Answer1 == null ? '@@' : item.Answer1 ,
                    Question1: item.Question1DisplayText == null ? '@@' : item.Question1DisplayText,
                    Answer2: item.Answer2 == null ? '@@' : item.Answer2,
                    Question2: item.Question2DisplayText == null ? '@@' : item.Question2DisplayText,
                    Answer3: item.Answer3 == null ? '@@' : item.Answer3,
                    Question3: item.Question3DisplayText == null ? '@@' : item.Question3DisplayText,
                    Answer4: item.Answer4 == null ? '@@' : item.Answer4,
                    Question4: item.Question4DisplayText == null ? '@@' : item.Question4DisplayText,
                    Answer5: item.Answer5 == null ? '@@' : item.Answer5,
                    Question5: item.Question5DisplayText == null ? '@@' : item.Question5DisplayText,
                },
                Preferred_Pronouns: {
                    PREFERREDPRONOUNS: this.state.employeeProfileDetails.PreferredPronouns == null ? '@@' : this.state.employeeProfileDetails.PreferredPronouns
                },
                NAME_PRONUNCIATION: {
                    NAME_PRONUNCIATION: this.state.employeeProfileDetails.NamePronunciation == null ? '@@' : this.state.employeeProfileDetails.NamePronunciation
                },
                TEAM_NAME: {
                    TEAM_NAME: this.state.employeeProfileDetails.TeamName == null ? '@@' : this.state.employeeProfileDetails.TeamName
                },
                Brief_Job_Description: {
                    Brief_Job_Description: this.state.employeeProfileDetails.Description == null ? '@@' : this.state.employeeProfileDetails.Description
                },
                SEATINGLOCATION: {
                    SEATINGLOCATION: this.state.employeeProfileDetails.SeatingLocation == null ? '@@' : this.state.employeeProfileDetails.SeatingLocation
                },
                Collective_Group_Membership: {
                    ERGMEMBERSHIP: [
                        {
                            "type": "work",
                            "value": this.state.employeeProfileDetails.ERGMembership == null ? '@@' : this.state.employeeProfileDetails.ERGMembership.replaceAll(",", "|")
                        }
                    ]

                },
                Primary_Phone: {
                    mobile_phone: this.state.employeeProfileDetails.MobilePhoneNumber == null ? '@@' : this.state.employeeProfileDetails.MobilePhoneNumber,
                    work_phone_ext: this.UserDetails.GoogleData.customSchemas.Primary_Phone.work_phone_ext == null ? '@@' : this.UserDetails.GoogleData.customSchemas.Primary_Phone.work_phone_ext
                }
            }
            //,
            //phones: [{
            //    "value": this.state.employeeProfileDetails.MobilePhoneNumber == null ? '' : this.state.employeeProfileDetails.MobilePhoneNumber,
            //    "type": "mobile"
            //},
            //{
            //    "value": this.UserDetails.GoogleData.phones[0].value == null ? '' : this.UserDetails.GoogleData.phones[0].value,
            //    "type": "work"
            //}]
        }
        let param = {
            "NTName": item.EmailAddress != null ? item.EmailAddress.split('@')[0].toString() : '',
            "Data": JSON.stringify(objData)
        }
        var updateResponse = await HttpRequest('SymptomTracker/UpdateUserDetailFromGoogleApi', 'POST', param);
        let res = await updateResponse.json();
    }

    //DataURLtoFile = (dataurl) => {

    //    var arr = dataurl.split(','),
    //        mime = arr[0].match(/:(.*?);/)[1],
    //        bstr = atob(arr[1]),
    //        n = bstr.length,
    //        u8arr = new Uint8Array(n);

    //    while (n--) {
    //        u8arr[n] = bstr.charCodeAt(n);
    //    }

    //    return new File([u8arr], 'Temp', { type: mime });
    //}
    UpdateUserImageFromGoogleApi = async () => {
        let item = this.state.employeeProfileDetails;
        let approvalUserDetails = this.state.ApprovalUserDetails;
        let param = {
            "NTName": item.EmailAddress.split('@')[0],
            "id": approvalUserDetails.id,
            "primaryEmail": item.EmailAddress,
            "kind": approvalUserDetails.kind,
            "etag": approvalUserDetails.etag,
            "photoData": item.EmployeeImage,
            "mimeType": 'JPEG',
            "width": '96',
            "height": '96'
        }
        var updateResponse = await HttpRequest('SymptomTracker/UpdateUserImageFromGoogleApi', 'POST', param);
        let res = await updateResponse.json();
        //if (res.success) {
        //    this.ShowMessageBox("Image successfully updated on LumApps", 1)
        //}
        //else {
        //    this.ShowMessageBox("Something went wrong while updating data on lumapp",2)
        //}
    }

    render() {

        if (this.UserDetails.GoogleData == null)
            return <></>

        return (
            <>
                <div className="container payrollPasswordReset">
                    <div className="row">

                        <div className="col s12 m12 l12" style={{ display: this.state.HideContent == false ? 'block' : 'none' }}>
                            <p></p>
                            <div className="row">
                                <div className="col s12 m12 l12">

                                    <div className="card horizontal employeeProfileReview" style={{ display: this.state.HideFristMessage == true ? 'none' : 'flex' }}>
                                        <div className="card-image">
                                            <img src="images/hoplogo.png" className="hoplogo-image"/>
                                        </div>
                                        <div className="card-stacked">
                                            <div className="card-content">
                                                <div>

                                                    <div className="row">
                                                        <div className="col s12 l12">
                                                            <h5>Employee Profile Review</h5>
                                                        </div>
                                                    </div>
                                                    {this.RenderFormData()}
                                                </div>

                                                {/*<div className="row">
                                                <div className="col s12 l12 m12">Your photo is a vital part of The Hop community. Please submit a personal photo of your choosing that is workplace appropriate and that prominently features your face. Your photo will be reviewed by your manager prior to posting. Your badge photo will be used as your default picture if you do not submit a photo.</div>
                                            </div>*/}

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="col s12 m12 l12">

                        </div>


                        <div className="col s12 l12 m12" style={{ display: this.state.HideFristMessage == true ? 'block' : 'none' }}>
                            <div className="card horizontal">
                                <div className="card-stacked">
                                    <div className="card-content">
                                        <div className="messageBoxDisplay">

                                            <img src={this.state.imgDis != false ? 'images/success-icon.svg' :'images/error-icon.svg'} />
                                            <h6>{this.state.MessageFristTime}</h6>

                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                        <div className="col s12 l12 m12" style={{ display: this.state.HideContent == true && this.state.countVal == 1 ? 'block' : 'none' }}>
                            <div className="card horizontal">
                                <div className="card-stacked">
                                    <div className="card-content">
                                        <div className="messageBoxDisplay">
                                            <img src={this.state.successImg == true ? 'images/success-icon.svg' : 'images/error-icon.svg'} />

                                            <h6>{this.state.SubmitResponse}</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}
