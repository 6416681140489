import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { MessageBox } from '../../common/modalBox/MessageBox'
import { HttpRequest,validateEmail } from '../../common/CommonOperations';

export class SLINGscriber extends Component {
    static displayName = SLINGscriber.name;

    constructor(props) {
        super(props);
        this.state = {
            slingScriberContentRequest: [],
            slingScriberFormRequest: [],   
            showBecomeACustomerModal: false,
            showMessageBox: false, message: '', messageType: '1'
        };
        this.UserDetails = this.props.UserDetails;
        this.path = "https://intranet.global.dish.com";
    }
    componentDidMount() {
        this.GetSlingScriberContent();
        this.GetSlingScriberForm();       
    }
    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });
    
    becomeACustomer = () => { this.setState({ showBecomeACustomerModal: true})}
    hideBecomeACustomerModal = () => {this.setState({showBecomeACustomerModal: false})}

    GetSlingScriberContent = async ()=> {
        var response = await HttpRequest('Content/GetContentByRouteText/slingScriberContent','Get');
        const res = await response.json();
        this.setState({ slingScriberContentRequest: res.data })
    }
    GetSlingScriberForm = async () => {
        var response = await HttpRequest('Content/GetContentByRouteText/slingScriberForm','Get');
        const res = await response.json();
        this.setState({ slingScriberFormRequest: res.data })
    }
    PostSlingScriberInfo = async (e) => {
        let email = document.getElementById('secondaryEmailText').value;
        if (email.length == 0) {
            this.ShowMessageBox("Secondary email cannot be blank", 3); e.stopPropagation(); return false;

        }
        else if (!validateEmail(email)) {
            this.ShowMessageBox("Please enter a valid email address which include @ and .", 3); e.stopPropagation(); return false;
        }
        try {
            let param = { 'EmployeeNumber': this.UserDetails.GoogleData.externalIds[0].value, 'EmailAddress': email }
            var response = await HttpRequest('Content/PostSlingscriberUserInfo', 'POST', param);
            const data = await response.json();

            this.setState({ showBecomeACustomerModal: false });
            if (data.IsSuccessful) {
                this.ShowMessageBox('Thank you for your interest in the Sling Scriber program!<br />If you experience issues signing up, please contact <a ext-Link href="mailto: SlingscriberSupport@sling.com">SlingscriberSupport@sling.com</a>');
            } else {
                this.ShowMessageBox("You have already submitted your request", 3);
            }
        } catch (e) {

        }
    }
    RenderImage = () => {
        if (this.state.slingScriberContentRequest.length == 0)
            return <></>;
        else {
            return (
                this.state.slingScriberContentRequest.imageGroup.imageGroupDetails.map((item, index) => (
                    <img key={item.imageUrl} src={this.path + item.imageUrl} alt="" />
                ))
            );
        }
    }

    render() {
        if (this.UserDetails.GoogleData == null)
            return <></>
        return (
            <>
                <div className="container slingScriberCon">
                    <div className="col s12 m7">
                        <div className="card horizontal dishScribre slingScriber">
                            <div className="card-content">
                                <img className="img-responsive tocenter" src="images/sling.png" alt="" />
                                <h4>{this.state.slingScriberContentRequest.title}</h4>
                                <p><span>{this.state.slingScriberContentRequest.body}</span></p>
                                <p><button type="button" className="btn btn-primary" onClick={this.becomeACustomer} style={{ display: this.UserDetails.GoogleData.organizations[0].title != 'Contractor' ? 'inline-block' : 'none' }}>SIGN UP NOW</button></p>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: this.state.slingScriberContentRequest.imageFooterText }} ></div>
                    <div style={{ textAlign: 'center' }}>    <img className="img-responsive tocenter" src="images/SlingGridBox.jpg" alt="" /> </div>
                    <div style={{ fontWeight: 'bold', padding: '10px', fontSize: '20px', textAlign: 'center' }}>Get over 160 channels for $5/mo </div>
                </div>

                <Modal show={this.state.showBecomeACustomerModal} onHide={this.hideBecomeACustomerModal} className="modalMySaveCall slingModal">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.slingScriberFormRequest.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col s12 m12 l12">
                                <p dangerouslySetInnerHTML={{ __html: this.state.slingScriberFormRequest.body }}></p>
                                <div className="input-field">
                                    <input id="FirstName" type="text" className="validate" disabled defaultValue={this.UserDetails.GoogleData.name.givenName} />
                                    <label htmlFor="FirstName" className="active">First Name</label>
                                </div>
                                <div className="input-field">
                                    <input id="LastName" type="text" className="validate" disabled defaultValue={this.UserDetails.GoogleData.name.familyName} />
                                    <label htmlFor="LastName" className="active">Last Name</label>
                                </div>
                                <div className="input-field">
                                    <input id="LastName" type="text" className="validate" disabled defaultValue={this.UserDetails.GoogleData.externalIds[0].value} />
                                    <label htmlFor="LastName" className="active">Employee Number</label>
                                </div>
                                <div className="input-field">
                                    <input id="secondaryEmailText" type="text" className="validate" defaultValue={this.UserDetails.GoogleData.primaryEmail} />
                                    <label htmlFor="secondaryEmailText" className={this.UserDetails.GoogleData.primaryEmail != null ? 'active' : ''}>Email Address</label>

                                </div>
                                <div className="input-field">
                                    <Button variant="primary" onClick={this.PostSlingScriberInfo}>Submit</Button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <div className="disclaimerText" dangerouslySetInnerHTML={{ __html: this.state.slingScriberFormRequest.imageFooterText }} ></div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}
