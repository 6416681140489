import React, { Component } from 'react';

export class BOOSTscriber extends Component {
    static displayName = BOOSTscriber.name;
    constructor(props) {
        super(props);
        this.state = { showModel: false, buttonValue: false, };
        this.UserDetails = this.props.UserDetails;
        this.googleData = this.UserDetails.GoogleData;
    }
    componentDidMount = () => {
    }
    buttonEnable = (e) => {
        (e.target.checked == true) ? this.setState({ buttonValue: true }) : this.setState({ buttonValue: false })
    };
    openModalWindow = () => {
       // this.setState({ showModel: !this.state.showModel })
        this.setState((state) => ({ showModel: !state.showModel })); //asif
    };

    boostscriberSentData = (e) => {
        e.preventDefault();

    }

    renderFromData = () => {
        if (this.googleData == null || this.googleData == undefined || this.googleData == '') return '';
        const { givenName, familyName } = this.googleData.name;
        return (

            <>
                <p>  <img src="./images/logo-boost-infinite.png" /></p>
                <h4>Sign Up Instructions</h4>
                <p>{
                    /*Please only complete the information below if you have already created a Boost Infinite account. Once you have, confirm the information below and click the SUBMIT button. Once you submit your information, one month of coins will be applied to your account*/}
                    Please only complete the information below if you have already created a Boost Infinite account. Once you have, confirm the information below and click the SUBMIT button. Once you submit your information, a credit will be applied to your account.
                </p>
                <form onSubmit={this.boostscriberSentData}>
                    <ul>

                        <li>

                            <label>First Name:</label>
                            <input type="text" defaultValue={givenName} />
                        </li>
                        <li>
                            <label>Last Name:</label>
                            <input type="text" defaultValue={familyName} />

                        </li>
                        <li>
                            <label>Employee Number:</label>
                            <input type="text" defaultValue={this.googleData.externalIds[0].value} />
                        </li>
                        <li>
                            <label>Email Address:</label>
                            <input type="text" defaultValue={this.googleData.primaryEmail} />
                        </li>
                        <li>
                            <label>Boost Infinite Phone Number: </label>
                            <input type="text" />
                        </li>
                        <li><input type="submit" value="Submit" className="btn btn-primary" /></li>
                    </ul>
                </form>
                <hr />
                <p className="disclaimerText"><strong>Disclaimers</strong></p>
                <p className="disclaimerText">
                    Billing and Cancellation: Your credit card will be charged monthly for applicable subscription until you cancel your service. No credits or refunds will be issued for partial months after cancellation. Offer only valid for employees of SLING TV L.L.C. or DISH Network L.L.C. and is nontransferable.</p>
                <p className="disclaimerText">
                    Miscellaneous: One account per DISH or Sling employee. State and local taxes apply. All prices, fees, charges, features, functionality and offers subject to change without notice. </p>
            </>
        )

    }

    render() {

        //  const { givenName, familyName } =  ? { givenName:'', familyName:''}:this.googleData.name;

        return (
            <>
                <div className="container boostscriber bigContainer ng-scope">
                    <div className="form-boostSign formImgCenter">
                        <span className="cricleImg">
                            <img src="images/boost-mobile-logo.png" className="img-responsive" alt="" />
                        </span>
                        <div className="fontSizeLarge" style={{ display: 'none' }}>Hello! We appreciate your interest in BOOSTscriber! We're working on some exciting enhancements to this process and have temporarily placed new activations on hold. Please check back in early- to mid-December!</div>
                        <div className="fontSizeLarge">Hello! We appreciate your interest in BOOSTscriber! We're working on some exciting enhancements to this process and have temporarily placed new activations on hold. Please check back soon!
                        </div>
                        <div style={{ display: 'none' }}>
                            <div className="contentScroll">
                                <h1><strong>Important Information!</strong><br /><strong>Read <span className="textUnderline">all</span> of the information below carefully.</strong></h1>

                                <p>Some features are not yet available on the new platform, but we expect to offer them within a few months. That's one reason the service is discounted to $5 for all plans until October 15!</p>
                                <p>Those features (not yet available) include:</p>
                                <ul>
                                    <li>International calling and texting</li>
                                    <li>Device insurance/Boost Shield</li>
                                    <li>Family plans</li>
                                    <li>Visual voicemail</li>
                                    <li>Other value-added services or "extras" (call screening, etc.)</li>
                                </ul>
                                <h2><strong><span className="textUnderline">If your device is currently carrier-locked, you must unlock it to activate on the new platform. Please perform this action before signing up.</span></strong></h2>
                                <p><span className="textUnderline"><b> Unless you are a current BOOSTscriber, please reach out to <a href="mailto:BoostscriberFeedback@dish.com">BoostscriberFeedback@dish.com</a>  to ensure your device is unlocked.</b></span></p>
                                <p><strong><em>A few other things you should know:</em></strong></p>
                                <ul>
                                    <li>You'll need to create a new account upon sign-up. Your current account history and balance will not transfer over.</li>
                                    <li>Taxes and fees are not included in the base plan price.</li>
                                    <li>
                                        You'll need a new SIM card, which you should request be shipped to you when creating your account on the new platform.
                                        <ul>
                                            <li>To ensure your device is unlocked, contact your current carrier.</li>
                                            <li>If you have any issues upon activation or questions about the new platform, please contact <a href="mailto: BOOSTscriber_Support@dish.com">BOOSTscriber_Support@dish.com</a></li>
                                            <li></li>
                                        </ul>

                                    </li>
                                    <li>Manufacturer warranties will not transfer when you switch to the new platform.</li>
                                </ul>
                                <p></p>
                                <p><strong><em>Apple/iPhones:</em></strong></p>

                                <ul>
                                    <li>Some Apple features are not currently available on the new platform, including but not limited to a customer care phone number, the self-care web page, visual voicemail, Wi-Fi calling, and tethering (mobile hotspot). These functions are expected to arrive in Q3 and Q4.</li>
                                    <li>Currently, the new platform cannot support iPads, Apple Watches, or other companion devices.</li>
                                    <li>There will be a 5-15 minutes delay from the first power-on of the device after activation, to the enablement of iMessage and FaceTime due to two-factor SMS authentication.</li>
                                    <li><span className="textUnderline">"Find My iPhone" must be turned off before activating on the new platform.</span></li>
                                </ul>
                                <p></p>
                                <p>By participating in DISH Wireless L.L.C.'s pilot 5G wireless network launch program and associated services, I agree to allow DISH Wireless L.L.C., DBA Boost Mobile, and/or Genesis and its representatives to call, text, or email me using the number(s), and/or information gathered in association with the use of DISH Wireless L.L.C. services regardless of whether the information is on any do not contact lists, or otherwise restricted by any applicable do not contact laws. I understand that the messaging may be delivered using a variety of systems including using automated technology or messaging system(s).</p>
                            </div>

                            <div>

                                <label> <input type="checkbox" className="filled-in" onChange={this.buttonEnable} />
                                    <span>Do you agree to the Acknowledgement, Terms and Conditions?</span>
                                </label></div>
                            <ul>
                                <li><button type="button" className="btn btn-primary" disabled={!this.state.buttonValue} onClick={this.openModalWindow}>Agree and continue</button></li>
                            </ul>

                        </div>
                    </div>
                </div>
                {/* Custom Modal box*/}
                <div className="bntAfterubSubContainer" style={{ display: this.state.showModel == true ? 'block' : 'none' }}>
                    <div className="formNewUserBootsSignup bntAfterubSub">
                        <div>
                            <p>Please note, you must be on DISH internet or VPN in order to proceed.</p>
                            <p>
                                <strong>Important note: </strong>
                                If you are an existing BOOSTscriber, please reach out to  <a href="mailto:BOOSTscriber_support@dish.com">BOOSTscriber_support@dish.com</a>
                                to retrieve your current account number. You will need it for&nbsp;future steps.
                            </p>
                            <a href="https://my.boostmobile.com/boost-subscriber.html" target="_blank" className="btn btn-primary">Sign up Now </a>
                        </div>
                        <button href="javascript:void(0)" onClick={this.openModalWindow} className="close-btn">&times;</button>
                    </div>
                </div>


                {/*new  boostscriber page start */}
                <section className="new-boostScrberSection" style={{ display: 'none' }}>>
                    <div className="container">
                        <div className="row">
                            <div className="col l12">
                                <div className="card horizontal">

                                    <div className="contianer-boostscribe-new">

                                        {this.renderFromData()}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*new  boostscriber page End */}
            </>
        );
    }
}









