import React, { Component } from 'react';
import { MessageBox } from './../../common/modalBox/MessageBox';
import { HttpRequest, HttpRequestWithouLoader, GetStartAndEndDate, CheckSupportIDException, HTMLTableExport, ShowLoader, ActiveMenu } from './../../common/CommonOperations';
export class BOOSTscriberNew extends Component {
    static displayName = BOOSTscriberNew.name;
    constructor(props) {
        super(props);
        this.state = {
            showModel: false, buttonValue: false,
            statusMessage: '',
            showMessageBox: false,
            message: '',
            messageType: '',
            mesasgeApiCheck: '',
            showModalApiResposne: false,
            showModalApiResposneTwo: true,
            apiResponse: '',

        };
        this.UserDetails = this.props.UserDetails;
        this.googleData = this.UserDetails.GoogleData;
        this.refInputName = React.createRef();
        this.refInputLastName = React.createRef();
        this.refInputEmpNumber = React.createRef();
        this.refInputEmpEmail = React.createRef();
        this.refInputEmpBoostNumber = React.createRef();
    }
    componentDidMount = async () => {
        if (this.googleData == null || this.googleData == undefined || this.googleData == '') return '';
        //let param = {            
        //    EmployeeID: this.googleData.externalIds[0].value,
        //    DeviceName:'onload'
        //}
        //var response = await HttpRequest('Content/SaveBoostInfiniteDetails', 'POST', param);
        //const responseData = await response.json();

        //if (responseData.IsSuccessful && responseData.ErrorMessage == 'Employee Exists') {
        //    // this.props.history.push('/Scriber/BOOSTscriber/');
        //    this.setState({
        //        showModalApiResposne: true, mesasgeApiCheck: 'We appreciate your interest in BOOSTInfinite!<br /> You have already subscribed to this program.', apiResponse: 'success'
        //    })
        //} else {
        //    this.setState({ showModalApiResposneTwo:true})
        //}
    }
    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });


    buttonEnable = (e) => {
        (e.target.checked == true) ? this.setState({ buttonValue: true }) : this.setState({ buttonValue: false })
    };
    openModalWindow = () => {
        this.setState((state) => ({ showModel: !state.showModel }))
    };

    validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    isNumber = (str) => {
        var pattern = /^\d+\.?\d*$/;
        return pattern.test(str);  // returns a boolean
    }

    MaxLengthCheck = (e) => {
    }

    boostscriberSentData = async (e) => {
        //let reg = new RegExp('^[0-9]*$');

        e.preventDefault();
        if (this.refInputName.current.value.length == 0 || this.refInputName.current.value == '') {
            this.ShowMessageBox('Please enter Employee Name', 3);
            this.refInputName.current.focus();
            return false;
        }
        else if (this.refInputLastName.current.value.length == 0 || this.refInputLastName.current.value == '') {
            this.ShowMessageBox('Please enter Employee Last Name', 3);
            this.refInputLastName.current.focus();
            return false;
        }
        else if (this.refInputEmpNumber.current.value.length == 0 || this.refInputEmpNumber.current.value == '') {
            this.ShowMessageBox('Please enter Employee Number', 3);
            this.refInputEmpNumber.current.focus();
            return false;
        }
        else if (this.refInputEmpEmail.current.value.length == 0 || this.refInputEmpEmail.current.value == '') {
            this.ShowMessageBox('Please enter Employee Email', 3);
            this.refInputEmpEmail.current.focus();
            return false;
        }
        else if (!this.validateEmail(this.refInputEmpEmail.current.value)) {
            this.ShowMessageBox('Please enter a Valid Employee Email', 3);
            this.refInputEmpEmail.current.focus();
            this.refInputEmpEmail.current.value = '';
            return false;
        }
        else if (this.refInputEmpBoostNumber.current.value.length == 0 || this.refInputEmpBoostNumber.current.value == '') {
            //  debugger;
            this.ShowMessageBox('Please enter Boost Mobile Phone Number', 3);
            this.refInputEmpBoostNumber.current.focus();
            return false;
        }
        else if (this.refInputEmpBoostNumber.current.value.length < 10 || this.refInputEmpBoostNumber.current.value.length > 10) {
            this.ShowMessageBox('Please enter 10 digit Boost Mobile Phone Number', 3);
            this.refInputEmpBoostNumber.current.value = '';
            this.refInputEmpBoostNumber.current.focus();

            return false;
        }

        else if (!this.isNumber(this.refInputEmpBoostNumber.current.value)) {
            this.ShowMessageBox('Please enter a valid Boost Mobile Phone Number', 3);
            this.refInputEmpBoostNumber.current.value = '';
            this.refInputEmpBoostNumber.current.focus();
            return false;
        }
        else if (this.refInputEmpBoostNumber.current.value.length > 10 || this.refInputEmpBoostNumber.current.value.length < 10) {
            this.ShowMessageBox('Boost Mobile Phone Number length not more then 10 digit or not less then 10 digit', 3);
            this.refInputEmpBoostNumber.current.value = '';
            this.refInputEmpBoostNumber.current.focus();
            return false;
        }
        else {

            let param = {
                FirstName: this.refInputName.current.value,
                LastName: this.refInputLastName.current.value,
                EmployeeID: this.refInputEmpNumber.current.value,
                EmployeeEmail: this.refInputEmpEmail.current.value,
                BoostMobilePhoneNumber: this.refInputEmpBoostNumber.current.value,
                DeviceName: 'onsubmit'
            }
            debugger;
            var response = await HttpRequest('Content/SaveBoostInfiniteDetails', 'POST', param);
            // debugger;
            const responseData = await response.json();

            if (responseData.IsSuccessful && (responseData.ErrorMessage == '' || responseData.ErrorMessage == null)) {
                // this.ShowMessageBox('Record has been saved successfully ', 1);
                //this.props.history.push('/Scriber/BOOSTscriber/');
                this.setState({ showModalApiResposne: true, mesasgeApiCheck: 'Record has been saved successfully', apiResponse: 'success' })
            }
            else if (responseData.IsSuccessful && responseData.ErrorMessage == 'Employee Exists') {
                // this.ShowMessageBox('Employee already exists', 2);

                //this.props.history.push('/Scriber/BOOSTscriber/');

                this.ShowMessageBox('We appreciate your interest in BOOSTMobile!<br />You have already subscribed to this program for phone number - ' + this.refInputEmpBoostNumber.current.value, 2);
                this.refInputEmpBoostNumber.current.value = '';


                //this.setState({
                //    showModalApiResposne: true, mesasgeApiCheck: 'We appreciate your interest in BOOSTInfinite!<br />You have already subscribed to this program.', apiResponse: 'success' })

            }
            else if (!responseData.isSuccessful) {
                this.ShowMessageBox(responseData.errorMessage, 3);
                this.refInputEmpBoostNumber.current.value = '';
            }
        }
    }

    renderFromData = () => {
        if (this.googleData == null || this.googleData == undefined || this.googleData == '') return '';
        const { givenName, familyName } = this.googleData.name;
        return (

            <>
                <p>  <img src="./images/logo-boost-mobile.png" /></p>
                <h4>Sign Up Instructions</h4>
                <p>Please only complete the information below if you have already created a Boost Mobile account. Once you have, confirm the information below and click the SUBMIT button. Once you submit your information, a credit will be applied to your account.</p>

                <form onSubmit={this.boostscriberSentData}>
                    <ul>

                        <li>

                            <label>First Name:</label>
                            <input type="text" defaultValue={givenName} disabled ref={this.refInputName} />

                        </li>
                        <li>
                            <label>Last Name:</label>
                            <input type="text" defaultValue={familyName} disabled ref={this.refInputLastName} />

                        </li>
                        <li>
                            <label>Employee Number:</label>
                            <input type="text" defaultValue={this.googleData.externalIds[0].value} disabled ref={this.refInputEmpNumber} />
                        </li>
                        <li>
                            <label>Email Address:</label>
                            <input type="text" defaultValue={this.googleData.primaryEmail} disabled ref={this.refInputEmpEmail} />
                        </li>
                        <li>
                            <label>Boost Mobile Phone Number: </label>
                            <input type="text" onKeyPress={(event) => { if (!/\d/.test(event.key)) { event.preventDefault() } }} placeholder="1111112222" ref={this.refInputEmpBoostNumber} maxLength="10" />
                        </li>
                        <li><input type="submit" value="Submit" className="btn btn-primary" /></li>
                    </ul>
                </form>
                <hr />
                <p className="disclaimerText"><strong>Disclaimers</strong></p>
                <p className="disclaimerText">
                    Billing and Cancellation: Your credit card will be charged monthly for applicable subscription
                    until you cancel your service. No credits or refunds will be issued for partial months after
                    cancellation. Offer only valid for employees of SLING TV L.L.C. or DISH Network L.L.C. and is
                    nontransferable.</p> <p className="disclaimerText">
                    Miscellaneous: One account per DISH or Sling employee. State and local taxes apply. All prices,
                    fees, charges, features, functionality and offers subject to change without notice. </p>
            </>
        )

    }

    render() {

        //  const { givenName, familyName } =  ? { givenName:'', familyName:''}:this.googleData.name;

        return (
            <>


                {/*new  boostscriber page start */}
                <section className="new-boostScrberSection">
                    <div className="container">
                        <div className="row" style={{ 'display': this.state.showModalApiResposne == true ? 'none' : 'block' }}>
                            <div className="col l12">
                                <div className="card horizontal">

                                    <div className="contianer-boostscribe-new">

                                        {this.renderFromData()}
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ 'display': this.state.showModalApiResposne == true ? 'block' : 'none' }}>
                            <div className="col l3"></div>
                            <div className="col l6">
                                <div className="card horizontal modalMesageBoostScriber">
                                    <p><img src="./images/logo-boost-infinite.png" /></p>
                                    <p> <img src={this.state.apiResponse == 'success' ? "images/success-icon.svg" : "images/error-icon.svg"} className="imgBox" /></p>
                                    <h2 dangerouslySetInnerHTML={{ __html: this.state.mesasgeApiCheck }}></h2>
                                </div>
                            </div>
                            <div className="col l3"></div>
                        </div>

                    </div>
                </section>
                {/*new  boostscriber page End */}
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}









