import React, { Component } from 'react';

import { MessageBox } from '../../common/modalBox/MessageBox'
import { HttpRequest } from '../CommonOperations';

export class BoostSignUpImei extends Component {
    static displayName = BoostSignUpImei.name;
    constructor(props) {
        super(props);
        this.state = {
            showModel: false, buttonValue: false, showMessageBox: false, message: '', messageType: '1',
        }
        this.UserDetails = this.props.UserDetails;
    }
    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });
    SaveBoostEMIDetails = async () => {
        if (document.getElementById('txtAddress').value.trim() == '' || document.getElementById('txtAddress').value.trim() == null) {
            document.getElementById('txtAddress').focus();
            this.ShowMessageBox("Address can not be Blank .", 3); return false;
        }
        if (document.getElementById('txtCurrentProvider').value.trim() == '' || document.getElementById('txtCurrentProvider').value.trim() == null) {
            document.getElementById('txtCurrentProvider').focus();
            this.ShowMessageBox("Current provider can not be Blank .", 3); return false;
        }
        if (document.getElementById('txtImei').value.trim() == '' || document.getElementById('txtImei').value.trim() == null) {
            document.getElementById('txtImei').focus();
            this.ShowMessageBox("IMEI can not be Blank .", 3); return false;
        }
        if (document.getElementById('txtConfirmEmi').value.trim() == '' || document.getElementById('txtConfirmEmi').value.trim() == null) {
            document.getElementById('txtConfirmEmi').focus();
            this.ShowMessageBox("Confirm IMEI can not be Blank .", 3); return false;
        }
        if (document.getElementById('txtConfirmEmi').value.trim() !== '' || document.getElementById('txtConfirmEmi').value.trim() !== null) {
            if (document.getElementById('txtConfirmEmi').value.trim() !== document.getElementById('txtImei').value.trim()) {
                document.getElementById('txtConfirmEmi').focus();
                this.ShowMessageBox("IMEI does not match .", 3); return false;
            }
        }

        let param = {
            EmployeeID: this.UserDetails.GoogleData.externalIds[0].value,
            IMEI: document.getElementById('txtImei').value.trim(),
            Address: document.getElementById('txtAddress').value.trim(),
            CurrentProvider: document.getElementById('txtCurrentProvider').value.trim(),
            DeviceName: '',// document.getElementById('txtImei').value.trim(),
            Eligibility: 'No'// document.getElementById('txtImei').value.trim(),
        }
        var response = await HttpRequest('Content/SaveBoostEMIDetails', 'POST', param);
        const responseData = await response.json();

        let message = "<p><span style='font - weight: 400; '>Hello! Thank you for starting the process to become a BOOSTscriber. We have received your submission and a new Boost mobile SIM card will be shipped to you in the next 2-3 business days. If our team needs any further information about your device you will be emailed by our support team. Once you receive this SIM card, please insert into your device.</span></p><p><span style = 'font - weight: 400; '> If you are currently a SPRINT customer or BOOST customer please call 1. 877. 730.6859 for activation and/ or porting instruction. (This number is under no circumstances to be shared with anyone outside of Dish Network)</span></p><p><span style = 'font - weight: 400; '> If you are new to Boost please follow the below link to activate your phone, set up your account and pay for your first month of Boost Mobile service if you have not already.</span><a href = 'https://activate.boostmobile.com/primary/activations-home?intnav=UtilNav:Activate' target='_blanck'><span style = 'font-weight: 400;'>&nbsp;https://activate.boostmobile.com/primary/activations-home?intnav=UtilNav:Activate</span></a> <span style='font-weight: 400;'>&nbsp;</span></p><p><span style = 'text-decoration: underline;' <span style = 'font-weight: 400;'> Once your plan is active, has been payed through one month, and your new SIM is activated, please proceed to SIGN UP PART 2 in The HOP</span></span></p>";

        if (responseData.IsSuccessful) {
            this.ShowMessageBox(message, 1); return true;
        }
        else {
            this.ShowMessageBox(responseData.FriendlyMessage, 3);
            return false;
        }
    }

    render() {
        if (this.UserDetails.GoogleData == null || this.UserDetails.GoogleData == undefined) { return '' }
        return (
            <>
                <div className="boostscriber-container">
                    <div className="modal fade customModalBoxPopup customMessagePopup" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" ng-click="RedirectBoostScriberIMEI()" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content " style={{ width: '450px' }}>
                                <div className="modal-header" style={{ backgroundColor: '#f99f38' }}>
                                    <h5 className="modal-title">Message</h5>
                                    <button type="button" ng-click="RedirectBoostScriberIMEI()" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" id="divErrorMsg" style={{ textAlign: 'left!important', fontSize: '14px' }}>

                                </div>
                                <div className="modal-footer">

                                    <button type="button" id="btnCloseConfirm" className="btn btn-boost submitBtn " style={{ backgroundColor: '#f99f38' }} ng-click="RedirectBoostScriberIMEI()" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="bootsSignupBg clearfix">
                        {/*<div class="container boostscriber" ng-if="IsAlreadyBoostScriber == false ">*/}
                        <div className="container boostscriber">
                            <div claclassNamess="container boostscriber"></div>
                            <div className="row">
                                <div className="co-lg-12 col-md-12 col-xs-12 col-sm-12">




                                    <div className="form-boostSign formImgCenter">
                                        <span className="cricleImg">
                                            <img src="images/boost-mobile-logo.png" alt="" />
                                        </span>

                                        <ul className="listDisplay">


                                            <li><h5> Please complete the below form so that we may ship you an eligible SIM Card</h5></li>

                                            <li><span>First Name</span><input type="text" readonly="readonly" defaultValue={this.UserDetails.GoogleData.name.givenName} className="form-control" /> </li>
                                            <li><span>Last Name</span> <input type="text" readonly="readonly" defaultValue={this.UserDetails.GoogleData.name.familyName} className="form-control" /></li>
                                            <li><span>Employee ID</span> <input type="text" readonly="readonly" defaultValue={this.UserDetails.GoogleData.externalIds[0].value} className="form-control" /></li>
                                            <li><span style={{ display: 'flex!important' }}> Shipping Address &nbsp;<span style={{ fontSize: '10px', paddingTop: '5px' }}>(Street, City, State, and ZIP)</span><span style={{ color: 'red' }} className="mendatoryFeild">*</span></span>
                                                <input type="text" id="txtAddress" className="form-control" /></li>
                                            <li> <span style={{ display: 'flex!important' }}> Current Provider<span className="mendatoryFeild" style={{ color: 'red' }}>*</span></span>
                                                <input type="text" id="txtCurrentProvider" className="form-control" /></li>
                                            <li> <span style={{ display: 'flex!important' }}>
                                                IMEI<span style={{ color: 'red' }} className="mendatoryFeild">*</span><span data-toggle="tooltip" data-placement="right" className="glyphicon glyphicon-question-sign" title="Your IMEI is a numeric ID that can usually be found in the �General� or �About Phone� settings on your device."></span>
                                            </span> <input id="txtImei" type="text" className="form-control" />
                                            </li>
                                            <li>  <span style={{ display: 'flex!important' }}> Confirm IMEI<span style={{ color: 'red' }} className="mendatoryFeild">*</span></span>
                                                <input type="text" id="txtConfirmEmi" className="form-control" /> </li>
                                            <li> <span style={{ display: 'flex!important' }}> Couldn't determine phone eligibility?<span style={{ color: 'red' }} className="mendatoryFeild">*</span></span>
                                                <input type="text" readonly="readonly" defaultValue="NO" data-ng-model="boostScriberDetails.PhoneElg" className="form-control" /></li>


                                            <li><button type="button" onClick={() => this.SaveBoostEMIDetails()} className="btn btn-primary">Submit</button></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}
