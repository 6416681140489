import React, { Component } from 'react';
import { HttpRequest, ShowLoader, HTMLTableExport } from '../common/CommonOperations';
import 'react-data-table-component-extensions/dist/index.css';
import * as XLSX from "xlsx";
import { MessageBox } from '../../components/common/modalBox/MessageBox';

export class EmployeePhoneNumber extends Component {
    static displayName = EmployeePhoneNumber.name;
    constructor(props) {
        super(props);
        this.state = {
            tableData: '',
            showMessageBox: false, message: '', messageType: '1', selectedFile: [], showTableWithData: false, NtLoginUserDetails: [], updatedeskPhone: '',
            NtLoginListArray: [], onImportList: [], importDataShow: false, usersExcelList: [],
        }
        this.UserDetails = this.props.UserDetails;
        this.onDeskExtChange = this.onDeskExtChange.bind(this)
        this.myRefInput = React.createRef();
        this.updatedeskPhone = React.createRef();
        this.ntloginString = React.createRef();
        this.updateOfficePhone = React.createRef();
    }

    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });

    onImportChange = (e) => {
        this.setState({ NtLoginListArray: [], usersExcelList: [] })
        const [file] = e.target.files;
        const ExcelFileExt = e.target.files;
        if (ExcelFileExt.length == 0) { this.ShowMessageBox('Please select a valid file', 3); e.stopPropagation(); return false; }
        var fileExtention = ExcelFileExt[0].name.split('.')[1];
        if (fileExtention != 'xls' && fileExtention != 'xlsx') { this.ShowMessageBox('Please select a valid file', 3); e.stopPropagation(); return false; }
        const reader = new FileReader();

        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const excelList = XLSX.utils.sheet_to_json(ws);

            var resultString = excelList.map(function (a) { return a.NTLogin; });
            let ntloginString = resultString.toString();
            if (ntloginString == '' || ntloginString == null) { this.ShowMessageBox('Please Enter Employee NtLogin', 3); return false; }
            this.ntloginString.current.value = ntloginString;
            this.setState({
                onImportList: excelList,
            });
            document.getElementById('EmployeeLabel').classList.add('active');
            this.GetExcelEmployeeDetailsForAproval();
        };
        reader.readAsBinaryString(file);
    };

    GetExcelEmployeeDetailsForAproval = async () => {

        var usersExcelList = [];
        let onImportList = this.state.onImportList
        if (onImportList.length == 0) return;
        for (let i = 0; i < onImportList.length; i++) {

            var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + onImportList[i].NTLogin.toLowerCase(), 'GET'); // + ntLogin.split('@')[0].toLowerCase()
            const dataGoogle = await responseGoogle.json();
            if (dataGoogle.success == true) {
                var NtLoginUser = JSON.parse(dataGoogle.data);
                if (NtLoginUser.primaryEmail.split('@')[0] == onImportList[i].NTLogin) {
                    NtLoginUser.customSchemas.Primary_Phone.work_phone_ext = onImportList[i].DeskPhoneExtension;
                    NtLoginUser.phones.find(({ type }) => type == 'work')['value'] = onImportList[i].OfficePhone;
                }
                usersExcelList.push(NtLoginUser)
            }
            //else if (dataGoogle.success == false) {
            //    setTimeout(async () => {
            //        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' +  onImportList[i].NTLogin.toLowerCase(), 'GET');
            //        const dataGoogle = await responseGoogle.json();



            //        var NtLoginUser = JSON.parse(dataGoogle.data);
            //         if (NtLoginUser.primaryEmail.split('@')[0] == onImportList[i].NTLogin) {
            //    NtLoginUser.customSchemas.Primary_Phone.work_phone_ext = onImportList[i].DeskPhoneExtension;
            //    NtLoginUser.phones.find(({ type }) => type == 'work')['value'] = onImportList[i].OfficePhone;
            //}
            //        usersExcelList.push(NtLoginUser)
            //        // await this.setState({ NtLoginUserDetails: NtLoginUser });

            //    }, 3000);
            //}
        }

        if (usersExcelList == null || usersExcelList.length == 0) {
            this.ShowMessageBox('NtLogin does not exist', 3);
        }
        else {
            await this.setState({ usersExcelList: usersExcelList, importDataShow: true, showTableWithData: true, });

        }
    }
    SubmitNtLogin = async (e) => {
        await this.setState({ NtLoginListArray: [], usersExcelList: [] })

        let EmpNtLogin = document.getElementById('EmpNtLogin').value;

        if (EmpNtLogin == '' || EmpNtLogin == null) { this.ShowMessageBox('Please Enter Employee Nt Login, you can enter mutiple Nt Login seprated with comma', 3); e.stopPropagation(); return false; }
        let removeSpaceNtLogin = EmpNtLogin.split(' ').join('')
        let NtLoginListArray = removeSpaceNtLogin.split(",")
        await this.setState({ NtLoginListArray: NtLoginListArray })
        await this.GetEmployeeDetailsForAproval();
    }
    HideTableWithData = () => this.setState({ showTableWithData: false });

    GetEmployeeDetailsForAproval = async (ntLogin) => {
        var usersList = [];

        if (this.state.NtLoginListArray.length == 0) return;
        for (var i = 0; i < this.state.NtLoginListArray.length; i++) {


            var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + this.state.NtLoginListArray[i].toLowerCase(), 'GET'); // + ntLogin.split('@')[0].toLowerCase()
            const dataGoogle = await responseGoogle.json();
            if (dataGoogle.success == true) {
                var NtLoginUser = JSON.parse(dataGoogle.data);
                usersList.push(NtLoginUser)
            }
            //else if (dataGoogle.success == false) {
            //    setTimeout(async () => {
            //        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + this.state.NtLoginListArray[i].toLowerCase(), 'GET');
            //        const dataGoogle = await responseGoogle.json();



            //        var NtLoginUser = JSON.parse(dataGoogle.data);
            //        usersList.push(NtLoginUser)

            //    }, 3000);
            //}
        }
        if (usersList == null || usersList.length == 0 || usersList[0].phones == null || usersList[0].phones == undefined) {
            this.ShowMessageBox('NtLogin does not exist', 3);
        }
        else {
            await this.setState({ NtLoginUserDetails: usersList, showTableWithData: true });

        }
    }
    onDeskExtChange(value) {
        this.setState({
            updatedeskPhone: value
        });
    }

    onOfficePhoneChange(value) {
        this.setState({
            updateOfficePhone: value
        });
    }


    updateAllNtLogin = async () => {
        if (this.state.importDataShow == true) {
            if (this.state.usersExcelList.length == 0) return;
            for (var i = 0; i < this.state.usersExcelList.length; i++) {
                this.SubmitAllRecord(this.state.usersExcelList[i])
            }
        }
        else {
            if (this.state.NtLoginUserDetails.length == 0) return;
            for (var i = 0; i < this.state.NtLoginUserDetails.length; i++) {
                this.SubmitAllRecord(this.state.NtLoginUserDetails[i])
            }
        }

    }

    SubmitAllRecord = async (userDetail) => {

        var pattern = new RegExp(/^[0-9\b]+$/);
        // let EmpNtLogin =  document.getElementById('ntlogin' + userDetail.externalIds[0].value);
        let valOfficeNum = document.getElementById('login' + userDetail.externalIds[0].value);
        let EmpOfficeNumber = valOfficeNum.value.replace("+1(", "").replace("1(", "").replace(/[^A-Z0-9]/ig, "").trim();

        // if ((EmpNtLogin.value != '' && EmpNtLogin.value.length >= 0) && Number.parseInt(EmpNtLogin.value) < 0) {

        //    this.ShowMessageBox('Plesae enter correct Extension number', 3);
        //    EmpNtLogin.value = '';
        //    EmpNtLogin.focus();
        //    return false;
        //}
        //else if ((EmpNtLogin.value != '' && EmpNtLogin.value.length >= 0) && (EmpNtLogin.value.length > 5 || EmpNtLogin.value.length < 5)) {
        //    this.ShowMessageBox('Plesae enter correct Extension number', 3);
        //    EmpNtLogin.value = '';
        //    EmpNtLogin.focus();
        //    return false;
        //} 
        // else if (EmpOfficeNumber == '' && EmpOfficeNumber.length == 0) {

        //   this.ShowMessageBox('Please Enter Office Number', 3);
        //  valOfficeNum.focus();
        //return false;
        //} 
        if (EmpOfficeNumber !== '' && !pattern.test(EmpOfficeNumber)) {
            valOfficeNum.focus();
            this.ShowMessageBox("Office Phone Number should be number.", 3); return false;
        }
        else if (EmpOfficeNumber !== '' && EmpOfficeNumber.trim() !== null
            && (EmpOfficeNumber).length !== 10) {
            valOfficeNum.focus();
            this.ShowMessageBox("Office Phone Number should be 10 characters long.", 3); return false;
        }
        this.UpdateExtension(userDetail.primaryEmail.split('@')[0], EmpOfficeNumber, userDetail);
    }
    SubmitSigleRecord = async (e) => {
        let pattern = new RegExp(/^[0-9\b]+$/);
        let EmpNumber = document.getElementById('EmpNumber');
        //let EmpExtNumber = document.getElementById('EmpExtNumber');
        let valOfficeNum = document.getElementById('EmpOfficeNumber');
        let EmpOfficeNumber = valOfficeNum.value.replace("+1(", "").replace(/[^A-Z0-9]/ig, "").trim();

        if (EmpNumber.value == '' && EmpNumber.value.length == 0) {

            this.ShowMessageBox('Please Enter Employee Nt Login', 3);
            EmpNumber.focus();
            e.stopPropagation();
            return false;
        }
        else if (EmpOfficeNumber == '' && EmpOfficeNumber.length == 0) {

            this.ShowMessageBox('Please Enter Office Number', 3);
            valOfficeNum.focus();
            e.stopPropagation();
            return false;
        } else if (EmpOfficeNumber !== '' && !pattern.test(EmpOfficeNumber)) {
            valOfficeNum.focus();
            this.ShowMessageBox("Office Phone Number should be number.", 3);
            e.stopPropagation();
            return false;
        }
        else if (EmpOfficeNumber !== '' && EmpOfficeNumber.trim() !== null
            && (EmpOfficeNumber).length !== 10) {
            valOfficeNum.focus();
            this.ShowMessageBox("Office Phone Number should be 10 characters long.", 3);
            e.stopPropagation();
            return false;
        }


        //else if ((EmpExtNumber.value != '' && EmpExtNumber.value.length >= 0) && Number.parseInt(EmpExtNumber.value) < 0) {

        //    this.ShowMessageBox('Plesae enter correct Extension number', 3);
        //    EmpExtNumber.value = '';
        //    EmpExtNumber.focus();
        //    return false;
        //}
        //else if ((EmpExtNumber.value != '' && EmpExtNumber.value.length >= 0) && (EmpExtNumber.value.length > 5 || EmpExtNumber.value.length < 5)) {
        //    this.ShowMessageBox('Plesae enter correct Extension number', 3);
        //    EmpExtNumber.value = '';
        //    EmpExtNumber.focus();
        //    return false;
        //} 

        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + EmpNumber.value.toLowerCase(), 'GET');
        const dataGoogle = await responseGoogle.json();

        // console.log('phone update ');
        // console.log(JSON.parse(dataGoogle.data));
        this.UpdateExtension(EmpNumber.value, EmpOfficeNumber, JSON.parse(dataGoogle.data))
    }



    UpdateExtension = async (ntName, officeNumber, googleData) => {
        
        let mobileNumber = googleData.hasOwnProperty('phones') ? googleData.phones.find(({ type }) => type === 'mobile') : ''
        let OfficePhoneNumber = (officeNumber == '') ? "" : "+1(" + officeNumber.substring(0, 3) + ")" + officeNumber.substring(3, 6) + "-" + officeNumber.substring(6, 10)


        var objData = {
            customSchemas: {
                Primary_Phone: {
                   // work_phone_ext: extension
                }
            },
            phones: [
                {
                    "value": OfficePhoneNumber,
                    "type": "work"
                },
                {
                    "value": (mobileNumber == undefined || mobileNumber == '') ? '' : mobileNumber.value,
                    "type": "mobile"
                }

            ]
        }
        let param = {
            "NTName": ntName,
            "Data": JSON.stringify(objData)
        }
        console.log('phone param');
        console.log(param);
        var updateResponse = await HttpRequest('SymptomTracker/UpdateUserDetailFromGoogleApi', 'POST', param);
        let res = await updateResponse.json();
        console.log(res);
        if (res.success) {
            this.ShowMessageBox("Data successfully updated on LumApps", 1)
            this.setState({ showTableWithData: true });
            document.getElementById('EmpNumber').value = '';
            // document.getElementById('EmpExtNumber').value = '';
            document.getElementById('EmpOfficeNumber').value = '';
        }
        else {
            this.ShowMessageBox("Data not successfully updated on LumApps", 3)
        }
    }

    renderNtLoginUserTable = () => {

        if (this.state.importDataShow == true) {

            if (this.state.usersExcelList.length == 0 || this.state.usersExcelList == []) return;

            return this.state.usersExcelList.map((item, index) => {
                return (
                    <>
                        <tr key={'ntlogin' + item.externalIds[0].value}>

                            <td>{item.name.fullName}</td>
                            <td>{item.primaryEmail.split('@')[0]}</td>

                            <td>

                                <input type="text" key={'login' + item.externalIds[0].value} defaultValue={item.phones.find(({ type }) => type == 'work') != undefined ? item.phones.find(({ type }) => type == 'work').value : ''}
                                    id={'login' + item.externalIds[0].value} className="form-control"
                                    name="OfficePhone" ref={this.updateOfficePhone} />
                            </td>
                            <td><a onClick={() => this.SubmitAllRecord(item)}>Update</a></td>
                        </tr>
                    </>
                )

            })
        }
        else {
            if (this.state.NtLoginUserDetails.length == 0 || this.state.NtLoginUserDetails == []) return;
            return (
                this.state.NtLoginUserDetails.map((item, index) => (
                    <tr key={'ntlogin' + item.externalIds[0].value}>

                        <td>{item.name.fullName}</td>
                        <td>{item.primaryEmail.split('@')[0]}</td>

                        <td>

                            <input type="text" key={'login' + item.externalIds[0].value}
                                defaultValue={(item.phones != null && item.phones.find(({ type }) => type === 'work') != undefined) ? item.phones.find(({ type }) => type === 'work').value : ''}
                                id={'login' + item.externalIds[0].value} className="form-control"
                                name="OfficePhone" ref={this.updatedeskPhone} />
                        </td>
                        <td><a onClick={() => this.SubmitAllRecord(item)}>Update</a></td>
                    </tr>
                )

                ))
        }



    }
    render() {
        console.log('new code phone 3')
        return (
            <>
                <div className="container employeeExtn">
                    <div className="row">
                        <div className="col l12 m12 s12">

                            <div className="card horizontal employeeProfileReview" >
                                <div className="card-image">
                                    <div className="imgBox">
                                        <svg viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M12,3C7.46,3 3.34,4.78 0.29,7.67C0.11,7.85 0,8.1 0,8.38C0,8.66 0.11,8.91 0.29,9.09L2.77,11.57C2.95,11.75 3.2,11.86 3.5,11.86C3.75,11.86 4,11.75 4.18,11.58C4.97,10.84 5.87,10.22 6.84,9.73C7.17,9.57 7.4,9.23 7.4,8.83V5.73C8.85,5.25 10.39,5 12,5C13.59,5 15.14,5.25 16.59,5.72V8.82C16.59,9.21 16.82,9.56 17.15,9.72C18.13,10.21 19,10.84 19.82,11.57C20,11.75 20.25,11.85 20.5,11.85C20.8,11.85 21.05,11.74 21.23,11.56L23.71,9.08C23.89,8.9 24,8.65 24,8.37C24,8.09 23.88,7.85 23.7,7.67C20.65,4.78 16.53,3 12,3M9,7V10C9,10 3,15 3,18V22H21V18C21,15 15,10 15,10V7H13V9H11V7H9M12,12A4,4 0 0,1 16,16A4,4 0 0,1 12,20A4,4 0 0,1 8,16A4,4 0 0,1 12,12M12,13.5A2.5,2.5 0 0,0 9.5,16A2.5,2.5 0 0,0 12,18.5A2.5,2.5 0 0,0 14.5,16A2.5,2.5 0 0,0 12,13.5Z" />
                                        </svg>
                                    </div>

                                </div>
                                <div className="card-stacked">
                                    <div className="card-content">


                                        <div className="row">
                                            <div className="col s12 l12">
                                                <h5>Add New Employee Phone Number</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col l6 s12 m6">
                                                <div className="input-field">
                                                    <input type="text" className="validate" id="EmpNumber" />
                                                    <label htmlFor="EmpNumber">Employee Nt Login</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col l6 s12 m6">
                                                <div className="input-field">
                                                    <input type="text" className="validate" id="EmpOfficeNumber" maxLength="10" />
                                                    <label htmlFor="EmpOfficeNumber">Office Phone Number</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col l6 s12 m6">
                                                <button className="btn btn-primary waves-effect waves-light" onClick={this.SubmitSigleRecord} name="action">Submit </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="card horizontal employeeProfileReview" >
                                <div className="card-stacked">
                                    <div className="card-content">
                                        <div>
                                            {/*<input type="file" onChange={this.onImportChange} />*/}
                                        </div>

                                        <div className="row">
                                            <div className="col s12 l12">
                                                <h5>Edit Employee Phone Numbers
                                                    <div className="btn btn-primary waves-effect waves-light uploadImportPhone" style={{ float: 'right' }}>
                                                        <span>Bulk Import </span>
                                                        <input type="file" title="Bulk Import" onChange={this.onImportChange} />
                                                    </div></h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col l6 s12 m6">
                                                <div className="input-field">
                                                    <input type="text" className="validate" id="EmpNtLogin" ref={this.ntloginString} />
                                                    <label htmlFor="EmpNtLogin" id="EmployeeLabel">Employee Nt Login's</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col l6 s12 m6">
                                                <button className="btn btn-primary waves-effect waves-light" onClick={this.SubmitNtLogin} name="action">Submit </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="card horizontal employeeProfileReview" style={{ display: this.state.showTableWithData == false ? 'none' : 'block' }} >
                                <div className="card-stacked">
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col l6 s12 m6">
                                                <button className="btn btn-primary waves-effect waves-light" onClick={this.updateAllNtLogin} name="action">Update All </button>
                                            </div>
                                        </div>
                                        <table className="table table-bordered" >
                                            <thead className="genericPageBodyText">
                                                <tr>
                                                    <th>Employee Name</th>
                                                    <th>Nt Login</th>
                                                    <th>Office Phone Number</th>
                                                    <th></th>

                                                </tr>
                                            </thead>
                                            <tbody className="genericPageBodyText">
                                                {this.renderNtLoginUserTable()}

                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}

