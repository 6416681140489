import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { HttpRequest, ShowLoader } from '../common/CommonOperations';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { Select } from 'react-materialize';
import { MessageBox } from '../../components/common/modalBox/MessageBox';

export class EmployeeProfile extends PureComponent {
    static displayName = EmployeeProfile.name;
    constructor(props) {

        super(props);
        this.state = {
            otherSelect: false,
            showMessageBox: false, message: '', messageType: '1', EmployeeProfileList: null, EmployeeProfileQuestionList: [], newQuestionList: [],
            checkedValueArray: [], managerName: '', mobilePhoneNumber: '', mobileCodeNumber: '+1', apimobilePhoneNumber: '', apimobileCodeNumber: '+1', showModalProfile: false, CropImageBlob: null,

            src: null, DisplayMessage: false,
            messageDisplayResponse: '',
            selectedOption1: '',
            selectedOption2: '',
            selectedOption3: '',
            selectedOption4: '',
            selectedOption5: '',

            apiselectedOption1: '',
            apiselectedOption2: '',
            apiselectedOption3: '',
            apiselectedOption4: '',
            apiselectedOption5: '',

            renderSelectedOption5: '',
            renderSelectedOption4: '',
            renderSelectedOption3: '',
            renderSelectedOption2: '',
            renderSelectedOption1: '',
            crop: {
                unit: 'px', width: 175, height: 225,
                aspect: 1 / 1
            },
            croppedImageUrl: null,
            // employeeProfileDetails: null,
            ApprovalUserDetails: null,
        };
        this.UserDetails = this.props.UserDetails;
    }

    componentDidMount() {

        this.GetEmployeeProfile();
        document.title = 'Update Your HOP Profile';
        console.log('new code after tag check')
    }


    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });


    GetEmployeeProfile = async () => {
        debugger;
        if (this.UserDetails.NTLogin == null) return;
        var response = await HttpRequest('BusinessCardEmployeeProfile/GetEmployeeProfileDetails/' + this.UserDetails.NTLogin, 'get');
        const res = await response.json();


        console.log(res)
        this.setState({ EmployeeProfileList: res.EmployeeProfileDetails, EmployeeProfileQuestionList: res.EmployeeProfileQuestions });

        if (this.UserDetails.GoogleData != null) {
            let value = this.UserDetails.GoogleData.relations[0].value.split('@')[0];
            let manager = value.split('.')[1] + ', ' + value.split('.')[0];

            console.log(this.UserDetails.GoogleData.customSchemas);

            let phonePattern = "";
            try {
                //phonePattern = this.UserDetails.GoogleData.customSchemas.Primary_Phone.hasOwnProperty('mobile_phone') ? this.UserDetails.GoogleData.customSchemas.Primary_Phone.mobile_phone.replace('-', '').replace(')', '').replace('-', '').split('(') : '';
                  phonePattern = this.UserDetails.GoogleData.customSchemas.Primary_Phone.hasOwnProperty('mobile_phone') ? this.UserDetails.GoogleData.customSchemas.Primary_Phone.mobile_phone.replace(/[)-]/g, '').split('(') : '';
            }
            catch { }
            console.log(phonePattern);

            var code = phonePattern[0];
            var phone = phonePattern[1];

            this.setState({ apimobilePhoneNumber: phone, apimobileCodeNumber: code })
            this.setState({ managerName: manager, })
        }
        if (this.state.EmployeeProfileList !== null) {
            if (this.state.EmployeeProfileList.MobilePhoneNumber !== null) {

                let phonePattern = this.state.EmployeeProfileList.MobilePhoneNumber.replace(/[)-]/g, '').split('(')
                var mobileCode = phonePattern[0]
                var mobilePhone = phonePattern[1]
                this.setState({ mobilePhoneNumber: mobilePhone, mobileCodeNumber: mobileCode })
            }
            this.setState({
                renderSelectedOption1: this.state.EmployeeProfileList.Question1 != null ? this.state.EmployeeProfileList.Question1 : '',
                renderSelectedOption2: this.state.EmployeeProfileList.Question2 != null ? this.state.EmployeeProfileList.Question2 : '',
                renderSelectedOption3: this.state.EmployeeProfileList.Question3 != null ? this.state.EmployeeProfileList.Question3 : '',
                renderSelectedOption4: this.state.EmployeeProfileList.Question4 != null ? this.state.EmployeeProfileList.Question4 : '',
                renderSelectedOption5: this.state.EmployeeProfileList.Question5 != null ? this.state.EmployeeProfileList.Question5 : '',
            })
            this.setState({
                apiselectedOption1: this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Question1 : '',
                apiselectedOption2: this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Question2 : '',
                apiselectedOption3: this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Question3 : '',
                apiselectedOption4: this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Question4 : '',
                apiselectedOption5: this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Question5 : '',
            })

        }

    }
    hideProfilePhoto = () => {
        this.setState({ showModalProfile: !this.state.showModalProfile })
    }


    selectChange = (e, param) => {
        this.state.newQuestionList.push(e.target.value);
        document.getElementById('Answer' + e.target.id.slice(-1)).value = '';
        document.getElementById('Answer' + e.target.id.slice(-1)).focus();
        if (param == 1) {
            this.setState({ selectedOption1: e.target.value, renderSelectedOption1: '', apiselectedOption1: e.target.value });
        } else if (param == 2) {
            this.setState({ selectedOption2: e.target.value, renderSelectedOption2: '', apiselectedOption2: e.target.value });
        } else if (param == 3) {
            this.setState({ selectedOption3: e.target.value, renderSelectedOption3: '', apiselectedOption3: e.target.value });
        } else if (param == 4) {
            this.setState({ selectedOption4: e.target.value, renderSelectedOption4: '', apiselectedOption4: e.target.value });
        } else if (param == 5) {
            this.setState({ selectedOption5: e.target.value, renderSelectedOption5: '', apiselectedOption5: e.target.value });
        }

    }

    renderPreferredPronouns = () => {
        let arrPrefered = ['--SELECT--', 'She/her/hers', 'He/him/his', 'They/them/theirs', 'Ze/hir/hirs', 'He/They', 'They/He', 'She/They', 'They/She', 'He/She/They', 'She/He/They', 'They/He/She', 'They/She/He', 'He/They/She', 'She/They/He', 'No pronouns (name only)']
        return (
            arrPrefered.map(item => (
                <>
                    <option value={item} >{item}</option>
                </>
            )))
    }
    GetValue = (param) => {
        param = param == 'Favorite  professional sports team:' ? 'Favorite professional sports team:' : param;
        let value = this.state.EmployeeProfileQuestionList.filter(item => item.DisplayQuestion == param)
        return value.length > 0 ? value[0].QuestionID : 0;
    }
    GetText = (param) => {
        param = param == 'Favorite  professional sports team:' ? 'Favorite professional sports team:' : param;
        let text = this.state.EmployeeProfileQuestionList.filter(item => item.DisplayQuestion == param)
        return text[0].Question
    }

    renderQuestionSelected = (param) => {

        if (this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null) {
            if (param == 1) {
                return (
                    <>
                        {
                            this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') && this.UserDetails.GoogleData.customSchemas.Questions.Question1 !== undefined && this.UserDetails.GoogleData.customSchemas.Questions.Question1 !== null && this.UserDetails.GoogleData.customSchemas.Questions.Question1 !== '' && this.UserDetails.GoogleData.customSchemas.Questions.Question1 !== '@@'
                                ? <option value={this.GetValue(this.UserDetails.GoogleData.customSchemas.Questions.Question1)} selected>{this.GetText(this.UserDetails.GoogleData.customSchemas.Questions.Question1)}</option>
                                : <option selected value=''>--Select---</option>
                        }
                    </>
                )
            }
            if (param == 2) {
                return (
                    <>
                        {

                            this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') && this.UserDetails.GoogleData.customSchemas.Questions.Question2 !== undefined && this.UserDetails.GoogleData.customSchemas.Questions.Question2 !== null && this.UserDetails.GoogleData.customSchemas.Questions.Question2 !== '' && this.UserDetails.GoogleData.customSchemas.Questions.Question2 !== '@@'
                                ? <option value={this.GetValue(this.UserDetails.GoogleData.customSchemas.Questions.Question2)} selected>{this.GetText(this.UserDetails.GoogleData.customSchemas.Questions.Question2)}</option>
                                : <option selected value=''>--Select---</option>
                        }
                    </>
                )
            }
            if (param == 3) {
                return (
                    <>
                        {
                            this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') && this.UserDetails.GoogleData.customSchemas.Questions.Question3 !== undefined && this.UserDetails.GoogleData.customSchemas.Questions.Question3 !== null && this.UserDetails.GoogleData.customSchemas.Questions.Question3 !== '' && this.UserDetails.GoogleData.customSchemas.Questions.Question3 !== '@@'
                                ? <option value={this.GetValue(this.UserDetails.GoogleData.customSchemas.Questions.Question3)} selected>{this.GetText(this.UserDetails.GoogleData.customSchemas.Questions.Question3)}</option>
                                : <option selected value=''>--Select---</option>
                        }
                    </>
                )
            }
            if (param == 4) {
                return (
                    <>
                        {
                            this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') && this.UserDetails.GoogleData.customSchemas.Questions.Question4 !== undefined && this.UserDetails.GoogleData.customSchemas.Questions.Question4 !== null && this.UserDetails.GoogleData.customSchemas.Questions.Question4 !== '' && this.UserDetails.GoogleData.customSchemas.Questions.Question4 !== '@@'
                                ? <option value={this.GetValue(this.UserDetails.GoogleData.customSchemas.Questions.Question4)} selected>{this.GetText(this.UserDetails.GoogleData.customSchemas.Questions.Question4)}</option>
                                : <option selected value=''>--Select---</option>
                        }
                    </>
                )
            }
            if (param == 5) {

                return (
                    <>
                        {
                            this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') && this.UserDetails.GoogleData.customSchemas.Questions.Question5 !== undefined && this.UserDetails.GoogleData.customSchemas.Questions.Question5 !== null && this.UserDetails.GoogleData.customSchemas.Questions.Question5 !== '' && this.UserDetails.GoogleData.customSchemas.Questions.Question5 !== '@@'
                                ? <option value={this.GetValue(this.UserDetails.GoogleData.customSchemas.Questions.Question5)} selected>{this.GetText(this.UserDetails.GoogleData.customSchemas.Questions.Question5)}</option>
                                : <option selected value=''>--Select---</option>
                        }
                    </>
                )
            }
        }
        else {
            if (param == 1) {
                return (
                    <>
                        {this.state.EmployeeProfileList.Question1Text !== null ? <option value={this.state.EmployeeProfileList.Question1} selected>{this.state.EmployeeProfileList.Question1Text}</option> : <option selected value=''>--Select---</option>}
                    </>
                )
            }
            if (param == 2) {
                return (
                    <>
                        {this.state.EmployeeProfileList.Question2Text !== null ? <option value={this.state.EmployeeProfileList.Question2} selected>{this.state.EmployeeProfileList.Question2Text}</option> : <option selected value=''>--Select---</option>}
                    </>
                )
            }
            if (param == 3) {
                return (
                    <>
                        {this.state.EmployeeProfileList.Question3Text !== null ? <option value={this.state.EmployeeProfileList.Question3} selected>{this.state.EmployeeProfileList.Question3Text}</option> : <option selected value=''>--Select---</option>}
                    </>
                )
            }
            if (param == 4) {
                return (
                    <>
                        {this.state.EmployeeProfileList.Question4Text !== null ? <option value={this.state.EmployeeProfileList.Question4} selected>{this.state.EmployeeProfileList.Question4Text}</option> : <option selected value=''>--Select---</option>}
                    </>
                )
            }
            if (param == 5) {
                return (
                    <>
                        {this.state.EmployeeProfileList.Question5Text !== null ? <option value={this.state.EmployeeProfileList.Question5} selected>{this.state.EmployeeProfileList.Question5Text}</option> : <option selected value=''>--Select---</option>}
                    </>
                )
            }
        }
    }
    IsGoogleQuestion = (questionId) => {
        if (this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null) {

            return this.GetValue(this.state.apiselectedOption1) != questionId
                && this.GetValue(this.state.apiselectedOption2) != questionId
                && this.GetValue(this.state.apiselectedOption3) != questionId
                && this.GetValue(this.state.apiselectedOption4) != questionId
                && this.GetValue(this.state.apiselectedOption5) != questionId ? true : false;

        }
        else {
            return true;
        }
    }

    renderQuestionsList = (param) => {

        if (param == 1) {
            return (
                this.state.EmployeeProfileQuestionList.map((item, index) => (
                    <>
                        {this.state.EmployeeProfileList.Question1Text != item.Question
                            && this.state.selectedOption2 != item.QuestionID
                            && this.state.selectedOption3 != item.QuestionID
                            && this.state.selectedOption4 != item.QuestionID
                            && this.state.selectedOption5 != item.QuestionID
                            && this.IsGoogleQuestion(item.QuestionID)
                            && this.state.renderSelectedOption2 != item.QuestionID
                            && this.state.renderSelectedOption3 != item.QuestionID
                            && this.state.renderSelectedOption4 != item.QuestionID
                            && this.state.renderSelectedOption5 != item.QuestionID
                            ? <option id={item.QuestionID} value={item.QuestionID}>{item.Question}</option> : ''}

                    </>)
                ))
        }
        if (param == 2) {
            return (
                this.state.EmployeeProfileQuestionList.map((item, index) => (
                    <>
                        {this.state.EmployeeProfileList.Question2Text != item.Question
                            && this.state.selectedOption1 != item.QuestionID
                            && this.state.selectedOption3 != item.QuestionID
                            && this.state.selectedOption4 != item.QuestionID
                            && this.state.selectedOption5 != item.QuestionID
                            && this.IsGoogleQuestion(item.QuestionID)
                            && this.state.renderSelectedOption1 != item.QuestionID
                            && this.state.renderSelectedOption3 != item.QuestionID
                            && this.state.renderSelectedOption4 != item.QuestionID
                            && this.state.renderSelectedOption5 != item.QuestionID
                            ? <option id={item.QuestionID} value={item.QuestionID}>{item.Question}</option> : ''}

                    </>)
                ))
        }
        if (param == 3) {
            return (
                this.state.EmployeeProfileQuestionList.map((item, index) => (
                    <>
                        {this.state.EmployeeProfileList.Question3Text != item.Question
                            && this.state.selectedOption1 != item.QuestionID
                            && this.state.selectedOption2 != item.QuestionID
                            && this.state.selectedOption4 != item.QuestionID
                            && this.state.selectedOption5 != item.QuestionID
                            && this.IsGoogleQuestion(item.QuestionID)
                            && this.state.renderSelectedOption1 != item.QuestionID
                            && this.state.renderSelectedOption2 != item.QuestionID
                            && this.state.renderSelectedOption4 != item.QuestionID
                            && this.state.renderSelectedOption5 != item.QuestionID ? <option id={item.QuestionID} value={item.QuestionID}>{item.Question}</option> : ''}
                    </>)
                ))
        }
        if (param == 4) {
            return (
                this.state.EmployeeProfileQuestionList.map((item, index) => (
                    <>
                        {this.state.EmployeeProfileList.Question4Text != item.Question
                            && this.state.selectedOption1 != item.QuestionID
                            && this.state.selectedOption2 != item.QuestionID
                            && this.state.selectedOption3 != item.QuestionID
                            && this.state.selectedOption5 != item.QuestionID
                            && this.IsGoogleQuestion(item.QuestionID)
                            && this.state.renderSelectedOption1 != item.QuestionID
                            && this.state.renderSelectedOption2 != item.QuestionID
                            && this.state.renderSelectedOption3 != item.QuestionID
                            && this.state.renderSelectedOption5 != item.QuestionID ? <option id={item.QuestionID} value={item.QuestionID}>{item.Question}</option> : ''}
                    </>)
                ))
        }
        if (param == 5) {
            return (
                this.state.EmployeeProfileQuestionList.map((item, index) => (
                    <>
                        {this.state.EmployeeProfileList.Question5Text != item.Question
                            && this.state.selectedOption1 != item.QuestionID
                            && this.state.selectedOption2 != item.QuestionID
                            && this.state.selectedOption3 != item.QuestionID
                            && this.state.selectedOption4 != item.QuestionID
                            && this.IsGoogleQuestion(item.QuestionID)
                            && this.state.renderSelectedOption1 != item.QuestionID
                            && this.state.renderSelectedOption2 != item.QuestionID
                            && this.state.renderSelectedOption3 != item.QuestionID
                            && this.state.renderSelectedOption4 != item.QuestionID ? <option id={item.QuestionID} value={item.QuestionID}>{item.Question}</option> : ''}
                    </>)
                ))
        }

    };


    validateEmployeeProfile = () => {

        debugger;
        var pattern = new RegExp(/^[0-9\b]+$/);
        let qus1 = document.querySelector('#selectedQuestion1');
        let qus2 = document.querySelector('#selectedQuestion2');
        let qus3 = document.querySelector('#selectedQuestion3');
        let qus4 = document.querySelector('#selectedQuestion4');
        let qus5 = document.querySelector('#selectedQuestion5');

        if (document.getElementById('MobileNumber').value == "") {
            document.getElementById('MobileNumber').value = null;
        }
        if (document.getElementById('MobileNumber').value == "")
            document.getElementById('MobileNumber').value = null;

        if (qus1.selectedIndex > 0 && (document.getElementById('Answer1').value == null || document.getElementById('Answer1').value.trim(' ') == "")) {
            document.getElementById('Answer1').focus();
            document.getElementById('Answer1').value = '';
            this.ShowMessageBox("Please enter Answer 1.", 3); return false;
        }
        else if (qus2.selectedIndex > 0 && (document.getElementById('Answer2').value == null || document.getElementById('Answer2').value.trim(' ') == "")) {
            document.getElementById('Answer2').focus();
            document.getElementById('Answer2').value = '';
            this.ShowMessageBox("Please enter Answer 2.", 3); return false;
        }
        else if (qus3.selectedIndex > 0 && (document.getElementById('Answer3').value == null || document.getElementById('Answer3').value.trim(' ') == "")) {
            document.getElementById('Answer3').focus();
            document.getElementById('Answer3').value = '';
            this.ShowMessageBox("Please enter Answer 3.", 3); return false;
        }
        else if (qus4.selectedIndex > 0 && (document.getElementById('Answer4').value == null || document.getElementById('Answer4').value.trim(' ') == "")) {
            document.getElementById('Answer4').focus();
            document.getElementById('Answer4').value = '';
            this.ShowMessageBox("Please enter Answer 4.", 3); return false;
        }
        else if (qus5.selectedIndex > 0 && (document.getElementById('Answer5').value == null || document.getElementById('Answer5').value.trim(' ') == "")) {
            document.getElementById('Answer5').focus();
            document.getElementById('Answer5').value = '';
            this.ShowMessageBox("Please enter Answer 5.", 3); return false;
        }
        /*else if (document.getElementById('MobileNumber').value !== '' && document.getElementById('MobileNumber').value.trim() !== null
            && (document.getElementById('MobileNumber').value).length !== 10) {
            document.getElementById('MobileNumber').focus();
            this.ShowMessageBox("Cell Phone Number should be 10 characters long.", 3); return false;
        }
        else if (document.getElementById('MobileNumber').value !== '' && !pattern.test(document.getElementById('MobileNumber').value)) {
            document.getElementById('MobileNumber').focus();
            this.ShowMessageBox("Cell Phone Number should be number.", 3); return false;
        }*/
        else if (document.getElementById('MobileNumber').value.replaceAll('-', '').length > 0 && ( document.getElementById('MobileNumber').value.replaceAll('-', '').length < 10 || document.getElementById('MobileNumber').value.replaceAll('-', '').length > 10)) {
            debugger
            this.ShowMessageBox('Cell Phone Number not more the 10 digit', 3);
            document.getElementById('MobileNumber').value='';
            document.getElementById('MobileNumber').focus();
            return false;
        }
        return true;
    }

    IsHigherManager = (val) => {
        const item = 'AC2150,AC2155,AC4025,AD2055,AD2060,AD3250,AD3270,AD4055,BB1010,CS1011,CS1022,EN1095,ES1001,ES1005,ES1010,ES1019,ES1020,ES1021,ES1022,ES1030,ES1048,ES1050,ES1055,ES1060,ES1090,ES1100,ES1110,ES1115,ES1125,ES1130,ES1140,ES1146,ES1150,ES1160,EX2015,EX2018,EX2025,EX2030,EX2035,EX2038,EX2045,EX2046,EX2047,EX2058,EX2060,EX2064,EX2065,EX2066,EX2080,EX2085,EX2095,EX9990,IT1049,MK3061,MK3065,MK3070,OP1040,OP1050,OP1075,PR3010,PR3015,SA1030,SA1080,SA1090,SA3500,SV1065,WL4000';
        var stuffArray = item.split(",");
        return stuffArray.indexOf(val) == -1 ? false : true;
    }

    submitEmployeeProfile = async () => {

        debugger;
        let mobileNmberText = document.getElementById('MobileNumber').value;
        mobileNmberText = mobileNmberText.replaceAll('-', '');
        debugger
        var checkedValueOfErg = [
            ...document.querySelectorAll('.checkboxGroup [type="checkbox"]:checked')
        ].map(el => el.value);
        var formattedMobilePhoneNumber
        if (!this.validateEmployeeProfile()) return;

        if (mobileNmberText == '') {
            formattedMobilePhoneNumber = null;
        }
        else {
            formattedMobilePhoneNumber = document.getElementById('countryCode').value + "(" + mobileNmberText.substring(0, 3) + ")"
                + mobileNmberText.substring(3, 6) + "-" + mobileNmberText.substring(6, 10);
        }

        var empImageBlob = null;
        if (this.state.croppedImageUrl == null) {
            if (this.state.EmployeeProfileList.EmployeeImage != null)
                empImageBlob = this.state.EmployeeProfileList.EmployeeImage;
        }
        else {
            empImageBlob = this.state.croppedImageUrl.substring(this.state.croppedImageUrl.indexOf(',') + 1);
        }
        var param = {
            EmployeeNumber: this.state.EmployeeProfileList.EmployeeNumber,
            Question1: document.getElementById('selectedQuestion1').value,
            Question2: document.getElementById('selectedQuestion2').value,
            Question3: document.getElementById('selectedQuestion3').value,
            Question4: document.getElementById('selectedQuestion4').value,
            Question5: document.getElementById('selectedQuestion5').value,
            Answer1: document.getElementById('Answer1').value,
            Answer2: document.getElementById('Answer2').value,
            Answer3: document.getElementById('Answer3').value,
            Answer4: document.getElementById('Answer4').value,
            Answer5: document.getElementById('Answer5').value,
            NamePronunciation: document.getElementById('NamePronunciation').value,
            TeamName: document.getElementById('TeamName').value,
            Description: document.getElementById('Description').value,
            SupervisorEmpID: this.state.EmployeeProfileList.SupervisorEmpID,
            SupervisorName: this.state.EmployeeProfileList.SupervisorName,
            FirstName: this.state.EmployeeProfileList.FirstName,
            LastName: this.state.EmployeeProfileList.LastName,
            EmailAddress: this.state.EmployeeProfileList.EmailAddress,
            EmployeeImage: empImageBlob,
            MobilePhoneNumber: formattedMobilePhoneNumber,
            ERGMembership: checkedValueOfErg.toString() == '' ? null : checkedValueOfErg.toString(),
            PreferredPronouns: document.getElementById('PreferredPron').value == '--SELECT--' ? '' : document.getElementById('PreferredPron').value,
            SeatingLocation: document.getElementById('SeatingLocation').value,
            JobCode: this.state.EmployeeProfileList.JobCode,
        }

        var response = await HttpRequest('BusinessCardEmployeeProfile/PostEmployeeProfileInfo', 'post', param);
        const responseData = await response.json();

        if (responseData.IsSuccessful) {

            if (this.IsHigherManager(this.state.EmployeeProfileList.JobCode)) {
                this.UpdateUserDetailFromGoogleApi(param);
            }
            this.clearFormValue();
            this.setState({ DisplayMessage: true, messageDisplayResponse: responseData.FriendlyMessage });
        }

        else {
            this.clearFormValue();
        }

    }
    getDisplayQuestion = (questionId) => {
        let questionsList = this.state.EmployeeProfileQuestionList;
        const displayQuestion = questionsList.filter(item => item.QuestionID == questionId)
        return displayQuestion[0].DisplayQuestion;
    }

    UpdateUserDetailFromGoogleApi = async (paramData) => {
        //let param1 = {
        //    EmployeeNumber: this.state.EmployeeProfileList.EmployeeNumber,
        //    SupervisorEmpID: this.state.EmployeeProfileList.SupervisorEmpID
        //}
        //console.log(param1)

        //var response = await HttpRequest('BusinessCardEmployeeProfile/GetEmployeeProfileReviewDetails', 'POST', param1);
        //const data = await response.json();
        //  this.setState({ employeeProfileDetails: data.EmployeeProfileDetails });

        //   let item = data.EmployeeProfileDetails;
        //  console.log(paramData)


        var objData = {
            customSchemas: {
                Questions: {
                    Answer1: (paramData.Answer1 == null || paramData.Answer1 == '') ? '@@' : paramData.Answer1,
                    Question1: (paramData.Question1 == null || paramData.Question1 == '') ? '@@' : this.getDisplayQuestion(paramData.Question1),

                    Answer2: (paramData.Answer2 == null || paramData.Answer2 == '') ? '@@' : paramData.Answer2,
                    Question2: (paramData.Question2 == null || paramData.Question2 == '') ? '@@' : this.getDisplayQuestion(paramData.Question2),

                    Answer3: (paramData.Answer3 == null || paramData.Answer3 == '') ? '@@' : paramData.Answer3,
                    Question3: (paramData.Question3 == null || paramData.Question3 == '') ? '@@' : this.getDisplayQuestion(paramData.Question3),

                    Answer4: (paramData.Answer4 == null || paramData.Answer4 == '') ? '@@' : paramData.Answer4,
                    Question4: (paramData.Question4 == null || paramData.Question4 == '') ? '@@' : this.getDisplayQuestion(paramData.Question4),

                    Answer5: (paramData.Answer5 == null || paramData.Answer5 == '') ? '@@' : paramData.Answer5,
                    Question5: (paramData.Question5 == null || paramData.Question5 == '') ? '@@' : this.getDisplayQuestion(paramData.Question5),
                },
                Preferred_Pronouns: {
                    PREFERREDPRONOUNS: (paramData.PreferredPronouns == null || paramData.PreferredPronouns == '') ? '@@' : paramData.PreferredPronouns
                },
                NAME_PRONUNCIATION: {
                    NAME_PRONUNCIATION: (paramData.NamePronunciation == null || paramData.NamePronunciation == '') ? '@@' : paramData.NamePronunciation
                },
                TEAM_NAME: {
                    TEAM_NAME: (paramData.TeamName == null || paramData.TeamName == '') ? '@@' : paramData.TeamName
                },
                Brief_Job_Description: {
                    Brief_Job_Description: (paramData.Description == null || paramData.Description == '') ? '@@' : paramData.Description
                },
                SEATINGLOCATION: {
                    SEATINGLOCATION: (paramData.SeatingLocation == null || paramData.SeatingLocation == '') ? '@@' : paramData.SeatingLocation
                },
                Collective_Group_Membership: {
                    ERGMEMBERSHIP: [
                        {
                            "type": "work",
                            "value": (paramData.ERGMembership == null || paramData.ERGMembership == '') ? '@@' : paramData.ERGMembership.replaceAll(",", "|")
                        }
                    ]

                },
                Primary_Phone: {
                    mobile_phone: (paramData.MobilePhoneNumber == null || paramData.MobilePhoneNumber == '') ? '@@' : paramData.MobilePhoneNumber,
                    work_phone_ext: (this.UserDetails.GoogleData.customSchemas.Primary_Phone != null)?
                        ((this.UserDetails.GoogleData.customSchemas.Primary_Phone.work_phone_ext == null
                            || this.UserDetails.GoogleData.customSchemas.Primary_Phone.work_phone_ext == '')
                            ? '@@' : this.UserDetails.GoogleData.customSchemas.Primary_Phone.work_phone_ext) : ""
                }
            }
        }

        let param = {
            "NTName": (paramData.EmailAddress != null || paramData.EmailAddress == '') ? paramData.EmailAddress.split('@')[0].toString() : '',
            "Data": JSON.stringify(objData)
        }
        //  console.log(param)


        var updateResponse = await HttpRequest('SymptomTracker/UpdateUserDetailFromGoogleApi', 'POST', param);
        let res = await updateResponse.json();
        if (res.success) {
            this.UpdateUserImageFromGoogleApi(paramData)
        }


    }
    UpdateUserImageFromGoogleApi = async (updateParam) => {

        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + updateParam.EmailAddress.split('@')[0].toLowerCase(), 'GET');
        const dataGoogle = await responseGoogle.json();
        if (dataGoogle.success == true) {
            var approvalUserDetails = JSON.parse(dataGoogle.data);
        }
        let param = {
            "NTName": updateParam.EmailAddress.split('@')[0],
            "id": approvalUserDetails.id,
            "primaryEmail": updateParam.EmailAddress,
            "kind": approvalUserDetails.kind,
            "etag": approvalUserDetails.etag,
            "photoData": updateParam.EmployeeImage,
            "mimeType": 'JPEG',
            "width": '96',
            "height": '96'
        }
        var updateResponse = await HttpRequest('SymptomTracker/UpdateUserImageFromGoogleApi', 'POST', param);
        let res = await updateResponse.json();
    }




    clearFormValue = () => {
        document.getElementById('selectedQuestion1').value = '';
        document.getElementById('selectedQuestion2').value = '';
        document.getElementById('selectedQuestion3').value = '';
        document.getElementById('selectedQuestion4').value = '';
        document.getElementById('selectedQuestion5').value = '';
        document.getElementById('Answer1').value = '';
        document.getElementById('Answer2').value = '';
        document.getElementById('Answer3').value = '';
        document.getElementById('Answer4').value = '';
        document.getElementById('Answer5').value = '';
        document.getElementById('NamePronunciation').value = '';
        document.getElementById('TeamName').value = '';
        document.getElementById('Description').value = '';

        document.getElementById('MobileNumber').value = '';
        document.getElementById('countryCode').value = '';
    }

    onSelectFile = (e) => {

        if (!e.target.files[0].name.toLowerCase().match(/\.(jpg|jpeg|png|gif|bmp)$/)) {
            this.ShowMessageBox('Select valid image.', 3);
            e.stopPropagation();
            return false;
        }

        var maxSize = 5120;
        var fileSize = e.target.files[0].size / 1024;

        if (fileSize > maxSize) {
            this.ShowMessageBox('Select valid image with size leass than 5 MB.', 3);
            e.stopPropagation();
            return false;
        }

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result, croppedImageUrl: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);

            ShowLoader(true);
            this.setState({ showModalProfile: !this.state.showModalProfile })

            ShowLoader(false);
        }
    };


    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    fitImage = async () => {
        let image = this.imageRef;
        let fileName = 'newImg';


        if (image.height > image.width) {
            this.setState({ crop: { unit: '%', width: 100, height: 100, x: 0, y: 0, aspect: 1 / 1 } })
            let crop = { unit: '%', width: 100, height: 100, x: 0, y: 0, aspect: 1 / 1 }
            this.setState({ croppedImageUrl: image.src })
        } else {
            this.setState({ crop: { unit: 'px', width: 175, height: 225, x: (image.width - 175) / 2, y: (image.height - 225) / 2, aspect: 1 / 1 } })
            let crop = { unit: 'px', width: 175, height: 225, x: (image.width - 175) / 2, y: (image.height - 225) / 2, aspect: 1 / 1 }
            const canvas = document.createElement('canvas');
            const pixelRatio = window.devicePixelRatio;
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            const ctx = canvas.getContext('2d');

            canvas.width = crop.width * pixelRatio * scaleX;
            canvas.height = crop.height * pixelRatio * scaleY;

            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
            );

            return new Promise((resolve, reject) => {
                canvas.toBlob(
                    (blob) => {
                        if (!blob) {
                            console.error('Canvas is empty');
                            return;
                        }
                        blob.name = fileName;
                        window.URL.revokeObjectURL(this.fileUrl);
                        this.fileUrl = window.URL.createObjectURL(blob);
                        resolve(this.fileUrl);

                        var reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.addEventListener('loadend', () => {
                            this.setState({ croppedImageUrl: reader.result })
                        });

                    },
                    'image/jpeg',
                    1
                );

            });
        }



    }

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);

                    var reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.addEventListener('loadend', () => {
                        this.setState({ croppedImageUrl: reader.result })
                    });

                },
                'image/jpeg',
                1
            );

        });
    }

    CheckBoxRender = () => {
        if (this.state.EmployeeProfileList == null) return <></>
        if (this.state.EmployeeProfileList != null) {
            let ergMembership = this.state.EmployeeProfileList.ERGMembership != null ? this.state.EmployeeProfileList.ERGMembership.split(',') : ''
            return (
                <>
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Adelante con DISH")} defaultValue="Adelante con DISH" />
                        <span>ADELANTE con DISH</span>
                    </label>
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("African American Network")} defaultValue="African American Network" />
                        <span>African American Network</span>
                    </label>
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes('DISH Disability Advocates Network') || ergMembership.includes('DISH Disability Advocates Network')} defaultValue="Disability Advocates Network" />
                        <span>Disability Advocates Network</span>
                    </label>
                  
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Empowered Parents Network")} defaultValue="Empowered Parents Network" />
                        <span>Empowered Parents Network</span>
                    </label>
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Furthering Indigenous Resources & Education")} defaultValue="Furthering Indigenous Resources & Education" />
                        <span>Furthering Indigenous Resources & Education</span>
                    </label>
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Honoring Asian Americans & Pacific Islanders")} defaultValue="Honoring Asian Americans & Pacific Islanders" />
                        <span>Honoring Asian Americans & Pacific Islanders</span>
                    </label>
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Out at DISH")} defaultValue="Out at DISH" />
                        <span>Out at DISH</span>
                    </label>
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Veterans Resource Group")} defaultValue="Veterans Resource Group" />
                        <span>Veterans Resource Group</span>
                    </label>
                    <label>
                        <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Women's Impact Network") || ergMembership.includes("DISH Women's Network")} defaultValue="Women's Impact Network" />
                        <span>Women's Impact Network</span>
                    </label>
                </>
            )
        }
    }
    CheckBoxRenderAPi = () => {
        if (this.UserDetails.GoogleData == null) return <></>
        let ergMembership = this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Collective_Group_Membership') ? this.UserDetails.GoogleData.customSchemas.Collective_Group_Membership.ERGMEMBERSHIP.length > 0
            ? this.UserDetails.GoogleData.customSchemas.Collective_Group_Membership.ERGMEMBERSHIP[0].value.split('|') : '' : '';
        return (
            <>
                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Adelante con DISH")} defaultValue="Adelante con DISH" />
                    <span>ADELANTE con DISH</span>
                </label>
                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("African American Network")} defaultValue="African American Network" />
                    <span>African American Network</span>
                </label>
                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes('DISH Disability Advocates Network' || ergMembership.includes('Disability Advocates Network') )} defaultValue="Disability Advocates Network" />
                    <span>Disability Advocates Network</span>
                </label>
               
                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Empowered Parents Network")} defaultValue="Empowered Parents Network" />
                    <span>Empowered Parents Network</span>
                </label>
                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Furthering Indigenous Resources & Education")} defaultValue="Furthering Indigenous Resources & Education" />
                    <span>Furthering Indigenous Resources & Education</span>
                </label>


                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Honoring Asian Americans & Pacific Islanders")} defaultValue="Honoring Asian Americans & Pacific Islanders" />
                    <span>Honoring Asian Americans & Pacific Islanders</span>
                </label>
                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Out at DISH")} defaultValue="Out at DISH" />
                    <span>Out at DISH</span>
                </label>
                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Veterans Resource Group")} defaultValue="Veterans Resource Group" />
                    <span>Veterans Resource Group</span>
                </label>
                <label>
                    <input type="checkbox" className="filled-in" defaultChecked={ergMembership.includes("Women's Impact Network") || ergMembership.includes("DISH Women's Network")} defaultValue="Women's Impact Network" />
                    <span>Women's Impact Network</span>
                </label>
            </>
        )
    }
    mobileDropdownValue = () => {
        if (this.state.EmployeeProfileList == null)
            return <></>
        else {

            let arrPrefered = ['+1', '+91', '+52', '+380']
            return (
                arrPrefered.map(item => (
                    <>
                        <option value={item}>{item}</option>
                    </>
                )))

        }
    }
    render() {
        if (this.UserDetails.GoogleData == null || this.state.EmployeeProfileList == null)
            return (<></>)
        const { crop, croppedImageUrl, src } = this.state;

        return (
            <>
                <div className="container payrollPasswordReset">
                    <div className="row">

                        <div className="col s12 m12 l12">
                            <p></p>
                            <div className="row">
                                <div className="col s12 l12 m12" style={{ display: this.state.DisplayMessage == true ? 'none' : 'block' }}>
                                    <div className="card horizontal employeeProfile">
                                        <div className="card-image">
                                            <img src="images/hoplogo.png" className="hoplogo-image" />
                                        </div>
                                        <div className="card-stacked">
                                            <div className="card-content">
                                                <div className="row">
                                                    <div className="col s12 l12">
                                                        <h5>Update Your HOP Profile</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col s12 l5 m12"><span className="labelText">Employee ID:</span></div>
                                                    <div className="col s12 l6 m12">{this.UserDetails.GoogleData.externalIds[0].value}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col s12 l5 m12"><span className="labelText">Manager Name:</span></div>
                                                    {<div className="col s12 l6 m12">{this.state.managerName}</div>}
                                                </div>
                                                <div className="row">
                                                    <div className="col s12 l5 m12">
                                                        <span className="labelText">Preferred Pronouns:</span>
                                                        <span className="helper-text">This is optional and your answer does not require manager approval.</span>
                                                    </div>
                                                    <div className="col s12 l6 m12">
                                                        <Select
                                                            id="PreferredPron"
                                                            multiple={false}
                                                            options={{
                                                                classes: '',
                                                                dropdownOptions: {
                                                                    alignment: 'left',
                                                                    autoTrigger: true,
                                                                    closeOnClick: true,
                                                                    constrainWidth: true,
                                                                    coverTrigger: true,
                                                                    hover: false,
                                                                    inDuration: 150,
                                                                    onCloseEnd: null,
                                                                    onCloseStart: null,
                                                                    onOpenEnd: null,
                                                                    onOpenStart: null,
                                                                    outDuration: 250
                                                                }
                                                            }}
                                                            value={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null) ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Preferred_Pronouns') ? this.UserDetails.GoogleData.customSchemas.Preferred_Pronouns.PREFERREDPRONOUNS : (this.state.EmployeeProfileList.PreferredPronouns !== null ? this.state.EmployeeProfileList.PreferredPronouns : '--SELECT---') : (this.state.EmployeeProfileList.PreferredPronouns !== null ? this.state.EmployeeProfileList.PreferredPronouns : '--SELECT---')} >

                                                            {this.renderPreferredPronouns()}

                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col s12 l5 m12"><span className="labelText">Name pronunciation (optional):</span></div>
                                                    <div className="col s12 l6 m12">
                                                        <div className="input-field">
                                                            <input id="NamePronunciation" type="text"
                                                                defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null)
                                                                    ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('NAME_PRONUNCIATION') ? this.UserDetails.GoogleData.customSchemas.NAME_PRONUNCIATION.NAME_PRONUNCIATION == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.NAME_PRONUNCIATION.NAME_PRONUNCIATION : ''
                                                                    : this.state.EmployeeProfileList.NamePronunciation} className="validate" />
                                                        </div></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col s12 l5 m12"><span className="labelText">Team Name:</span></div>
                                                    <div className="col s12 l6 m12">
                                                        <div className="input-field">
                                                            <input id="TeamName" type="text" defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null)
                                                                ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('TEAM_NAME') ? this.UserDetails.GoogleData.customSchemas.TEAM_NAME.TEAM_NAME == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.TEAM_NAME.TEAM_NAME : '' : this.state.EmployeeProfileList.TeamName} className="validate" />

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col s12 l5 m12">
                                                        <span className="labelText">Brief Job Description (255 chars max)</span>
                                                        <span className="helper-text">Ex: Applications/Skills</span>
                                                    </div>
                                                    <div className="col s12 l6 m12">
                                                        <div className="input-field">
                                                            {/*<input id="Description" type="text" maxLength="255" defaultValue={this.state.EmployeeProfileList.Description} className="validate" />*/}

                                                            <textarea id="Description" name="jobDescription" className="materialize-textarea" maxLength="255" rows="3" cols="33">
                                                                {(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null)
                                                                    ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Brief_Job_Description') ? this.UserDetails.GoogleData.customSchemas.Brief_Job_Description.Brief_Job_Description == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.Brief_Job_Description.Brief_Job_Description : '' : this.state.EmployeeProfileList.Description}
                                                            </textarea>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col s12 l5 m12">
                                                        <span className="labelText">Collective Resource Group Membership:</span>
                                                        <span className="helper-text">This does not require manager approval.</span>
                                                    </div>
                                                    <div className="col s12 l6 m12">
                                                        <div className="checkboxGroup">
                                                            {(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null)
                                                                ? this.CheckBoxRenderAPi() : this.CheckBoxRender()}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col s12 l5 m12"><span className="labelText">Mobile Phone Number:</span></div>
                                                    <div className="col s12 l6 m12">
                                                        <div className="inlineFromElement">
                                                            <Select
                                                                id="countryCode"
                                                                multiple={false}
                                                                options={{
                                                                    classes: '', dropdownOptions: {
                                                                        alignment: 'left', autoTrigger: true,
                                                                        closeOnClick: true,
                                                                        constrainWidth: true,
                                                                        coverTrigger: true,
                                                                        hover: false,
                                                                        inDuration: 150,
                                                                        onCloseEnd: null,
                                                                        onCloseStart: null,
                                                                        onOpenEnd: null,
                                                                        onOpenStart: null,
                                                                        outDuration: 250
                                                                    }

                                                                }}
                                                                value={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null)
                                                                    ? this.state.apimobileCodeNumber : this.state.mobileCodeNumber} >

                                                                {this.mobileDropdownValue()}
                                                            </Select>
                                                            <div className="input-field">
                                                                <input id="MobileNumber"
                                                                    type="text"
                                                                    defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null)
                                                                    ? this.state.apimobilePhoneNumber : this.state.mobilePhoneNumber}
                                                                    onKeyPress={(event) => {

                                                                        let charCode = (event.which) ? event.which : event.keyCode;

                                                                        if (charCode != 46 && charCode != 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                                                                            event.preventDefault();
                                                                        }



                                                                    }} maxLength='12'
                                                                    className="validate" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col s12 l5 m12"><span className="labelText">Seating Location:</span></div>
                                                    <div className="col s12 l6 m12">
                                                        <div className="input-field">
                                                            <input id="SeatingLocation" type="text" className="validate" defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null)
                                                                ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('SEATINGLOCATION') ? this.UserDetails.GoogleData.customSchemas.SEATINGLOCATION.SEATINGLOCATION == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.SEATINGLOCATION.SEATINGLOCATION : this.state.EmployeeProfileList.SeatingLocation : this.state.EmployeeProfileList.SeatingLocation} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col s12 l12 m12">Answering the following questions is optional, but appreciated. Your answers will be reviewed by your manager for approval prior to posting.</div>

                                                </div>

                                                <div className="row">
                                                    <div className="col s12 l12 m12">
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Question 1:</span></div>
                                                            <div className="col s12 l6 m12 qusList">
                                                                <select id="selectedQuestion1" className="browser-default" onChange={(e) => { this.selectChange(e, 1) }}>
                                                                    {this.state.EmployeeProfileList.Question1Text !== null ? <option value=''>--Select--</option> : ''}

                                                                    {this.renderQuestionSelected(1)}
                                                                    {this.renderQuestionsList(1)}
                                                                </select></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Answer 1:</span></div>
                                                            <div className="col s12 l6 m12"> <div className="input-field">
                                                                <input id="Answer1" type="text" className="validate" defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null) ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Answer1 == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.Questions.Answer1 : this.state.EmployeeProfileList.Answer1 : this.state.EmployeeProfileList.Answer1} />
                                                            </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col s12 l12 m12">
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Question 2:</span></div>
                                                            <div className="col s12 l6 m12 qusList">
                                                                <select className="browser-default" id="selectedQuestion2" onChange={(e) => { this.selectChange(e, 2) }}>
                                                                    {this.state.EmployeeProfileList.Question2Text !== null ? <option value=''>--Select--</option> : ''}
                                                                    {this.renderQuestionSelected(2)}
                                                                    {this.renderQuestionsList(2)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Answer 2:</span></div>
                                                            <div className="col s12 l6 m12">
                                                                <div className="input-field">
                                                                    <input id="Answer2" type="text" className="validate" defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null) ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Answer2 == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.Questions.Answer2 : this.state.EmployeeProfileList.Answer2 : this.state.EmployeeProfileList.Answer2} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col s12 l12 m12">
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Question 3:</span></div>
                                                            <div className="col s12 l6 m12 qusList">
                                                                <select className="browser-default" id="selectedQuestion3" onChange={(e) => { this.selectChange(e, 3) }}>
                                                                    {this.state.EmployeeProfileList.Question3Text !== null ? <option value=''>--Select--</option> : ''}
                                                                    {this.renderQuestionSelected(3)}
                                                                    {this.renderQuestionsList(3)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Answer 3:</span></div>
                                                            <div className="col s12 l6 m12"> <div className="input-field">
                                                                <input id="Answer3" type="text" className="validate" defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null) ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Answer3 == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.Questions.Answer3 : this.state.EmployeeProfileList.Answer3 : this.state.EmployeeProfileList.Answer3} />
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="row">
                                                    <div className="col s12 l12 m12">
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Question 4:</span></div>
                                                            <div className="col s12 l6 m12 qusList">
                                                                <select className="browser-default" id="selectedQuestion4" onChange={(e) => { this.selectChange(e, 4) }}>
                                                                    {this.state.EmployeeProfileList.Question4Text !== null ? <option value=''>--Select--</option> : ''}
                                                                    {this.renderQuestionSelected(4)}
                                                                    {this.renderQuestionsList(4)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Answer 4</span></div>
                                                            <div className="col s12 l6 m12"> <div className="input-field">
                                                                <input id="Answer4" type="text" className="validate" defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null) ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Answer4 == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.Questions.Answer4 : this.state.EmployeeProfileList.Answer4 : this.state.EmployeeProfileList.Answer4} />
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col s12 l12 m12">
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Question 5:</span></div>
                                                            <div className="col s12 l6 m12 qusList">
                                                                <select className="browser-default" id="selectedQuestion5" onChange={(e) => { this.selectChange(e, 5) }}>
                                                                    {this.state.EmployeeProfileList.Question5Text !== null ? <option value=''>--Select--</option> : ''}
                                                                    {this.renderQuestionSelected(5)}
                                                                    {this.renderQuestionsList(5)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col l5 m12 s12"><span className="labelText qna">Answer 5:</span></div>
                                                            <div className="col s12 l6 m12"> <div className="input-field">
                                                                <input id="Answer5" type="text" className="validate" defaultValue={(this.state.EmployeeProfileList.ApprovalStatus == "APPROVED" || this.state.EmployeeProfileList.ApprovalStatus == null) ? this.UserDetails.GoogleData.customSchemas.hasOwnProperty('Questions') ? this.UserDetails.GoogleData.customSchemas.Questions.Answer5 == '@@' ? '' : this.UserDetails.GoogleData.customSchemas.Questions.Answer5 : this.state.EmployeeProfileList.Answer5 : this.state.EmployeeProfileList.Answer5} />
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                {/*<div className="row">
                                                <div className="col s12 l12 m12">Your photo is a vital part of The Hop community. Please submit a personal photo of your choosing that is workplace appropriate and that prominently features your face. Your photo will be reviewed by your manager prior to posting. Your badge photo will be used as your default picture if you do not submit a photo.</div>
                                            </div>*/}

                                                <div className="row">
                                                    <div className="col s12 l5 m12">
                                                        <span className="labelText">Update Profile Picture</span>
                                                        <span className="helper-text">
                                                            Your photo will look best if it meets the following specifications:<br />
                                                            Format: JPG or PNG<br />
                                                            Size: Between  500KB and 5 MB<br />
                                                            Recommended resolution: 720 px tall by 720 px wide.

                                                        </span>

                                                    </div>
                                                    <div className="col s12 l6 m12" style={{ display: 'block' }}>
                                                        <span className="helper-text">Select an image file:</span>
                                                        <div className="file-field input-field">
                                                            <div className="btn btn-primary waves-effect waves-light">
                                                                <span>Upload New</span>
                                                                <input type="file" accept="image/*" title="Upload New" onChange={this.onSelectFile} />
                                                            </div>

                                                            <div className="imageContainer"> {croppedImageUrl && (<img alt="Crop" className="imgSize" src={croppedImageUrl} />)}</div>
                                                            {/*<br/><p>Previous Image</p><img src={'data:image/JPEG;base64,' + this.state.EmployeeProfileList.EmployeeImage} alt='' width="170px" height="225px" />*/}
                                                        </div>
                                                        <br />
                                                        <span className="helper-text">
                                                            Your photo is a vital part of The Hop community. Please submit a personal photo<br />
                                                            of your choosing that is workplace appropriate and that prominently features<br />your face.
                                                            Your photo will be reviewed by your manager prior to posting. Your<br />
                                                            employee badge photo will be used as your default picture if you do not submit<br /> a photo.

                                                        </span>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col s12 l12 m12">
                                                        <button className="btn btn-primary waves-effect waves-light" type="submit" onClick={this.submitEmployeeProfile} name="action">Submit </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col s12 l12 m12" style={{ display: this.state.DisplayMessage == true ? 'block' : 'none' }}>
                                    <div className="card horizontal">
                                        <div className="card-stacked">
                                            <div className="card-content">
                                                <div className="messageBoxDisplay">
                                                    <img src="images/success-icon.svg" />
                                                    {/*<h6>Your request has been successfully submitted. Profile updates require your manager’s approval before the changes will be publicly visible.</h6>*/}
                                                    <h6>{this.state.messageDisplayResponse}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.showModalProfile} onHide={() => { this.setState({ croppedImageUrl: null, src: null }); this.hideProfilePhoto() }} className="modalMySaveCall slingModal">
                    <Modal.Header closeButton>
                        <Modal.Title>Profile Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col l8 m7 s12">
                                <div className="file-path-wrapper">
                                    {src && (
                                        <ReactCrop
                                            src={src}
                                            crop={crop}
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                    )}

                                </div>

                            </div>
                            <div className="col l4 m5 s12">
                                <div className="file-path-wrapper">
                                    {croppedImageUrl && (<img alt="Crop" className="imgSize" src={croppedImageUrl} />)}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 l12 m12">
                                <button className="btn btn-primary" onClick={this.fitImage}>Fit Image</button>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="spEqual">
                        <Button className="btn-secondary" onClick={() => {
                            this.setState({
                                croppedImageUrl: null, src: null, crop: {
                                    unit: 'px', width: 175, height: 225,
                                    aspect: 1 / 1
                                }
                            }); this.hideProfilePhoto()
                        }}>Cancel</Button>
                        <Button className="btn-primary" onClick={() => { this.hideProfilePhoto() }}>Ok</Button>
                    </Modal.Footer>
                </Modal>


                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}
