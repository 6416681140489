import React, { Component } from 'react';
import { HttpRequest, HttpRequestWithouLoader, GetStartAndEndDate, CheckSupportIDException, HTMLTableExport, ShowLoader, ActiveMenu } from '../common/CommonOperations';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import dateFormat from 'dateformat';


export class SymptomTracker extends Component {
    static displayName = SymptomTracker.name;
    constructor(props) {
        super(props);
        this.state = {
            allQuestionData: '', nextStep: false, prevStep: false, buttonValue: false, fieldValue: '', showModalWAH: false, wahModal: false, isAdmin: false,
            allDisplayNone: false, successModalDisplay: false, errorModalDisplay: false, message: '', date: null, action: 1, allHideContent: false, selection: [], employeeLoginInfo: '',
            showTextArea: false, textareaMessage: '', otherinfovalue: null, idSeven: '',
        };
        this.UserDetails = this.props.UserDetails;
    }
    componentDidMount() {
        this.GetIllnessReportingQuestions();
       // this.Savedata();
        document.title = 'Symptom Tracker';
    }

    stepFrist = (e) => {
       
        let wfhRadioValue = this.findSelection('wfhRadio');
        if (wfhRadioValue["EmployeeResponse"] == true) {
            this.setState({ nextStep: true });
            this.setState({ prevStep: false });
            
        } else{

            this.setState({
                prevStep: false, successModalDisplay: true,allHideContent:false,
                message: 'Thank You for your response.<br />No additional action is needed at this time.'
            });
        }
    }

    successModalMessage(message = this.state.message, date = this.state.date) {
       
        if (date != null) {
            //let currentDate = date;
            //let day = currentDate.getDate();
            //let month = currentDate.getMonth()+1;
            //let year = currentDate.getFullYear();
            //let hours = currentDate.getHours();
            //let mins = currentDate.getMinutes();
             //<span>{month < 10 ? '0' + month : month}/{day < 10 ? '0' + day : day}/{year} {hours > 12 ? hours % 12 : '0' + hours}:{mins < 10 ? '0' + mins : mins} {hours < 12 ? 'AM' : 'PM'}</span>
            return (
               <>
                    <span>Thank You</span>
                    <span dangerouslySetInnerHTML={{ __html: date }} ></span>
                    <div dangerouslySetInnerHTML={{ __html: message }} ></div>
                </>
                );
        } else {
            
            return (<div dangerouslySetInnerHTML = {{ __html: message }} ></div>);
        }
    }

    findSelection(field) {
    var test = document.getElementsByName(field);
        var sizes = test.length;
        var valueArr =[];
        for (let i = 0; i < sizes; i++) {
            if (test[i].checked == true) {
                let obj = { ID: parseInt(test[i].dataset.id), EmployeeResponse: test[i].value == 'True' ? true : false, EmployeeResponseText: null, AdditionalInfo: false };
                valueArr = obj;
                test[i].setAttribute('disabled','true');
            }
            test[i].setAttribute('disabled', 'true')
        }
        return valueArr;
    }

    Savedata = async (employeeResponse,otherinfoText) => {
        var res = [];
        let sendDateObj = employeeResponse;
        if (this.state.showTextArea) {
            let newState = sendDateObj.map(obj =>
                obj.ID === 7 ? { ...obj, AdditionalInfo: true, EmployeeResponseText: otherinfoText, } : obj
            );
            newState.forEach(item => res.push(item));
        } else {
            let newState = sendDateObj.map(obj => obj.ID === 7 ? obj : obj);
            newState.forEach(item => res.push(item));
        }       
        let param = { Data: res, EmployeeLoginInfo: this.state.employeeLoginInfo}
        var response = await HttpRequest('SymptomTracker/SaveIllnessReportingEmployeeResponse','Post', param);
        const data = await response.json();
    }

    nextStepBtn = (action) => {
       
        if (action == 1) {
            let radioGroup = document.querySelectorAll('#fromStep2 li input[type="radio"]');
            let sizes = radioGroup.length / 2;
            let arrValue = [];
            for (let i = 0; i < sizes; i++) {
                let radiovalArr = this.findSelection(i + 1);
                arrValue.push(radiovalArr);
            }
            let falseItem = arrValue.findIndex(function (element, index, array) {
                if (element.EmployeeResponse == true) {
                    return true;
                }
            });
            if (falseItem >= 0) {

                this.setState({
                    prevStep: false, errorModalDisplay: true, nextStep: false, allHideContent:false,
                    date: dateFormat(new Date(), "mm/dd/yyyy hh:MM TT"),
                    message: 'For your safety and the safety of others, please remain offsite and contact <a href="mailto:illnessreporting@dish.com">illnessreporting@dish.com</a> and your supervisor.'
                });
                this.Savedata(arrValue);
            } else {
                this.setState({
                    nextForm: true,
                    action: 2
                })
               
            }
        } else if (action == 2) {
            let additionInfo = document.querySelector('#additionInfo');
            let radioGroup = document.querySelectorAll('#fromStep2 li input[type="radio"]');
            let sizes = radioGroup.length / 2;
            let arrValue = [];
            for (let i = 0; i < sizes; i++) {
                let radiovalArr = this.findSelection(i + 1);
                arrValue.push(radiovalArr);
            }
            let falseItem = arrValue.findIndex(function (element, index, array) {
                if (element.EmployeeResponse === true) {
                    return true;
                }
            });
            
            if (falseItem >= 0) {
                if (this.state.showTextArea == true && additionInfo.value != '') {
                    this.setState({
                        prevStep: false, errorModalDisplay: true, nextStep: false, allHideContent: false,
                        //date: new Date(),
                        date: dateFormat(new Date(), "mm/dd/yyyy hh:MM TT"),
                        message: 'For your safety and the safety of others, please remain offsite and contact <a href="mailto:illnessreporting@dish.com">illnessreporting@dish.com</a> and your supervisor.'
                    });
                    this.Savedata(arrValue,  additionInfo.value);
                } else {
                    alert('Please enter other symptoms ');
                    additionInfo.focus();
                }
              
            } else {
                this.setState({
                    prevStep: false, successModalDisplay: true, nextStep: false, allHideContent: false,
                    date: dateFormat(new Date(), "mm/dd/yyyy hh:MM TT"),
                    message: 'You are clear to enter the building and proceed to your assigned work station.'
                });
                this.Savedata(arrValue);
            }
        }
        
    }

    hideModalFWAH = () => {
       // this.setState({ showModalWAH: !this.state.showModalWAH });
        this.setState((state) => ({ showModalWAH: !state.showModalWAH })); // asif
    }


    async GetIllnessReportingQuestions() {
        var response = await HttpRequest('SymptomTracker/GetIllnessReportingQuestions', 'Get');
        const data = await response.json();
        this.setState({ allQuestionData: data, employeeLoginInfo: data.EmployeeLoginInfo, isAdmin: data.IsIllnessReportingAdmin });
        if (this.state.employeeLoginInfo.EmployeeId != null) { this.getDisplayMessage(); }
    }

    async getDisplayMessage() {
        if (this.state.employeeLoginInfo.IsSurveyCompleted) {
            if (!this.state.employeeLoginInfo.HaveIllness) {
                this.setState((state) => ({
                    prevStep: false, successModalDisplay: true, nextStep: false, allHideContent: false,
                    date: dateFormat(this.state.employeeLoginInfo.TimeStamp, "mm/dd/yyyy hh:MM TT"),
                    message: 'You are clear to enter the building and proceed to your  assigned work station.'
                }));
            }
            else {
                this.setState((state) => ({
                    prevStep: false, errorModalDisplay: true, nextStep: false, allHideContent: false,
                    date: dateFormat(this.state.employeeLoginInfo.TimeStamp, "mm/dd/yyyy hh:MM TT"),
                    message: 'For your safety and the safety of others, please remain offsite and contact <a> illnessreporting@dish.com</a> and your supervisor.'
                }));
            }
        } else {
            this.setState({ prevStep:true,allHideContent:true})
        }

    }

    textAreaDisplay = (e) => {

    if(e.target.value == 'True') {
            this.setState({ showTextArea: true })
        } else {
            this.setState({ showTextArea: false })
        }
    }
    
    getNextQuestion = (questionSet) => {
        if (this.state.allQuestionData == null || this.state.allQuestionData.length == 0) {
            return;
        } else {
            let questionData = this.state.allQuestionData.Data;
            if (questionSet == '1') {
                return questionData.map((item, index) => {
                    if (index < 3) {
                        return (
                            <>
                               <li className="genericPageLinkText label-text"><span>{item.OrderNumber}</span>
                                    <img src="images/questionIcon.svg" /> {item.Text}
                                </li>
                                <li>
                                    <input id={'toggle-on-' + item.OrderNumber} data-id={item.ID}className="toggle toggle-left" type="radio" name={item.OrderNumber} value="False"  onChange={this.radioValue} />
                                    <label htmlFor={'toggle-on-' + item.OrderNumber} className="btn">  No</label>
                                    <input id={'toggle-off-' + item.OrderNumber} data-id={item.ID}className="toggle toggle-right" type="radio" name={item.OrderNumber} value="True" onChange={this.radioValue} />
                                    <label htmlFor={'toggle-off-' + item.OrderNumber} className="btn lborder">Yes</label>
                                </li>
                            </>)
                    }
                });
            }

            else if (questionSet == '2') {
                return questionData.map((item, index) => {
                    if (index > 2) {
                        if (index == 6) {
                            return (
                                <>
                                    <li className="genericPageLinkText label-text"><span>{item.OrderNumber}</span>
                                        <img src="images/questionIcon.svg" /> <div dangerouslySetInnerHTML={{ __html: item.Text }}></div>
                                    </li>
                                    <li>
                                        <input id={'toggle-on-' + item.OrderNumber} data-id={item.ID} className="toggle toggle-left" type="radio" name={item.OrderNumber} value="False"  onChange={this.textAreaDisplay} />
                                        <label htmlFor={'toggle-on-' + item.OrderNumber} className="btn">  No</label>
                                        <input id={'toggle-off-' + item.OrderNumber} data-id={item.ID} className="toggle toggle-right" type="radio" name={item.OrderNumber} value="True" onChange={this.textAreaDisplay} />
                                        <label htmlFor={'toggle-off-' + item.OrderNumber} className="btn lborder">Yes</label>
                                        {this.state.showTextArea == true ? <textarea className="form-control" id="additionInfo"></textarea>:''}
                                    </li>
                                </>)
                        }
                        return (
                            <>
                                <li className="genericPageLinkText label-text"><span>{item.OrderNumber}</span>
                                    <img src="images/questionIcon.svg" /> <div dangerouslySetInnerHTML={{ __html: item.Text }}></div>
                                </li>
                                <li>
                                    <input id={'toggle-on-' + item.OrderNumber} data-id={item.ID}  className="toggle toggle-left" type="radio" name={item.OrderNumber} value="False"  onChange={this.radioValue} />
                                    <label htmlFor={'toggle-on-' + item.OrderNumber} className="btn">  No</label>
                                    <input id={'toggle-off-' + item.OrderNumber} data-id={item.ID} className="toggle toggle-right" type="radio" name={item.OrderNumber} value="True" onChange={this.radioValue} />
                                    <label htmlFor={'toggle-off-' + item.OrderNumber} className="btn lborder">Yes</label>
                                </li>
                            </>)
                       
                    }

                });
            }
        }
    }
    render() {
        if (this.UserDetails.GoogleData == null)
            return <></>
    return (
        <>
            
            <div className="feedbackContainer">
                <div className="container">
                <div className="row" style={{ display: this.state.isAdmin == true ? 'block' : 'none' }}>
                    <div className="col s12 m12">

                        <div><a href="/illnessReportingAdmin/">Admin Link</a></div>
                        <div><a href="/illnessReportingContractorAdmin/">Summit Capital and Construction Admin Link</a></div>
                        
                    </div>
                </div>
                <div className="row" style={{display:this.state.allHideContent==true?'block':'none'}}>
                    <div className="col s12 m12">

                       
                        <div className="row">
                            <div className="col s12 m12">
                                <div className="formHeader">HOP Symptom Tracker</div>
                                <div className="page-body-text">
                                    WE HAVE UPDATED THE HEALTH SCREENING QUESTIONS. PLEASE READ  EACH ONE AND SELECT THE APPROPRIATE ANSWER.  <br /><br />Your health and safety are of the utmost importance as we return to working in our sites. In an effort to quickly and efficiently assess the health of those entering DISH buildings, we ask that you complete the following survey before you travel to, or enter a DISH site. As you enter, your temperature will be checked and you should show your completed symptom screening to the designated professional.<br /><br />DISH takes the privacy of its employees and others very seriously and will keep the results of its temperature and safety screenings confidential to the fullest extent possible, unless required by law to share with a governmental or other health authority or with your consent. You can review DISH's Health Screening Policy <a href="/DeptContent/TemperatureHealthScreeningPolicy.pdf" target="_blank">here</a>.
                    </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="row">
                    <div className="col s12 m12">
                        <div id="isEmployeeWFH" style={{ display: this.state.prevStep == true ? 'block' : 'none' }}>
                           
                            <ul className="formElementBox">
                                <li className="genericPageLinkText label-text"><span>1</span>
                                    <img src="images/questionIcon.svg" />Will you be working at a DISH facility today, or meeting with customers or vendors today?
                                </li>
                                <li className="firstInputContainer">
                                    <input id="toggle-on" data-id="0" className="toggle toggle-left" type="radio" name="wfhRadio" value="True" />
                                    <label htmlFor="toggle-on" className="btn">  Yes</label>
                                    <input id="toggle-off" data-id="0" className="toggle toggle-right" type="radio" name="wfhRadio"  value="False" />
                                    <label htmlFor="toggle-off" className="btn lborder">No</label>
                                </li>
                                <li><button type="button" className="btn btn-primary customBtnSym" onClick={this.stepFrist}>Submit</button></li>
                                </ul>
                           
                        </div>

                        <div id="isEmployeeWFH-No" style={{display:this.state.nextStep==true?'block':'none'}}>
                            <ul className="formElementBox" id="fromStep2">
                                {this.getNextQuestion('1')}
                                {this.state.nextForm == true ? this.getNextQuestion('2') : ''}
                                <li><button type="button" className="btn btn-primary customBtnSym" onClick={() => this.nextStepBtn(this.state.action)}>Submit</button></li>
                            </ul>

                        </div>



                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 modalShowContaianer">
                        <div id="errorDiv" className="customModalBox" style={{ display: this.state.errorModalDisplay == true ? 'block' : 'none' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="iconContainer">
                                            <img src="images/error-icon.svg" />
                                         </div>
                                       
                                        <ul id="divWarning" className="error-list">
                                            {this.successModalMessage()}
                                        </ul>
                                        <button type="button" className="btn btn-primary customBtnSym">Close</button>
                                    </div>
                                </div>
                             </div>
                        </div>

                        <div id="successDiv" className="customModalBox" style={{ display: this.state.successModalDisplay == true?'block':'none' }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    
                                    <div className="modal-body">
                                        <div className="iconContainer">
                                            <img src="images/success-icon.svg" />
                                        </div>
                                        <ul id="divSuccess" className="success-list">
                                            {this.successModalMessage()}
                                            </ul>
                                        <div className="input-group btnCenter ng-scope" ng-if="!showTempTextbox">
                                            <button type="button" className="btn btn-primary customBtnSym secBtn" >Close</button>
                                                
                                     </div>
                                    <div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12"></div>
                </div>
                    </div>
                </div>
            

           
            <Modal show={this.state.showModalWAH} onHide={this.hideModalFWAH} className="modalMySaveCall">
                <Modal.Header closeButton>
                    <Modal.Title>Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Body Text
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.hideModalFWAH}>Cancel</Button>
                   
                </Modal.Footer>
            </Modal>

        </>
    );
  }
}
