
import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Scriber } from './components/Scriber/Scriber';
import { SymptomTracker } from './components/SymptomTracker/SymptomTracker';
import { IllnessReportingAdmin } from './components/SymptomTracker/illnessReportingAdmin';
import { IllnessReportingContractorAdmin } from './components/SymptomTracker/illnessReportingContractorAdmin';
import { AcademyAwards } from './components/AcademyAwards/AcademyAwards';
import { ThankYouMessage } from './components/AcademyAwards/ThankYouMessage';
import  SelectPicks  from './components/AcademyAwards/SelectPicks';
import { Results } from './components/AcademyAwards/Results';
import Review from './components/AcademyAwards/Review'; 
import { Leaderboard } from './components/AcademyAwards/Leaderboard';
import { DISHscriber } from './components/Scriber/DISHscriber/DISHscriber';
import { SLINGscriber } from './components/Scriber/SLINGscriber/SLINGscriber';
import { BOOSTscriber } from './components/Scriber/BOOSTscriber/BOOSTscriber';
import { BOOSTscriberB } from './components/Scriber/BOOSTscriber/BOOSTscriberB';
import { BoostSignUp } from './components/Scriber/BOOSTscriber/BoostSignUp';
import { BoostSignUpImei } from './components/Scriber/BOOSTscriber/BoostSignUpImei';
import { BoostRedirect } from './components/Scriber/BOOSTscriber/BoostRedirect';



import { HttpRequest, CheckSupportIDException, HttpRequestWithouLoader } from '../src/components/common/CommonOperations';
import { SessionTimeout } from './components/SessionTimeout';
import { PayrollPasswordReset } from './components/otherPages/PayrollPasswordReset';
//import { FlowerPurchasingRequest } from './components/otherPages/FlowerPurchasingRequest';
//import { FlowerPurchasingRequestReview } from './components/otherPages/FlowerPurchasingRequestReview';

import { BusinessCardRequest } from './components/otherPages/BusinessCardRequest';
import { EmployeeProfile } from './components/otherPages/EmployeeProfile';
import { EmployeeProfileOther } from './components/otherPages/EmployeeProfileOther';
import { EmployeeProfileReview } from './components/otherPages/EmployeeProfileReview';
import { ImageCrop } from './components/otherPages/ImageCrop';
import { ProfilePage } from './components/otherPages/ProfilePage';
import { ProfilePageLumapps } from './components/otherPages/ProfilePageLumapps';
import { SelfClose } from './components/otherPages/SelfClose';
import { OrganizationChart } from './components/otherPages/OrganizationChart';
import { EmployeePhoneNumber } from './components/otherPages/EmployeePhoneNumber';
import { Weather } from './components/otherPages/Weather';
import { SearchResultView } from './components/otherPages/SearchResultView';
import { BOOSTscriberNew } from './components/Scriber/BOOSTscriber/BOOSTscriberNew';
import { BracketBreaks } from './components/BracketBreaks/BracketBreaks';
import { FirstAidClasses } from './components/FirstAid/FirstAidClasses';
import 'materialize-css/dist/css/materialize.min.css';
import './custom.css'

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);
        var userDetails = { PersonID: null, NTLogin: null, AcademyAwardsEndDate: null, GoogleData: null, IsAdmins:null}
        this.state = { UserDetails: userDetails, showModel: false, ntLoginDate:'' }
    }
   

    async  componentDidMount() {
        await this.GetNTLogin();
        //await this.IsAdmin();
        await this.GetLoggedUserDetails(this.state.ntLoginDate.key, this.state.ntLoginDate.personID, this.state.ntLoginDate.academyAwardsEndDate);
    }
    componentWillUnmount() {
        clearInterval(this.setTimeout)
    }
    
    async GetNTLogin() {
        var response = await HttpRequest('SymptomTracker/GetNTLogin','Get')
        var data = await response.json();
        this.setState({ ntLoginDate: data })
    }
    async IsAdmin() {
        ////debugger;
        //var response = await HttpRequestWithouLoader("SymptomTracker/IsAdmin", 'get')
        //var data = await response.json();
        //var userDetails = this.state.UserDetails;
        //userDetails.IsAdmins = data;
        //console.log('AdminuserDetails');
        //console.log(userDetails);
        //await this.setState({ UserDetails: userDetails });
    }

    async GetLoggedUserDetails(ntLogin, personID, academyAwardsEndDate) {

        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + ntLogin.toLowerCase(), 'GET');
        var dataGoogle = await responseGoogle.json();

        if (dataGoogle.success == true) {
            this.setState(prevState => ({
                UserDetails: {
                    ...prevState.UserDetails,
                    NTLogin: ntLogin,
                    PersonID: personID,
                    AcademyAwardsEndDate: academyAwardsEndDate,
                    GoogleData: JSON.parse(dataGoogle.data)
                }
            }))
            console.log('after')
            console.log(this.state.UserDetails)

        }
        else if (dataGoogle.success == false) {
            setTimeout(async () => {
                var responseGoogle1 = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + ntLogin.toLowerCase(), 'GET');
                const dataGoogle1 = await responseGoogle1.json();
                this.setState(prevState => ({
                    UserDetails: {
                        ...prevState.UserDetails,
                        NTLogin: ntLogin,
                        PersonID: personID,
                        AcademyAwardsEndDate: academyAwardsEndDate,
                        GoogleData: JSON.parse(dataGoogle1.data)
                    }
                }))
                console.log('after else')
                console.log(this.state.UserDetails)

            }, 3000);
        }
    }

  render () {
      return ( 
          <Layout>  
              <Route path='/ImageCrop' component={(props) => <ImageCrop {...props} />} />
            <Route exact path='/SessionTimeout' component={SessionTimeout} />
              <Route exact path='/' component={(props) => <Scriber {...props} UserDetails={this.state.UserDetails} />} />
              <Route  path='/Home' component={(props) => <Scriber {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/SymptomTracker'  component={(props) => <SymptomTracker {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/illnessReportingAdmin' component={(props) => <IllnessReportingAdmin {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/illnessReportingContractorAdmin'component={(props) => <IllnessReportingContractorAdmin {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/AcademyAwards/Intro'  component={(props) => <AcademyAwards {...props} UserDetails={this.state.UserDetails} />}/>
            <Route path='/AcademyAwards/ThankYouMessage'  component={(props) => <ThankYouMessage {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/AcademyAwards/SelectPicks' component={(props) => <SelectPicks {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/AcademyAwards/Results'  component={(props) => <Results {...props} UserDetails={this.state.UserDetails} />}/>
            <Route path='/AcademyAwards/Review' component={(props) => <Review {...props} UserDetails={this.state.UserDetails} />}/>
            <Route path='/AcademyAwards/Leaderboard'  component={(props) => <Leaderboard {...props} UserDetails={this.state.UserDetails} />}/>
            <Route path='/Scriber/DISHscriber' component={(props) => <DISHscriber {...props} UserDetails={this.state.UserDetails} />}/>
            <Route path='/Scriber/SLINGscriber'  component={(props) => <SLINGscriber {...props} UserDetails={this.state.UserDetails} />}/>
              <Route path='/Scriber/BOOSTscriber' component={(props) => <BOOSTscriber {...props} UserDetails={this.state.UserDetails} />} />
              
              

              <Route path='/Scriber/BOOSTscriberB' component={(props) => <BOOSTscriberB {...props} UserDetails={this.state.UserDetails} />} />
              <Route path='/Scriber/BoostSignUp' component={(props) => <BoostSignUp {...props} UserDetails={this.state.UserDetails} />} />
              <Route path='/Scriber/BoostSignUpImei' component={(props) => <BoostSignUpImei {...props} UserDetails={this.state.UserDetails} />} />
              <Route path='/Scriber/BoostRedirect' component={(props) => <BoostRedirect {...props} UserDetails={this.state.UserDetails} />} />
              
              
              
            <Route path='/PayrollPasswordReset' component={(props) => <PayrollPasswordReset {...props} UserDetails={this.state.UserDetails} />} />
            {/*<Route path='/FlowerPurchasingRequest' component={(props) => <FlowerPurchasingRequest {...props} UserDetails={this.state.UserDetails} />} />*/}
            {/*<Route path='/FlowerPurchasingRequestReview' component={(props) => <FlowerPurchasingRequestReview {...props} UserDetails={this.state.UserDetails} />} />*/}
            <Route path='/BusinessCardRequest' component={(props) => <BusinessCardRequest {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/EmployeeProfile' component={(props) => <EmployeeProfile {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/EmployeeProfileReview' component={(props) => <EmployeeProfileReview {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/EmployeeProfileOther' component={(props) => <EmployeeProfileOther {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/ProfilePage' component={(props) => <ProfilePage {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/ProfilePageLumapps' component={(props) => <ProfilePageLumapps {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/SelfClose' component={(props) => <SelfClose {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/OrganizationChart' component={(props) => <OrganizationChart {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/EmployeePhoneNumber' component={(props) => <EmployeePhoneNumber {...props} UserDetails={this.state.UserDetails} />} />
            <Route path='/Weather' component={(props) => <Weather {...props} UserDetails={this.state.UserDetails} />} />  
            <Route path='/SearchResultView' component={(props) => <SearchResultView {...props} UserDetails={this.state.UserDetails} />} /> 
            <Route path='/Scriber/BoostInfinite' component={(props) => <BOOSTscriberNew {...props} UserDetails={this.state.UserDetails} />} />
              <Route path='/BracketBreaks' component={(props) => <BracketBreaks {...props} UserDetails={this.state.UserDetails} />} />
              <Route path='/WorldCup' component={(props) => <BracketBreaks {...props} UserDetails={this.state.UserDetails} />} />
              
              <Route path='/BusinessEvent' component={(props) => <FirstAidClasses {...props} UserDetails={this.state.UserDetails} />} />
      </Layout>
    );
  }
}
