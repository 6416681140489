import React, { Component } from 'react';
import { HttpRequest } from '../CommonOperations';

export class BoostRedirect extends Component {
    static displayName = BoostRedirect.name;
    constructor(props) {
        super(props);
        this.state = {
            showModel: false, buttonValue: false, IsAlreadyBoostScriber: true     
        }
        this.UserDetails = this.props.UserDetails;
    }
   async componentDidMount() {
       await this.IsBoostScriberInfoExist();
    }

    IsBoostScriberInfoExist = async () => {
        if (this.UserDetails.GoogleData != null) {

            let param = {
                EmployeeID: this.UserDetails.GoogleData.externalIds[0].value
            }

            var response = await HttpRequest('Content/IsBoostScriberInfoExist', 'POST', param);
            const responseData = await response.json();
            if (responseData.IsSuccessful) {
                responseData.Data == null ? this.setState({ IsAlreadyBoostScriber: false }) : this.setState({ IsAlreadyBoostScriber: true })
            }
        }
    }


    RedirectToSignup = (value) => {
        this.props.history.push('/Scriber/BoostSignUp/' + value);
    }

    render() {
        if (this.UserDetails.GoogleData == null || this.UserDetails.GoogleData == undefined) { return '' }
        return (
            <>
                <div className="boostscriber-container">
                    <div className="container boostscriber" style={{ display: this.state.IsAlreadyBoostScriber == true ? 'block' : 'none' }}  >

                        <div className="row">
                            <div className="co-lg-12 col-md-12 col-xs-12 col-sm-12">
                                <div className="form-boostSign CenterAllItem">
                                    <h1>  BOOSTscriber Benefit</h1>

                                    <h4 className="centerTextImg">

                                        <img src="../Images/boost-mobile-logo.png" width="150" alt="" />

                                        You are currently enrolled in the BOOSTscriber program.
                
                    </h4>
                                    <h4 style={{ margin:'15px 0'}}>Dish Employees Receive:</h4>

                                    <ul className="boostscriber-list">
                                        <li><span>50% Off all Eligible Boost Mobile Plans</span></li>
                                        <li><span>Unlimited Talk and Texting</span></li>
                                        <li><span>Access to new expanded data network (T-Mobile)</span></li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="container boostscriber" style={{ display: this.state.IsAlreadyBoostScriber == false ? 'block' : 'none' }}>
                        <div className="formNewUserBootsSignup">
                            <div className="row " style={{ textAlign: 'center' }}>
                                <h2 >BOOSTscriber Offer </h2>
                                <div>
                                    Select your plan on your existing Boost Mobile account:
            
                    </div>
                                <hr />



                            </div>

                            <div className="row">
                                <div className="co-lg-12">
                                    {/*<h2> Eligible plans on 8/1:</h2> */}
                                    <div className="pricing-table-wrapper">

                                        <ul className="pricing-table">

                                            <li className="pricing-table__item pricing-table__item--popular" onClick={()=>this.RedirectToSignup(1)}>
                                                <a>
                                                    <img src="images/boost-mobile-logo.png" alt="" className="pricing-table__img" />
                                                    <h3 className="pricing-table__title">  $35 for 10GB<br />Unl. Talk/Txt  </h3>
                                                </a>
                                            </li>

                                            {/*<li class="pricing-table__item pricing-table__item--popular" ng-click="RedirectToSignup('$45 for 15GB ($Hrink It) Unl. Talk/Txt $40 after 3 months of on time payments $35 after 6 months')">
                                                <a>
                                                    <img src="images/boost-mobile-logo.png" alt="" class="pricing-table__img" />
                                                    <h3 class="pricing-table__title"> $45 for 15GB ($Hrink It) <br />Unl. Talk/Txt<br />$40 after 3 months of on time payments $35 after 6 months</h3>
                                                </a>
                                            </li>*/}
                                            <li className="pricing-table__item pricing-table__item--popular" onClick={() => this.RedirectToSignup(2)}>



                                                <a >
                                                    <img src="images/boost-mobile-logo.png" alt="" className="pricing-table__img" />
                                                    <h3 className="pricing-table__title">$50 Unlimited  <br />Data/Talk/Txt</h3>
                                                </a>
                                            </li>


                                        </ul>
                                        <br />
                                        <ul className="pricing-table">


                                            <li className="pricing-table__item pricing-table__item--popular" onClick={() => this.RedirectToSignup(3)}>
                                                <a>
                                                    <img src="images/boost-mobile-logo.png" alt="" className="pricing-table__img" />
                                                    <h3 className="pricing-table__title">
                                                        $25 for 5GB
                                        <br />Unl. Talk/Txt
                                    </h3>

                                                </a>
                                            </li>

                                            {/*<li class="pricing-table__item pricing-table__item--popular" ng-click="RedirectToSignup('2 for $60 Family Plan (+$30 per line) at 12GB of Data (discount applied to first line only)')">
                                                <a>
                                                    <img src="images/boost-mobile-logo.png" alt="" class="pricing-table__img" />
                                                    <h3 class="pricing-table__title">
                                                        2 for $60 Family Plan (+$30 per line) at 12GB of Data (discount applied to first line only)<br />Unl. Talk/Txt
                
                                    </h3>
                                                </a>

                                            </li>*/}
                                            <li className="pricing-table__item pricing-table__item--popular" onClick={() => this.RedirectToSignup(4)}>
                                                <a>
                                                    <img src="images/boost-mobile-logo.png" alt="" className="pricing-table__img" />
                                                    <h3 className="pricing-table__title">
                                                        {/*3 for $90 Family Plan (+$40 per line) Unlimited (discount applied to first line only)<br />Unl. Talk/Txt*/}
                                                        3 for $90 Family Plan 12gb Talk/Txt/Data (+$30 per line)
                                                        Also applies to below 12GB family plans
                                        <span style={{fontSize:'10px'}}>
                                                            <br />4 for $120
                                            <br />5 for $150
                                        </span>

                                                    </h3>
                                                </a>
                                            </li>

                                        </ul>




                                    </div>
                                </div>
                            </div>







                        </div>
                    </div>
                </div>
            </>
        );
    }
}
