import React, { Component, useReducer } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect }from 'react-router-dom';
import { HttpRequest, CheckSupportIDException, } from './components/common/CommonOperations';
import Home from './components/home/home';
import Dashbaord from './components/dashboard/dashboard.component'; 
import EventPage from './components/EventPage/event.component';
import PreviewPage from './components/dashboard/PreviewPage';
import './assets/styles/style.css';

export default class HopCms extends Component {
    static displayName = HopCms.name;
    constructor(props) {
        super(props);
        var userDetails = { PersonID: null, NTLogin: null, AcademyAwardsEndDate: null, GoogleData: null }
        this.state = { UserDetails: userDetails, showModel: false, ntLoginDate: '' }
    }


    async  componentDidMount() {
        await this.GetNTLogin();

        await this.GetLoggedUserDetails(this.state.ntLoginDate.key, this.state.ntLoginDate.personID, this.state.ntLoginDate.academyAwardsEndDate);
    }

    componentWillUnmount() {
        clearInterval(this.setTimeout)
    }

    async GetNTLogin() {
        var response = await HttpRequest('SymptomTracker/GetNTLogin', 'Get')
        var data = await response.json();
        this.setState({ ntLoginDate: data })
    }

    async GetLoggedUserDetails(ntLogin, personID, academyAwardsEndDate) {
        //var userDetail = this.state.UserDetails;
        //userDetail.NTLogin = ntLogin;
        //userDetail.PersonID = personID;
        //userDetail.AcademyAwardsEndDate = academyAwardsEndDate;

        var responseGoogle = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + ntLogin.toLowerCase(), 'GET');
        var dataGoogle = await responseGoogle.json();
        if (dataGoogle.success == true) {
            //userDetail.GoogleData = JSON.parse(dataGoogle.data);
            //await this.setState({ UserDetails: userDetail });
            //console.log(userDetail.GoogleData);

            this.setState(prevState => ({
                UserDetails: {
                    ...prevState.UserDetails,
                    NTLogin: ntLogin,
                    PersonID: personID,
                    AcademyAwardsEndDate: academyAwardsEndDate,
                    GoogleData: JSON.parse(dataGoogle.data)
                }
            }))
            console.log('after')
            console.log(this.state.UserDetails)
        }
        else if (dataGoogle.success == false) {
            setTimeout(async () => {
                var responseGoogle1 = await HttpRequest('SymptomTracker/GetUserDetailFromGoogleApi/' + ntLogin.toLowerCase(), 'GET');
                const dataGoogle1 = await responseGoogle1.json();

                this.setState(prevState => ({
                    UserDetails: {
                        ...prevState.UserDetails,
                        NTLogin: ntLogin,
                        PersonID: personID,
                        AcademyAwardsEndDate: academyAwardsEndDate,
                        GoogleData: JSON.parse(dataGoogle1.data)
                    }
                }))

            }, 3000);
        }
    }   


    render() {
        return (
            
                <Router>
                    <Switch>
                         <Home UserDetails={this.state.UserDetails}>
                        <Route path='/HopCMS/Dashbaord' component={(props) => <Dashbaord {...props} UserDetails={this.state.UserDetails} />} />
                        <Route path='/HopCMS/EventPage' component={(props) => <EventPage {...props} UserDetails={this.state.UserDetails} />} />
                        <Route path='/HopCMS/PreviewPage' component={(props) => <PreviewPage {...props} UserDetails={this.state.UserDetails} />} />
                        
                        </Home>
                    </Switch>
                </Router>

            );
    }
}

