import React, { Component } from 'react';
import { HttpRequest, CheckSupportIDException, } from '../common/CommonOperations';
import { connect } from "react-redux";
import { MessageBox } from '../common/modalBox/MessageBox'
import './style/otherCss.css'

class SelectPicks extends Component {
    static displayName = SelectPicks.name;

    constructor(props) {
        super(props);
        this.state = {
            selectedItemNumber: 0, categoryList: [], categoryDetail: [], categoryIndexNumber: 0, userResponseDetail: [],
            showMessageBox: false, message: '', messageType: '1', txtValueOne: 0, txtValueTwo: 0, treeSelectedEmployee: this.props.treeSelectedEmployee
        };
        this.UserDetails = this.props.UserDetails;
        this.txtQues2 = React.createRef();
        this.txtQues1 = React.createRef();
    }


    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });
    componentDidMount() {
        if (this.props.UserDetails.AcademyAwardsEndDate == null) return
        else {
            let awardsLastDate = this.props.UserDetails.AcademyAwardsEndDate;
            var todaydate = (new Date()).valueOf();
            var awardsEndDate = (new Date(awardsLastDate)).valueOf();
            if (awardsEndDate < todaydate) {
                let path = "/AcademyAwards/Leaderboard";
                this.props.history.push(path)
            }

            else {
                this.GetAcademyResults();
                this.getIndexFromResult();
                document.title = 'Academy Awards';
            }
        }
    }
    getIndexFromResult() {
        if (this.state.treeSelectedEmployee.length != 0) {
            this.setState((state) => ({ categoryIndexNumber: parseInt(this.props.treeSelectedEmployee) }));
        }
    }

    GetAcademyResults = async () => {
        var response = await HttpRequest('AcademyAwards/GetAcademyResults', 'get');
        const res = await CheckSupportIDException(response)
        this.setState({
            categoryList: res.AcademyNominationCategories, userResponseDetail: res.AcademyNominationUserResponses
        });
    }
    GetAcademyResultsForUserByPersonID = async (PersonID) => {
        var path = "/AcademyAwards/Review/" + PersonID;
        this.props.history.push(path)

    }
    GetId(categoryId, position) {
        if (this.state.userResponseDetail != null) {
            let result = this.state.userResponseDetail.filter(itemRes => itemRes.AcademyNominationCategoryID == categoryId);
            let index = (position == 'First') ? 0 : 1;
            return result.length > 0 ? result[index].ID : 0;
        }
        else {
            return Number(0);
        }
    }
    handleSelectedNominee = async (id, selectedNominee) => {
        if (this.state.categoryList[this.state.categoryIndexNumber].ID == id) {
            let indexCount = this.state.categoryIndexNumber + 1;
            this.setState((state) => ({categoryIndexNumber: indexCount}))
            this.renderCategory();
        }
        let count = 0;
        this.setState({ selectedItemNumber: count++ })
        var arr = [];
        arr.push({
            id: this.GetId(selectedNominee.AcademyNominationCategoryID, 'First'), TieBreakerResponse: null,
            AcademyNominationCategoryID: selectedNominee.AcademyNominationCategoryID, AcademyNomineeID: selectedNominee.AcademyNominee.ID
        });
        var param = { AcademyNominationUserResponses: arr };
        var response = await HttpRequest('AcademyAwards/PostAcademyNominationUserResponse', 'post', param);
        this.GetAcademyResults();
    }
    submitTiebreakerValue = async (item, tiebreaker1NomineeId, tiebreaker2NomineeId) => {
        var arr = [];
        const re = /^[0-9\b]+$/;
        if (this.txtQues1.current.value == '') {
            this.ShowMessageBox("Fill the Ques1 value with number.", 3); return false;
        }
        else {
            arr.push({
                id: this.GetId(item.AcademyNominationCategoryID, 'First'), TieBreakerResponse: this.txtQues1.current.value,
                AcademyNominationCategoryID: item.AcademyNominationCategoryID, AcademyNomineeID: tiebreaker1NomineeId
            });
        }
        if (this.txtQues2.current.value == '') {
            this.ShowMessageBox("Fill the Ques2 value with number.", 3); return false;
        }
        else {
            arr.push({
                id: this.GetId(item.AcademyNominationCategoryID, 'second'), TieBreakerResponse: this.txtQues2.current.value,
                AcademyNominationCategoryID: item.AcademyNominationCategoryID, AcademyNomineeID: tiebreaker2NomineeId
            });
        }
        var param = { AcademyNominationUserResponses: arr };
        var response = await HttpRequest('AcademyAwards/PostAcademyNominationUserResponse', 'post', param);
        this.setState({ categoryIndexNumber: 0 })
        this.GetAcademyResults();
    }

    renderCategory = () => {
        var category = this.state.categoryList[this.state.categoryIndexNumber]
        if (category == null || category == 0) {
            return;
        }
        return (
            <>
                <div className="col m12 s12 cartegoryMargin" >
                    <span className="categorytitle" id={category.ID}>{category.Description}</span>
                    <span className="sub-title title_p">{category.PointValue} <span className="pts">pts</span></span>
                </div>
            </>
        )
    }
    renderForward = () => {
        let indexCount = this.state.categoryIndexNumber + 1;
        this.setState((state) => ({ categoryIndexNumber: indexCount }))
    }
    renderPrevious = () => {
        let indexCount = this.state.categoryIndexNumber - 1;
        this.setState((state) => ({ categoryIndexNumber: indexCount }))
    }
    className = (item) => {

        if (this.state.userResponseDetail != null) {
            var result = this.state.userResponseDetail.filter(items => items.AcademyNominationCategoryID == item.AcademyNominationCategoryID && items.AcademyNomineeID == item.AcademyNominee.ID);
            return (result != null && result.length > 0) ? 'nomineeBlockDetails nomineebor highLightSelectedNomineeDiv card horizontal' : 'nomineeBlockDetails nomineebor card horizontal'
        }
        else {
            return 'nomineeBlockDetails nomineebor card horizontal';
        }
    }

    renderCategoryDetail = () => {

        if (this.state.categoryList == null || this.state.categoryList.length == 0) {
            return;
        }
        else {
            var categoryFirst = this.state.categoryList[this.state.categoryIndexNumber];
            var categoryFirstNom = categoryFirst.AcademyNominationCategoryDetails

            if (categoryFirst.IsTieBreakerQuestion == true) {

                if (this.state.userResponseDetail != null && this.state.userResponseDetail.length > 0) {
                    let userRes = this.state.userResponseDetail.filter((item, index) => { return item.TieBreakerResponse != null });
                    if (userRes != null && userRes.length > 0) {
                        setTimeout(() => { this.txtQues1.current.value = userRes[0].TieBreakerResponse }, 0);
                        if (userRes.length == 2) {
                            setTimeout(() => { this.txtQues2.current.value = userRes[1].TieBreakerResponse }, 0);
                        }
                    }
                }
                return (
                    <>{categoryFirstNom.length == 0 ?
                        <></>
                        :
                        <>
                            <li className="col s4 m4 reviewBlock desktopBlock" >
                                <div className="nomineeBlockDetails nomineebor card" >
                                    <span>
                                        <span> <p className="nomineesTextHeader nomineebor pad_nominee tieBreaker">
                                            <span>{categoryFirstNom[0].AcademyNominee.NomineeName}</span>
                                        </p><span className="line_1"></span></span>
                                        <input type="number" className="form-control" ref={this.txtQues1} />
                                    </span>

                                </div>
                            </li>
                            <li className="col s4 m4 reviewBlock desktopBlock" >
                                <div className="nomineeBlockDetails nomineebor card" >
                                    <span>
                                        <span> <p className="nomineesTextHeader nomineebor pad_nominee tieBreaker">
                                            <span>{categoryFirstNom[1].AcademyNominee.NomineeName}</span></p><span className="line_1"></span></span>
                                        <input type="number" className="form-control" ref={this.txtQues2} />
                                    </span>

                                </div>
                            </li>
                            <li>
                                <div className="col s12 m12 text-center" >
                                    <button type="button" className="btn btn-primary" style={{ margin: '20px auto', padding: '0 20px' }}
                                        onClick={() => this.submitTiebreakerValue(categoryFirstNom[0], categoryFirstNom[0].AcademyNominee.ID, categoryFirstNom[1].AcademyNominee.ID)}>SAVE TIE BREAKERS</button>
                                </div>
                            </li>
                        </>
                    }
                    </>
                )
            }
            else {
                return (
                    categoryFirstNom.map((item, index) => (
                        <>
                            <li id={item.AcademyNominee.ID} key={index}
                                className="col s12 m4 nomineeBlock reviewBlock desktopBlock" onClick={(e) => { this.handleSelectedNominee(item.AcademyNominationCategoryID, item) }}>
                                <div className={this.className(item)} >
                                    <span className="nominimg card-image"><img src={'images' + item.AcademyNominee.ImageUrl} alt="" /></span>

                                    <span className="card-stacked">
                                        <span> <p className="nomineesTextHeader nomineebor pad_nominee ng-binding"> <span>{item.AcademyNominee.NomineeName}</span></p><span className="line_1"></span></span>
                                        <span className="nomineesBlockText">{item.AcademyNominee.MovieTitle}</span>
                                    </span>

                                </div>
                            </li>
                        </>
                    ))
                )
            }
        }
    }

    render() {
        if (this.state.categoryList.length == 0)
            return <></>;
        else {
            return (
                <>
                    <div className="container selectPicksContainer">
                        <div className="selectPicksPage">
                            <div className="scoreBar row">
                                <div className="progressBarContianer" style={{ paddingTop: '15px' }}>
                                    <div className="col s12 m12 progress progressbrand" style={{ padding: '0px' }}>
                                        <div className="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="24" style={{ width: (this.state.userResponseDetail == null) ? 0 : (this.state.userResponseDetail.length / (this.state.categoryList.length - 1)) * 100 + '%' }}></div>
                                    </div>

                                    <div className="selectPicksBackButton ">
                                        {this.state.categoryIndexNumber == 0
                                            ? <></>
                                            : <div id='previousButton'>
                                                <span
                                                    onClick={() => this.renderPrevious()}
                                                    style={{ float: 'left', cursor: 'pointer' }}>
                                                    <img src="/images/AcademyAwards/arrow-left-short.svg" alt="" className="glyphicon glyphicon-chevron-left" />
                                                    <a> {this.state.categoryList[parseInt(this.state.categoryIndexNumber) - 1].Description} </a>
                                                </span>
                                            </div>

                                        }
                                        {this.state.categoryIndexNumber == 23
                                            ? <></>
                                            : <div id='forwardButton'>
                                                <span
                                                    onClick={() => this.renderForward()}
                                                    style={{ float: 'right', cursor: 'pointer' }} className="ng-scope">
                                                    <a>  {this.state.categoryList[parseInt(this.state.categoryIndexNumber) + 1].Description}</a>
                                                    <img src="/images/AcademyAwards/arrow-right-short.svg" alt="" className="glyphicon glyphicon-chevron-right" />
                                                </span>
                                            </div>

                                        }



                                    </div>
                                    <div className="itemDisplay" >
                                        <img id="image" src="/images/AcademyAwards/leaf.png" alt="" />
                                        <div className="scoreboards">
                                            <center>
                                                <span id="upText1" className="ng-binding">{this.state.categoryIndexNumber + 1}</span>
                                                <span id="upTextimg1"><img src="/images/AcademyAwards/lineslice.png" alt="" /></span>
                                                <span id="downText1">{this.state.categoryList.length}</span>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row"> {this.renderCategory()}</div>
                            <div className="userGridDisplay">
                                <ul className="row">
                                    {this.renderCategoryDetail()}
                                </ul>
                            </div>
                            <div className="col-lg-12 nomineesReviewText text-center">
                                <button onClick={() => this.GetAcademyResultsForUserByPersonID(this.UserDetails.PersonID)} className="btn btn-primary">REVIEW YOUR PICKS</button>
                            </div>
                        </div>
                    </div>
                    <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
                </>
            );
        }
    }
}
const mapStateToProps = state => {
    return {
        treeSelectedEmployee: state.treeSelectedEmployee
    };
};
export default connect(mapStateToProps, null)(SelectPicks)
