import React, { Component } from 'react';

export class BOOSTscriberB extends Component {
    static displayName = BOOSTscriberB.name;
    constructor(props) {
        super(props);
        this.state = {
            showModel: false, buttonValue: false,
            IsAlreadyBoostScriber: true,            ErrorMsg: '',            RedirectActionPerform: false,            ShowContent: false,
            divStep1: true,            divStep2: false,            divStep3Step2Yes: false,            divStep3Step2No: false,            divStep4Step3Yes: false,
            divStep4Step3No: false,            divStep5Step4No: false,            divStep6Step4No: false,            divStep5No: false,            divStep5None: false,
            ModelDevice: '',            div3: false,            divStep4Step3Cannot: false,            EmployeeResponse1: null,            EmployeeResponse11: null,
            EmployeeResponse111: null,            agreeAndContinueFlag: false
        }
    }
    RedirectToSignup = (value) => {
        this.props.history.push('/Scriber/BoostSignUp/' + value);
    }

    ProcessFarword = () => {
        this.setState({})
       this.setState({divStep2 : true})
       this.setState({divStep1 : false})
       this.setState({divStep3Step2Yes : false})
       this.setState({divStep3Step2No : false})
       this.setState({divStep4Step3Yes : false})
       this.setState({divStep4Step3No : false})
       this.setState({divStep5Step4No : false})
       this.setState({divStep5None : false})
       this.setState({EmployeeResponse11 : null})
       this.setState({EmployeeResponse111 : null})
       this.setState({divStep6Step4No : false})
       this.setState({divStep4Step3Cannot : false})
       this.setState({divStep5No : false})
    }
    Step2Click= (ngValue) => {

       this.setState({EmployeeResponse1 : ngValue})
        if (ngValue == 'false') {
           this.setState({divStep3Step2Yes : false})
           this.setState({divStep3Step2No : true})
           this.setState({divStep3Step2Yes : false})

           this.setState({divStep4Step3Yes : false})
           this.setState({divStep4Step3No : false})
           this.setState({divStep5Step4No : false})
           this.setState({divStep5None : false})
           this.setState({divStep4Step3Cannot : false})
           this.setState({EmployeeResponse11 : null})
           this.setState({EmployeeResponse111 : null})
           this.setState({divStep5No : false})
           this.setState({divStep6Step4No : false})
        }
        else {
           this.setState({divStep3Step2Yes : true})
           this.setState({divStep3Step2No : false})

        }

    }
    Step3Click = (ngValue) => {
       this.setState({EmployeeResponse11 : ngValue})
       this.setState({ModelDevice : ''})
       this.setState({divStep5No : false})
       this.setState({divStep5None : false})
        if (ngValue == 'true') {
           this.setState({divStep4Step3No : false})
           this.setState({divStep4Step3Cannot : true})
        }
        else if (ngValue == 'false') {
           this.setState({divStep4Step3No : true})
           this.setState({divStep4Step3Cannot : false})
           this.setState({divStep5Step4No : false})
        }
        else if (ngValue == 'Cannot') {
           this.setState({divStep4Step3No : false})
           this.setState({divStep4Step3Cannot : true})

        }
    }
    Step4Click = (ngValue) => {
       this.setState({divStep5None : false})
       this.setState({divStep6Step4No : false})
       this.setState({divStep6Step4No : false})
       this.setState({divStep5No : false})

        if (ngValue == 'false') {
           this.setState({divStep5Step4No : true})
        }
        else {
           this.setState({divStep5Step4No : false})
           this.setState({divStep6Step4No : true})
        }

    }
    Step5Click = (ngValue) => {
        if (ngValue == 'false') {
           this.setState({divStep5No : true})
        }
        else {
           this.setState({divStep5No : false})
            //  window.localStorage.setItem('boostimei',this.setState({EmployeeResponse11}))
            this.props.history.push('/Scriber/BoostSignUpImei')
        }

    }
    Step6Click = () => {
       this.setState({divStep5None : true})
    }
    



    render() {
        return (
            <>
                <div className="boostscriber-container">
                <div  className="container boostscriber" ng-if="IsAlreadyBoostScriber == false && ShowContent">

                    <div  className="formNewUserBootsSignup">
                        <span  className="cricleImg">
                            <img src="images/boost-mobile-logo.png" alt="" />
                        </span>
                        <ul>
                            <li  className="label-text">
                                Would you like to use a device you currently own?
               
            </li>
                            <li>

                                <label for="toggle-on"  className="radioContainer">
                                    No, I am purchasing a phone from Boost
                           
                          
                    <input id="toggle-on" className="toggle toggle-left" type="radio" name="wfhRadio" value="False" onClick={()=>this.Step2Click('false')} />
                                    <span  className="checkmark"></span>

                                </label>


                                <label for="toggle-off"  className="radioContainer">
                                    Yes, I am using a device I currently own
                       
                    <input id="toggle-off" className="toggle toggle-right" type="radio" name="wfhRadio" value="True" onClick={() =>this.Step2Click('true')} />
                                    <span  className="checkmark"></span>
                                </label>

                            </li>
                        </ul>
                    </div>

                    <div id="step3">
                            <div style={{ display: this.state.divStep3Step2Yes == true ? 'block' : 'none' }}  className="formNewUserBootsSignup">

                            <ul>
                                <li>
                                    <span>
                                        Please use the below link to verify your phones eligibility. Is your phone eligible?<br /><br />
                                        <a href="https://www.boostmobile.com/bring-your-phone" target="_blank"> https://www.boostmobile.com/bring-your-phone</a>

                                    </span>
                                </li>
                                <li>

                                    <label for="toggle-on1"  className="radioContainer">
                                        No, When I enter my device information, my phone is not compatible for Boost service.
                       
                        <input id="toggle-on1" className="toggle toggle-left" type="radio" name="wfhRadio1" value="False" data-ng-model="EmployeeResponse11" onClick={() =>this.Step3Click('false')} />
                                        <span  className="checkmark"></span>
                                    </label>

                                    <label for="toggle-off1"  className="radioContainer">
                                        Yes, When I enter my device information, my phone is compatible for Boost service.
                       
                        <input id="toggle-off1" className="toggle toggle-right" type="radio" name="wfhRadio1" value="True" data-ng-model="EmployeeResponse11" onClick={() =>this.Step3Click('true')} />
                                        <span  className="checkmark"></span>
                                    </label>
                                    <label for="toggle-off12"  className="radioContainer">
                                        Cannot Determine, When I enter my device information I receive one of the following messages
               
                        <br /><span>
                                            <span>&#8226; </span>We cannot determine if your phone is eligible to switch to Boost at this time
               
                        </span>
                                        <br /> <span >
                                            <span>&#8226; </span>  This phone is already active on our network
                        </span>


                                            <input id="toggle-off12" className="toggle toggle-right" type="radio" name="wfhRadio1" value="Cannot" data-ng-model="EmployeeResponse11" onClick={() =>this.Step2Click('Cannot')}  />
                                        <span  className="checkmark"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                            <div style={{ display: this.state.divStep3Step2No == true ? 'block' : 'none' }}  className="formNewUserBootsSignup">

                            <ul>
                                <li>
                                    <span>
                                            Please proceed to the following link and select &quot;Shop Phones&quot;	 to purchase a compatible device. During the transaction you will need to select an eligible plan. To view the BOOSTscriber eligible plans, please visit the <a href="https://intranet.global.dish.com/DeptContent/businessrulesandfaqs.pdf" target="_blank">Business Rules and FAQs</a>.<br />
                                        <br />

                                        <a href="https://www.boostmobile.com/about/expanded-network.html" target="_blank">  https://www.boostmobile.com/about/expanded-network.html</a>
                                        <br />
                                            <br />
                                            Once you have purchased and activated your phone please revisit the BOOSTscriber home page on the HOP to continue with <a href="/Scriber/BoostRedirect" >Part 2</a> and receive your BOOSTscriber discount.
                    </span>
                                </li>


                            </ul>

                        </div>
                    </div>

                    <div id="step4">
                            <div style={{ display: this.state.divStep4Step3No == true ? 'block' : 'none' }}  className="formNewUserBootsSignup">

                            <ul>
                                <li>
                                    <span>
                                        Your current device is not compatible. If you would like to purchase a device from Boost, please proceed to the following link and select &quot;Shop Phones&quot; to purchase a compatible device.
                        During the transaction you will need to select an eligible plan. To view the BOOSTscriber eligible plans, please visit the <a href="/DeptContent/businessrulesandfaqs.pdf" target="_blank">Business Rules and FAQs</a>.
                        <br /><br /><a href="https://www.boostmobile.com/about/expanded-network.html" target="_blank">  https://www.boostmobile.com/about/expanded-network.html</a>

                                        <br /><br />
                                            Once you have purchased and activated your phone please revisit the BOOSTscriber home page on the HOP to continue with <a href="/Scriber/BoostRedirect" >Part 2</a> and recieve your BOOSTscriber discount.
               
                    </span>
                                </li>


                            </ul>

                        </div>

                            <div style={{ display: this.state.divStep4Step3Cannot == true ? 'block' : 'none' }}  className="formNewUserBootsSignup">



                            <ul>
                                <li>
                                    <span>
                                        Are you a Boost subscriber currently?
               
                    </span>
                                </li>
                                <li>

                                    <label for="toggle-on112"  className="radioContainer">
                                        No, my cell service is with another carrier
                           
                        <input id="toggle-on112" className="toggle toggle-left" type="radio" name="wfhRadio12" value="False" onClick={() =>this.Step4Click('false')}  />
                                        <span  className="checkmark"></span>
                                    </label>

                                    <label for="toggle-off113"  className="radioContainer">
                                        Yes, My cellular service is currently with Boost
                       
                        <input id="toggle-off113" className="toggle toggle-right" type="radio" name="wfhRadio12" value="True" onClick={() =>this.Step4Click('true')}  />
                                        <span  className="checkmark"></span>
                                    </label>

                                </li>
                            </ul>




                        </div>

                    </div>
                        <div style={{ display: this.state.divStep5Step4No == true ? 'block' : 'none' }}  className="formNewUserBootsSignup">



                        <ul>
                            <li>
                                <span>
                                    Have you contacted your current carrier to ensure that your phone is unlocked and free of financial obligation?
               
               
                </span>
                            </li>
                            <li>

                                <label for="toggle-on1123"  className="radioContainer">
                                    Yes, I have contacted my current carrier
                       
                       
                    <input id="toggle-on1123" className="toggle toggle-left" type="radio" name="wfhRadio123" value="False" onClick={() =>this.Step5Click('true')}   />
                                    <span  className="checkmark"></span>
                                </label>

                                <label for="toggle-off1134"  className="radioContainer">
                                    No, I have not contacted my current carrier
                       
                    <input id="toggle-off1134" className="toggle toggle-right" type="radio" name="wfhRadio124" value="True" onClick={() =>this.Step5Click('false')}  />
                                    <span  className="checkmark"></span>
                                </label>

                            </li>
                        </ul>




                    </div>

                        <div style={{ display: this.state.divStep5No == true ? 'block' : 'none' }}  className="formNewUserBootsSignup">

                        <ul>
                            <li>
                                <span>
                                    Please contact your current carrier before you set up your Boost account to ensure your device is unlocked and that you are free of financial obligation from any equipment. Thank you!
                </span>cu                   </li>


                        </ul>

                    </div>

                        <div style={{ display: this.state.divStep6Step4No == true ? 'block' : 'none' }}  className="formNewUserBootsSignup">

                        <div  className="row " >

                            <div>
                                Select your plan on your existing Boost Mobile account:
               
            </div>
                            <hr />



                        </div>

                        <div  className="row">
                            <div  className="co-lg-12">

                                <div  className="pricing-table-wrapper">

                                    <ul  className="pricing-table">

                                            <li className="pricing-table__item pricing-table__item--popular" onClick={() => this.RedirectToSignup(1)}>
                                            <a>
                                                <img src="images/boost-mobile-logo.png" alt=""  className="pricing-table__img" />
                                                <h3  className="pricing-table__title">  $35 for 10GB<br />Unl. Talk/Txt  </h3>
                                            </a>
                                        </li>


                                            <li className="pricing-table__item pricing-table__item--popular" onClick={() => this.RedirectToSignup(2)}>



                                            <a title="Please note that Boost has 2 different $50 unlimited plans. Please ensure you have the one compatible with the Expanded Data Network by calling 1 (866) 402-7366. If not please select None of The Above">
                                                <img src="images/boost-mobile-logo.png" alt=""  className="pricing-table__img" />
                                                <h3  className="pricing-table__title">$50 Unlimited  <br />Data/Talk/Txt</h3>
                                            </a>
                                        </li>


                                    </ul>
                                    <br />
                                    <ul  className="pricing-table">


                                            <li className="pricing-table__item pricing-table__item--popular" onClick={() => this.RedirectToSignup(3)}>
                                            <a>
                                                <img src="images/boost-mobile-logo.png" alt=""  className="pricing-table__img" />
                                                <h3  className="pricing-table__title">
                                                    $25 for 5GB
                                    <br />Unl. Talk/Txt
                                </h3>

                                            </a>
                                        </li>


                                            <li className="pricing-table__item pricing-table__item--popular" onClick={() => this.RedirectToSignup(4)}>
                                            <a>
                                                <img src="images/boost-mobile-logo.png" alt=""  className="pricing-table__img" />
                                                <h3  className="pricing-table__title">

                                                    3 for $90 Family Plan 12gb Talk/Txt/Data (+$30 per line)
                                                    Also applies to below 12GB family plans
                                    <span >
                                                        <br />4 for $120
                                        <br />5 for $150
                                    </span>

                                                </h3>
                                            </a>
                                        </li>

                                    </ul>

                                    <br />
                                    <ul  className="pricing-table">

                                            <li className="pricing-table__item" onClick={() =>this.Step6Click()} >
                                            <a>
                                                <img src="images/boost-mobile-logo.png" alt=""  className="pricing-table__img" />
                                                <h3  className="pricing-table__title">  NONE OF THE ABOVE </h3>
                                            </a>
                                        </li>

                                        <li>
                                        </li>
                                        <li>
                                        </li>


                                    </ul>


                                </div>
                            </div>
                        </div>





                    </div>


                        <div style={{ display: this.state.divStep5None == true ? 'block' : 'none' }}  className="formNewUserBootsSignup">



                        <ul>
                            <li>
                                <span>
                                    Please visit your MYBoost online account at <a href="http://BoostMobile.com"> BoostMobile.com</a> to change your plan to one of these eligible plans.
                </span>
                            </li>

                        </ul>




                    </div>

                </div>




                    <div className="container boostscriber" style={{ display: this.state.IsAlreadyBoostScriber == true && this.state.ShowContent == true ? 'block' : 'none' }}>
                    <div  className="row">
                        <div  className="co-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div  className="form-boostSign CenterAllItem">
                                <h1>  BOOSTscriber Benefit</h1>

                                <h4  className="centerTextImg">

                                    <img src="../Images/boost-mobile-logo.png" width="150" alt="" />

                                    You are currently enrolled in the BOOSTscriber program.
               
                </h4>
                                <h4 >Dish Employees Receive:</h4>

                                <ul  className="boostscriber-list">
                                    <li><span>50% Off all Eligible Boost Mobile Plans</span></li>
                                    <li><span>Unlimited Talk and Texting</span></li>
                                    <li><span>Access to new expanded data network (T-Mobile)</span></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </>
        );
    }
}
