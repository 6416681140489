import React, { Component } from 'react';
import { MessageBox } from '../../common/modalBox/MessageBox'
import { HttpRequest } from '../CommonOperations';

export class BoostSignUp extends Component {
    static displayName = BoostSignUp.name;
    constructor(props) {
        super(props);
        this.state = {
            showModel: false, buttonValue: false, showMessageBox: false, message: '', messageType: '1', boostPlan:'',
        }
        this.UserDetails = this.props.UserDetails;
    }
    ShowMessageBox = (message, messageType) => this.setState({ showMessageBox: true, messageType: messageType, message: message });
    HideMessageBox = () => this.setState({ showMessageBox: false });
    componentDidMount() {
        let menuTittleName = this.props.history.location.pathname;
        let splitId = menuTittleName.substring(menuTittleName.lastIndexOf('/') + 1);
        if (splitId == 1) {
            this.setState({ boostPlan: '$35 for 10GB'})
        }
        else if (splitId == 2) {
            this.setState({ boostPlan: '$50 Unlimited' })
        }
        else if (splitId == 3) {
            this.setState({ boostPlan: '$25 for 5GB' })
        }
        else if (splitId == 4) {
            this.setState({ boostPlan: '3 for $90 Family Plan (+$40 per line) Unlimited (discount applied to first line only)' })
        }
        else {
            this.props.history.push('/Scriber/BOOSTscriberB/');
        }
    }
    validateEmployeeProfile = () => {
      
        return true;
    }
    SaveBoostScriberPlan = async () => {
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (document.getElementById('MobileNumber').value.trim() == '' || document.getElementById('MobileNumber').value.trim() == null)
        {
            document.getElementById('MobileNumber').focus();
            this.ShowMessageBox("Mobile Number should not be Blank .", 3); return false;
        }

        if (document.getElementById('MobileNumber').value !== '' && document.getElementById('MobileNumber').value.trim() !== null
            && (document.getElementById('MobileNumber').value).length !== 10) {
            document.getElementById('MobileNumber').focus();
            this.ShowMessageBox("Cell Phone Number should be 10 characters long.", 3); return false;
        }
        else if (document.getElementById('MobileNumber').value !== '' && !pattern.test(document.getElementById('MobileNumber').value)) {
            document.getElementById('MobileNumber').focus();
            this.ShowMessageBox("Cell Phone Number should be number.", 3); return false;
        }
        let param = {
            EmployeeID: this.UserDetails.GoogleData.externalIds[0].value,
            BoostMobilePhoneNumber: document.getElementById('MobileNumber').value,
            BoostPlan: this.state.boostPlan
        }

        var response = await HttpRequest('Content/SaveBoostScriberPlan', 'POST', param);
        const responseData = await response.json();

        let message = "<p><span style='font-weight: 400;'>Thank you for applying for your BOOSTscriber Benefit! You will receive a confirmation when your benefit has been activated.&nbsp;</span></p><p><span style = 'font-weight: 400;'> Your benefit will take effect on your next month&rsquo;s cycle.&nbsp;</span></p><p><span style = 'font-weight: 400;'> If you have any questions please email</span><span style = 'font-weight: 400;' ><a href = 'mailto:BOOSTscriber_support@dish.com'> BOOSTscriber_support@dish.com </a></span><span style = 'font-weight: 400;'>.</span></p>    <p><span style = 'font-weight: 400;'> If we need any further information or encounter any issues you will receive an email from our team</span></p> ";

        if (responseData.IsSuccessful) {
            this.ShowMessageBox(message, 1); return true;
        }
        else {
            this.ShowMessageBox(responseData.FriendlyMessage, 3);
            return false;
        }
    }
    render() {
        if (this.UserDetails.GoogleData == null || this.UserDetails.GoogleData == undefined) { return '' }
        return (
            <>
                <div className="boostscriber-container">
                    <div className="modal fade customModalBoxPopup customMessagePopup" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" ng-click="RedirectBoostScriber()" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content " style={{ width: '450px' }}>
                                <div className="modal-header" style={{backgroundColor:'#f99f38'}}>
                                    <h5 className="modal-title">Message</h5>
                                    <button type="button" ng-click="RedirectBoostScriber()" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" id="divErrorMsg" style={{textAlign:'left!important',fontZize:'14px'}}>

                                </div>
                                <div className="modal-footer">

                                    <button type="button" id="btnCloseConfirm" className="btn btn-boost submitBtn " style={{backgroundColor:'#f99f38'}}ng-click="RedirectBoostScriber()" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="bootsSignupBg clearfix">
                        <div className="container boostscriber">
                            <div className="container boostscriber"></div>
                            <div className="row">
                                <div className="co-lg-12 col-md-12 col-xs-12 col-sm-12">

                                    <div className="form-boostSign formImgCenter" ng-if="boostScriberDetails.BoostPlan!='NONE OF THE ABOVE'">
                                        <span className="cricleImg">
                                            <img src="images/boost-mobile-logo.png" alt="" />
                    </span>
                                        <h4 style={{textAlign:'center'}}>Please fill out below information</h4>
                                        <br />
                                        <ul className="listDisplay">
                                            <li className="wd100">

                                                <span>First Name</span><input type="text" readonly="readonly" defaultValue={this.UserDetails.GoogleData.name.givenName} className="form-control" /> </li>
                                            <li><span>Last Name</span> <input type="text" readonly="readonly" defaultValue={this.UserDetails.GoogleData.name.familyName} className="form-control" /></li>
                                            <li><span>Employee ID</span> <input type="text" readonly="readonly" defaultValue={this.UserDetails.GoogleData.externalIds[0].value} className="form-control" /></li>
                                            <li><span>Employee E-Mail</span> <input type="text" readonly="readonly" defaultValue={this.UserDetails.GoogleData.primaryEmail} className="form-control" /></li>
                                            <li><span>Boost Plan</span> <input defaultValue={this.state.boostPlan} type="text"  readonly="readonly" className="form-control" /></li>
                                            <li><span style={{ display: 'flex!important' }}>Boost Mobile Phone Number<span style={{ color: 'red' }} className="mendatoryFeild">*</span></span>
                                                <input id="MobileNumber" type="number" maxLength="10"  className="form-control" /></li>

                                            <li><button type="button" onClick={() => this.SaveBoostScriberPlan()} className="btn btn-primary">Submit</button> </li>
                                            </ul>
                </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                </div>
                <MessageBox show={this.state.showMessageBox} onHide={this.HideMessageBox} type={this.state.messageType} message={this.state.message} />
            </>
        );
    }
}
