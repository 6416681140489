import React, { Component } from 'react';
import { HttpRequest, HttpRequestWithouLoader, GetStartAndEndDate, CheckSupportIDException, HTMLTableExport, ShowLoader, ActiveMenu } from '../common/CommonOperations';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';



export class IllnessReportingContractorAdmin extends Component {
    static displayName = IllnessReportingContractorAdmin.name;


    constructor(props) {
        super(props);
        this.state = {
            allQuestionData: '',
            nextStep: false,
            prevStep: true,
            buttonValue: false,
            fieldValue: '',
            showModalWAH: false,
            wahModal: false,
            isVistor: false,
            allDisplayNone: false,
            successModalDisplay: false,
            errorModalDisplay: false,
            message: '',
            date: null,
            action: 1,
            allHideContent: true,
            userName: '',


        };
    }
    componentDidMount() {
        this.GetIllnessReportingQuestions();
        document.title = 'Symptom Tracker';
    }


    userInfoBox = (usertName = this.state.userName) => {
        //if (usertName == 'ASIF.IQBAL') {
        //    return this.state.userName;
        //} else {
        //    return this.state.userName;
        //}
        return this.state.userName;
    }
    isVistorCheck = (e) => {
        this.setState((state)=>({ isVistor: !this.state.isVistor }));
    }
    userNameCapture = (e) => {
        let textValue = e.target.value;
        let email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;;

        if (textValue.length > 7) {
            if (textValue.match(email)) {
                this.setState({ userName: 'asif.iqbal@dish.com' });


                this.setState({ nextStep: true });


            }
            else {

                this.setState({ userName: 'Please Enter a email address' });
            }
        } else {
            this.setState({
                userName: ''
            })
        }



    }






    successModalMessage(message = this.state.message, date = this.state.date) {

        if (date != null) {
            let currentDate = date;
            let day = currentDate.getDate();
            let month = currentDate.getMonth() + 1;
            let year = currentDate.getFullYear();
            let hours = currentDate.getHours();
            let mins = currentDate.getMinutes();
            return (
                <>
                    <span>Thank You</span>
                    <span>{month < 10 ? '0' + month : month}/{day < 10 ? '0' + day : day}/{year} {hours > 12 ? hours % 12 : '0' + hours}:{mins < 10 ? '0' + mins : mins} {hours < 12 ? 'AM' : 'PM'}</span>
                    <div dangerouslySetInnerHTML={{ __html: message }} ></div>
                </>
            );
        } else {

            return (<div dangerouslySetInnerHTML={{ __html: message }} ></div>);
        }
    }

    findSelection(field) {
        var test = document.getElementsByName(field);
        var sizes = test.length;
        let valueArr = '';
        for (let i = 0; i < sizes; i++) {
            if (test[i].checked == true) {
                valueArr = test[i].value;
                test[i].setAttribute('disabled', 'true');
            }
            test[i].setAttribute('disabled', 'true')
        }
        return valueArr;
    }

    nextStepBtn = (action) => {
        let checkValue = document.querySelector('#chkVisitor');
        let vistorNameTextBox = document.querySelector('#vistorName');
        if (action == 1) {
            let radioGroup = document.querySelectorAll('#fromStep2 li input[type="radio"]');

            let sizes = radioGroup.length / 2;
            let arrValue = [];
            for (let i = 0; i < sizes; i++) {
                let radiovalArr = this.findSelection(i + 1);
                arrValue.push(radiovalArr);
            }
            //debugger

            if (arrValue.indexOf('True') !== -1) {

                if (checkValue.checked == true && vistorNameTextBox.value.length == 0) {
                    alert('Please Enter Vistor Name');

                } else {
                    this.setState({
                        prevStep: false, errorModalDisplay: true, nextStep: false, allHideContent: false,
                        date: new Date(),
                        message: 'For your safety and the safety of others, please remain offsite and contact <a href="mailto:illnessreporting@dish.com">illnessreporting@dish.com</a> and your supervisor.'
                    });
                }

            } else {
                if (checkValue.checked == true && vistorNameTextBox.value.length == 0) {
                    alert('Please Enter Vistor Name');
                } else {
                    this.setState({
                        nextForm: true,
                        action: 2
                    })
                }

            }
        } else if (action == 2) {


            let radioGroup = document.querySelectorAll('#fromStep2 li input[type="radio"]');
            let sizes = radioGroup.length / 2;

            let arrValue = [];
            for (let i = 0; i < sizes; i++) {
                let radiovalArr = this.findSelection(i + 1);
                arrValue.push(radiovalArr);
            }
            //debugger
            if (arrValue.indexOf('True') !== -1) {
                if (checkValue.checked == true && vistorNameTextBox.value.length == 0) {
                    alert('Please Enter Vistor Name');

                } else {
                    this.setState({
                        prevStep: false, errorModalDisplay: true, nextStep: false, allHideContent: false,
                        date: new Date(),
                        message: 'For your safety and the safety of others, please remain offsite and contact <a href="mailto:illnessreporting@dish.com">illnessreporting@dish.com</a> and your supervisor.'
                    });
                }

            } else {

                if (checkValue.checked == true && vistorNameTextBox.value.length == 0) {
                    alert('Please Enter s Vistor Name');

                } else {
                    this.setState({
                        prevStep: false, successModalDisplay: true, nextStep: false, allHideContent: false,
                        date: new Date(),
                        message: 'You are clear to enter the building and proceed to your assigned work station.'
                    });
                }

            }
        }

    }

    hideModalFWAH = () => {
        this.setState((state) => ({ showModalWAH: !this.state.showModalWAH }));
    }


    async GetIllnessReportingQuestions() {
        var response = await HttpRequest('SymptomTracker/GetIllnessReportingQuestions', 'Get');
        const data = await response.json();
        this.setState({ allQuestionData: data });
    }

    getNextQuestion = (questionSet) => {
        if (this.state.allQuestionData == null || this.state.allQuestionData.length == 0) {
            return;
        } else {
            let questionData = this.state.allQuestionData.Data;
            if (questionSet == '1') {


                return questionData.map((item, index) => {
                    if (index < 3) {
                        return (
                            <>
                                <li className="genericPageLinkText label-text"><span>{item.OrderNumber}</span>
                                    <img src="images/questionIcon.svg" /> {item.Text}
                                </li>
                                <li>
                                    <input id={'toggle-on-' + item.OrderNumber} className="toggle toggle-left" type="radio" name={item.OrderNumber} value="False"  onChange={this.radioValue} />
                                    <label htmlFor={'toggle-on-' + item.OrderNumber} className="btn">  No</label>
                                    <input id={'toggle-off-' + item.OrderNumber} className="toggle toggle-right" type="radio" name={item.OrderNumber} value="True" onChange={this.radioValue} />
                                    <label htmlFor={'toggle-off-' + item.OrderNumber} className="btn lborder">Yes</label>
                                </li>


                            </>)
                    }

                });

            }

            else if (questionSet == '2') {
                return questionData.map((item, index) => {
                    if (index > 2) {
                        return (
                            <>
                                <li className="genericPageLinkText label-text"><span>{item.OrderNumber}</span>
                                    <img src="images/questionIcon.svg" /> {item.Text}
                                </li>
                                <li>
                                    <input id={'toggle-on-' + item.OrderNumber} className="toggle toggle-left" type="radio" name={item.OrderNumber} value="False"  onChange={this.radioValue} />
                                    <label htmlFor={'toggle-on-' + item.OrderNumber} className="btn">  No</label>
                                    <input id={'toggle-off-' + item.OrderNumber} className="toggle toggle-right" type="radio" name={item.OrderNumber} value="True" onChange={this.radioValue} />
                                    <label htmlFor={'toggle-off-' + item.OrderNumber} className="btn lborder">Yes</label>
                                </li>


                            </>)
                    }

                });
            }
        }



    }
    render() {
        return (
            <>
                <div className="feedbackContainer">

                    <div className="container">

                    

                    <div className="row">
                            <div className="col m12 s12">
                            <div className="formHeader text-center">Symptom Tracker Admin Tool</div>

                        </div>
                    </div>
                    <div className="row">

                            <div className="col s4 m4">&nbsp;</div>

                            <div className="col s4 m4" id="divForm">

                            <ul className="formElementBox ng-scope" ng-if="haveAdminAccess">

                                <li className="genericPageLinkText label-text"><img src="images/questionIcon.svg" /><span>?</span><div>Employee Email</div> </li>
                                <li className="genericPageLinkText label-text">
                                    <input type="text" className="form-control" name="txtEmpId" onKeyUp={this.userNameCapture} />
                                    <div>{this.userInfoBox()}</div>
                                </li>
                                <li className="genericPageLinkText label-text">
                                    <input type="checkbox" id="chkVisitor" name="isvistorname" onChange={this.isVistorCheck} /> Is Visitor
                    </li>
                                <li className="genericPageLinkText label-text livistor" style={{ display: this.state.isVistor == true ? 'block' : 'none' }}>
                                    <img src="images/questionIcon.svg" /><span>?</span>Visitor Name
                    </li>
                                <li className="genericPageLinkText label-text livistor" style={{ display: this.state.isVistor == true ? 'block' : 'none' }}>
                                    <input type="text" name="txtEmpVisitor" id="vistorName" className="form-control" /></li>
                            </ul>

                        </div>
                            <div className="col s4 m4">&nbsp;</div>
                    </div>



                    <div className="row" style={{ display: this.state.allHideContent == true ? 'block' : 'none' }}>

                    </div>


                    <div className="row">
                            <div className="col s4 m4"></div>
                            <div className="col s4 m4">
                            <div id="isEmployeeWFH" style={{ display: this.state.prevStep == true ? 'block' : 'none' }}>



                            </div>

                            <div id="isEmployeeWFH-No" style={{ display: this.state.nextStep == true ? 'block' : 'none' }}>
                                <ul className="formElementBox" id="fromStep2">
                                    {this.getNextQuestion('1')}
                                    {this.state.nextForm == true ? this.getNextQuestion('2') : ''}
                                    <li><button type="button" className="btn btn-primary submitBtn" onClick={() => this.nextStepBtn(this.state.action)}>Submit</button></li>
                                </ul>

                            </div>



                        </div>
                    </div>
                    <div className="row">
                            <div className="col s12 m12 modalShowContaianer">
                            <div id="errorDiv" className="customModalBox" style={{ display: this.state.errorModalDisplay == true ? 'block' : 'none' }}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="iconContainer">
                                                <img src="images/error-icon.svg" />
                                            </div>

                                            <ul id="divWarning" className="error-list">
                                                {this.successModalMessage()}
                                            </ul>
                                            <button type="button" className="btn btn-primary submitBtn">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="successDiv" className="customModalBox" style={{ display: this.state.successModalDisplay == true ? 'block' : 'none' }}>
                                <div className="modal-dialog">
                                    <div className="modal-content">

                                        <div className="modal-body">
                                            <div className="iconContainer">
                                                <img src="images/success-icon.svg" />
                                            </div>
                                            <ul id="divSuccess" className="success-list">
                                                {this.successModalMessage()}
                                            </ul>
                                            <div className="input-group btnCenter ng-scope" ng-if="!showTempTextbox">
                                                <button type="button" className="btn btn-primary submitBtn secBtn" >Close</button>

                                            </div>
                                            <div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                            <div className="col s12 m12"></div>
                    </div>
                </div>

                </div>

                <Modal show={this.state.showModalWAH} onHide={this.hideModalFWAH} className="modalMySaveCall">
                    <Modal.Header closeButton>
                        <Modal.Title>Title</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Body Text
                </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.hideModalFWAH}>Cancel</Button>

                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}

