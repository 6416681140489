import React, { Component } from 'react';
import pdfDish from '../../../../ClientApp/src/assets/Resources/dishscriberbusinessrulesequipmentfeesandfaqs.pdf'
import pdfSling from '../../../../ClientApp/src/assets/Resources/slingscriberfaq.pdf'
import pdfBoost from '../../../../ClientApp/src/assets/Resources/businessrulesandfaqs.pdf'
import { HttpRequest } from './CommonOperations';

export class Scriber extends Component {
    static displayName = Scriber.name;

    constructor(props) {
        super(props);
        this.state = { AcademyResponse: [] };
        this.UserDetails = this.props.UserDetails;
    }
    componentDidMount() {
        if (sessionStorage.getItem("redirectURL") != null && sessionStorage.getItem("redirectURL").indexOf('Session') == -1) {
            var url = sessionStorage.getItem("redirectURL");
            sessionStorage.removeItem("redirectURL");
            window.location.href = url;
        }
        this.GetAcademyResultsForUserByPersonID(this.UserDetails.PersonID);
        document.title = 'Internal Apps';
    }
    GetAcademyResultsForUserByPersonID = async (PersonID) => {
        var response = null;
        console.log('Scriber: ', PersonID);
        if (PersonID == null) return;
        response = await HttpRequest('AcademyAwards/GetAcademyResultsForUserByPersonID/' + PersonID, 'Get');
        const res = await response.json();
        await this.setState({ AcademyResponse: res });
    }
    clickDISHscriber = () => {
        var path = "/Scriber/DISHscriber";
        this.props.history.push(path)
    }

    clickAcademyAwards = () => {
        var path;
        let awardsLastDate = this.UserDetails.AcademyAwardsEndDate;
        var todaydate = (new Date()).valueOf();
        var awardsEndDate = (new Date(awardsLastDate)).valueOf();
        if (awardsEndDate < todaydate) {
            path = "/AcademyAwards/Leaderboard";
        }
        else if (this.state.AcademyResponse.AcademyNominationUserResponses == null || this.state.AcademyResponse.AcademyNominationUserResponses == 0) {
            path = "/AcademyAwards/Intro";
        }
        else if (this.state.AcademyResponse.AcademyNominationCategories.length == this.state.AcademyResponse.AcademyNominationUserResponses.length - 1) {
            path = "/AcademyAwards/Review"
        }
        else {
            path = "/AcademyAwards/SelectPicks";
        }
        this.props.history.push(path)
    }

    clickSymptomTracker = () => {
        var path = "/SymptomTracker";
        this.props.history.push(path)
    }
    clickPayrollPassword = () => {
        var path = "/PayrollPasswordReset";
        this.props.history.push(path)
    }

    clickFlowerPurchasingRequest = () => {
        var path = "/FlowerPurchasingRequest";
        this.props.history.push(path)
    }

    clickFlowerPurchasingRequestReview = () => {
        var path = "/FlowerPurchasingRequestReview";
        this.props.history.push(path)
    }

    clickSLINGscriber = () => {
        var path = "/Scriber/SLINGscriber";
        this.props.history.push(path)
    }
    clickBOOSTscriber = () => {
        var path = "/Scriber/BOOSTscriber";
        this.props.history.push(path)
    }

    BusinessCardRequest = () => {
        var path = "/BusinessCardRequest";
        this.props.history.push(path)
    }
    EmployeeProfile = () => {
        var path = "/EmployeeProfile";
        this.props.history.push(path)
    }

    clickBoostInfinite = () => {
        let path = "/Scriber/BoostInfinite";
        this.props.history.push(path)
    }



    ProfilePage = () => {
        var path = "/ProfilePage";
        this.props.history.push(path)
    }

    EmployeePhoneNumber = () => {
        var path = "/EmployeePhoneNumber";
        this.props.history.push(path)

    }
    bussinessEventCMS = () => {
        var path = "/HopCMS/Dashbaord";
        this.props.history.push(path)
    }




    BrackekBreaks = (gender) => {
        var path = '';
        if (gender == 'Male') {
            path = "/BracketBreaks/mens";
            this.props.history.push(path);
        }
        else if (gender == 'Female') {
            path = "/BracketBreaks/womens";
            this.props.history.push(path);
        }
        else if (gender == 'Other') {
            path = "/BracketBreaks/others";
            this.props.history.push(path);
        }


    }


    FirstAidClasses = (type) => {
        var path = '';
        if (type == 'oktoberfest') {
            path = "/FirstAidClasses/volunteeratoktoberfest";
            this.props.history.push(path);
        }
        else if (type == 'meridian') {
            path = "/FirstAidClasses/meridianfirstaid";
            this.props.history.push(path);
        }

    }

    render() {
        return (
            <>
                <div className="container">

                    <div className="row homeCardImg">
                        <div className="col l3 m3 s12">

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/dish-scriber-sm.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>DISHscriber</h5>

                                </div>
                                <div className="card-action">
                                    <a href={pdfDish} target='_blank' rel='noopener noreferrer'>Business Rules and FAQs</a>
                                    <button onClick={() => this.clickDISHscriber()} className="btn btn-primary">Signup</button>
                                </div>

                            </div>
                        </div>
                        <div className="col l3 m3 s12">

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/sling.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>SLINGscriber</h5>
                                </div>
                                <div className="card-action">
                                    <a href={pdfSling} target='_blank' rel='noopener noreferrer'>Business Rules and FAQs</a>
                                    <button onClick={() => this.clickSLINGscriber()} className="btn btn-primary">Signup</button>
                                </div>

                            </div>
                        </div>

                        <div className="col l3 m3 s12">

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/AcademyAwards/dishLogoColor.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>Academy Awards</h5>

                                </div>
                                <div className="card-action">
                                    <button onClick={() => this.clickAcademyAwards()} className="btn btn-primary">Vote Now</button>
                                </div>

                            </div>
                        </div>

                        <div className="col l3 m3 s12">

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/AcademyAwards/dishLogoColor.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>Payroll Password Reset</h5>

                                </div>
                                <div className="card-action">
                                    <button onClick={() => this.clickPayrollPassword()} className="btn btn-primary">submit</button>
                                </div>

                            </div>
                        </div>

                        <div className="col l3 m3 s12">

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/AcademyAwards/dishLogoColor.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>Employee Profile</h5>

                                </div>
                                <div className="card-action">
                                    <button onClick={() => this.EmployeeProfile()} className="btn btn-primary">submit</button>
                                </div>

                            </div>
                        </div>

                        <div className="col l3 m3 s12">

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/AcademyAwards/dishLogoColor.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>Bracket Breaks</h5>

                                </div>
                                <div className="card-action">
                                    <button onClick={() => this.BrackekBreaks('Male')} className="btn btn-primary" style={{ 'padding': '0 14px', 'margin-right': '8px' }}>Male</button>
                                    <button onClick={() => this.BrackekBreaks('Female')} className="btn btn-primary" style={{ 'padding': '0 14px', 'margin-right': '8px' }}>Female</button>
                                    <button onClick={() => this.BrackekBreaks('Other')} className="btn btn-primary" style={{ 'padding': '0 14px', 'margin-right': '8px' }}>Other</button>

                                </div>

                            </div>
                        </div>
                        <div className="col l3 m3 s12">

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/logo-boost-mobile.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>BOOSTMobile</h5>

                                </div>
                                <div className="card-action">
                                    {/* <a href={pdfBoost} target='_blank' rel='noopener noreferrer'>Business Rules and FAQs</a>*/}
                                    <button onClick={() => this.clickBoostInfinite()} className="btn btn-primary">Signup</button>
                                </div>

                            </div>
                        </div>

                        <div className="col l3 m3 s12" style={{ 'display': 'none' }}>

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/AcademyAwards/dishLogoColor.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>Bussness Event CMS</h5>

                                </div>
                                <div className="card-action">
                                    {/* <a href={pdfBoost} target='_blank' rel='noopener noreferrer'>Business Rules and FAQs</a>*/}
                                    <button onClick={this.bussinessEventCMS} className="btn btn-primary">Submit</button>
                                </div>

                            </div>
                        </div>
                        <div className="col l3 m3 s12" style={{ 'display': 'none' }}>

                            <div className="card">
                                <div className="card-image">
                                    <img src="images/AcademyAwards/dishLogoColor.png" />
                                    {/*<span className="card-title">DISH SUBSCRIBER</span>*/}
                                </div>
                                <div className="card-content">
                                    <h5>First Aid Classes</h5>

                                </div>
                                <div className="card-action">
                                    <button onClick={() => this.FirstAidClasses('oktoberfest')} className="btn btn-primary" style={{ 'padding': '0 14px', 'margin-bottom': '8px' }}>Oktoberfest Volunteer Registration</button>

                                    <button onClick={() => this.FirstAidClasses('meridian')} className="btn btn-primary" style={{ 'padding': '0 14px' }}>Meridian First Aid Classes</button>

                                </div>

                            </div>
                        </div>



                    </div>


                    <div style={{ 'display': 'none' }}>

                        <div >
                            <h3>DISHscriber</h3>
                            <div>

                                <p> <a href={pdfDish} target='_blank' rel='noopener noreferrer'>Business Rules and FAQs</a></p>
                                <p onClick={() => this.clickDISHscriber()}>Sign Up</p>
                            </div>
                        </div>
                        <div>
                            <h3>SLINGscriber</h3>
                            <div>
                                <p> <a href={pdfSling} target='_blank' rel='noopener noreferrer'>Business Rules and FAQs</a></p>
                                <p onClick={() => this.clickSLINGscriber()}>Sign Up</p>
                            </div>
                        </div>
                        <div>
                            <h3>BOOSTscriber</h3>
                            <div>
                                <p> <a href={pdfBoost} target='_blank' rel='noopener noreferrer'>Business Rules and FAQs</a></p>
                                <p onClick={() => this.clickBOOSTscriber()}>Info & Sign Up</p>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
